<div class="container" *ngIf="paymentType != 'subscription'">
  <div class="card">
    <div class="task-head error">
      <h4>
        We are sorry that the payment did not go through. Would you like to try
        again?
      </h4>
    </div>

    <div class="btns">
      <button mat-stroked-button routerLink="/profile" style="width: 175px;">My Profile</button>
      <button mat-flat-button routerLink="/support">Contact Eartheye</button>
    </div>
  </div>
</div>

<div class="container" *ngIf="paymentType === 'subscription'">
  <div class="card">
    <div class="task-head error">
      <h4>
        We are sorry that the deposit did not go through.
      </h4>
    </div>

    <div class="btns">
      <button mat-stroked-button routerLink="/deposit" style="width: 175px;">Try Again ?</button>
      <button mat-flat-button routerLink="/support">Contact Eartheye</button>
    </div>
  </div>
</div>
