import { Component, Injectable, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TaskService } from '../../services/task.service';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import { BANK_DETAILS, PAYPAL_FEE, STRIPE_FEE } from 'src/app/services/constants';
import { environment } from 'src/environments/environment';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.scss']
})
@Injectable({ providedIn: 'root' })
export class DepositsComponent implements OnInit {
  url: any;
  currentUser: any = undefined;
  displayedColumns: string[] = ['createdAt', 'gatewayTransactionId', 'referenceId', 'amount', 'referenceType', 'receipt', 'invoice'];
  data = [];
  dataSource = new MatTableDataSource<any>();
  tableDataLength: number;
  filteredData: any;
  userid: any;
  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  hideColumn: boolean = false;
  darkTheme: boolean;
  invoiceImagePath: string;
  receiptsImagePath: string;
  isInIframe: boolean;
  subscribeValue: boolean;
  taxType: string;
  gstpercent: number = 0;
  
  constructor(private us: UserService, public datepipe: DatePipe, private authService: AuthService,
    private router: Router, private taskService: TaskService,
    private localStorageService: DataService,
    private themeService: ThemeService
  ) {
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;

        this.invoiceImagePath = this.darkTheme ? '../../../assets/images/invoice-dark.png' : '../../../assets/images/invoice.png';
        this.receiptsImagePath = this.darkTheme ? '../../../assets/images/receipts-dark.svg' : '../../../assets/images/receipts.svg';
      });
      this.isInIframe = this.themeService.isInIframe();    
  }

  ngOnInit(): void {
    localStorage.removeItem('routeChange');
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;

      if (!this.currentUser?.verified) {
        this.router.navigate(['login']);
        this.authService.logout();
      }
      else {
        this.userid = this.currentUser.id;
        if (this.currentUser.userType === 'admin')
          this.userid = this.userid + 'admin';
         if(this.currentUser?.userType != 'Super user') this.router.navigate(['task']);        
         else this.getDepositData(this.userid);

          this.us.personalInfo(this.userid).subscribe(async (res: any) => {
            this.subscribeValue = res?.subscribed;
            this.currentUser = res;
            // Set the timer for 3 minutes (180,000 milliseconds)
            if (!this.subscribeValue) {
              this.checkTimeOut();
            };
          });
      }
      castUser.unsubscribe();
    });
    localStorage.removeItem('taskData');
  }

  getDepositData(userId: any) {
    this.tableDataLength = 0;
    this.taskService.getdeposit(userId, { deposit: 'all' }).subscribe(
      (result: any) => {
        this.filteredData = [];
        this.tableDataLength = result.length > 0 ? result.length : 0;

        if (result.length > 0) {
          this.filteredData = result;
          this.dataSource = new MatTableDataSource(this.filteredData);
        }
      },
      (err) => {
        return false;
      }
    );
  }

  checkTimeOut() {
    setTimeout(() => {
      this.router.navigate(['/howitworks']);
    }, 180000);
  }

  generateReceipt(row, type) {
    this.genPdf(row, type);
  }

  async genPdf(order: any, type: any) {
    if(!order?.top_up_amount) {
      order.top_up_amount = 0;
    }
    
    let reportType = 'Receipt', logo;
    let amount, topUp, onlineFees;
    
    if (order.referenceType === "Bank Transfer") {
      reportType = 'Invoice';
      this.hideColumn = true;
    } else this.hideColumn = false;
    const exs: any = [];
    const logoimge = '../assets/images/logo.png';
    this.taxType = 'VAT';
    
    if (order.referenceType === 'PayPal') onlineFees = PAYPAL_FEE;
    else if (order.referenceType === 'Stripe') onlineFees = STRIPE_FEE;

    if (this.userid) {
      const castUser = this.us.personalInfo(this.userid).subscribe(async (res: any) => {
        this.currentUser = res;

        let fullAddress, country;
        if (this.currentUser.address1) {
          fullAddress =
            this.currentUser?.address1 + ' , ' + this.currentUser?.address2;
          country = this.currentUser?.country;
        } else {
          fullAddress = 'Not available';
          country = 'Not available';
        }

        if (
          this.currentUser?.channelPriceId === undefined &&
          this.currentUser?.country === 'Singapore'
        ) {
          this.gstpercent = environment.gst;
        } else if (this.currentUser?.channelPriceId != undefined) {
          this.gstpercent = this.currentUser?.tax[0]?.taxperc;
          this.taxType = this.currentUser?.tax[0]?.type;
        }

        let subAmount = Number(order?.subscription_amount) > 0 ? order.subscription_amount : Number(order.amount);
        
        amount = this.dollarUS.format(subAmount);
        topUp = this.dollarUS.format(order?.top_up_amount);

        let totalAmount = +subAmount + +order?.top_up_amount;
        let grandTotalWithCharges = (totalAmount / onlineFees);
        let paymentCharge = (grandTotalWithCharges - totalAmount);
        

        await this.toDataURL(logoimge).then((dataUrl) => {
          logo = dataUrl;
        });

        exs.push([
          !this.hideColumn ? {
            columns: [
              {
                width: 40,
                alignment: 'center',
                fontSize: 10,
                text: 'Item No.',
                style: 'tableHeader',
              },
              {
                width: 165,
                alignment: 'center',
                fontSize: 10,
                text: 'Transaction ID',
                style: 'tableHeader',
              },
              {
                width: 80,
                alignment: 'center',
                fontSize: 10,
                text: 'Type',
                style: 'tableHeader',
              },
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: 'Amount',
                style: 'tableHeader',
              },
              {
                width: 80,
                alignment: 'center',
                fontSize: 10,
                text:  'Reference Type',
                style: 'tableHeader',
              },
            ],
          } : 
          {
            columns: [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: 'Item No.',
                style: 'tableHeader',
              },
              {
                width: 150,
                alignment: 'center',
                fontSize: 10,
                text: 'Type',
                style: 'tableHeader',
              },
              {
                width: 180,
                alignment: 'center',
                fontSize: 10,
                text: 'Amount',
                style: 'tableHeader',
              },
              {
                width: 120,
                alignment: 'center',
                fontSize: 10,
                text: 'Reference Type',
                style: 'tableHeader',
              },
            ],
          } ,
        ]);
        const numericAmount = amount.replace(/[^0-9]/g, '');

        if (order?.subscription_amount > 0 || numericAmount > 0) {
          exs.push([
            !this.hideColumn ? {
              columns: [
                {
                  width: 40,
                  alignment: 'center',
                  fontSize: 10,
                  text: 1,
                  style: 'contents'
                },
                {
                  width: 165,
                  alignment: 'center',
                  fontSize: 10,
                  text: order.gatewayTransactionId,
                  style: 'contents'
                },
                {
                  width: 80,
                  alignment: 'center',
                  fontSize: 10,
                  text: order?.subscription_amount > 0 ? 'Deposit' : 'Top-up',
                  style: 'contents'
                },
               {
                  width: 100,
                  alignment: 'center',
                  fontSize: 10,
                  text: amount,
                  style: 'contents'
                },
                {
                  width: 80,
                  alignment: 'center',
                  fontSize: 10,
                  text: order.referenceType,
                  style: 'contents'
                },
              ]
  
            } : 
            {
              columns: [
                {
                  width: 50,
                  alignment: 'center',
                  fontSize: 10,
                  text: 1,
                  style: 'contents'
                },
                {
                  width: 150,
                  alignment: 'center',
                  fontSize: 10,
                  text: order?.subscription_amount > 0 ? 'Deposit' : 'Top-up',
                  style: 'contents'
                },
               {
                  width: 180,
                  alignment: 'center',
                  fontSize: 10,
                  text: amount,
                  style: 'contents'
                },
                {
                  width: 120,
                  alignment: 'center',
                  fontSize: 10,
                  text: order.referenceType,
                  style: 'contents'
                },
              ]
  
            }
          ]);
        }

        

       if (order?.top_up_amount > 0) {
        
        exs.push([
          !this.hideColumn ? {
            columns: [
              {
                width: 40,
                alignment: 'center',
                fontSize: 10,
                text: order?.subscription_amount > 0 ||  numericAmount > 0 ? 2 : 1,
                style: 'contents'
              },
              {
                width: 165,
                alignment: 'center',
                fontSize: 10,
                text: order.gatewayTransactionId,
                style: 'contents'
              },
              {
                width: 80,
                alignment: 'center',
                fontSize: 10,
                text: 'Top-up',
                style: 'contents'
              },
             {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: topUp,
                style: 'contents'
              },
              {
                width: 80,
                alignment: 'center',
                fontSize: 10,
                text: order.referenceType,
                style: 'contents'
              },
            ]

          } : 
          {
            columns: [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: order?.subscription_amount > 0 ||  numericAmount > 0 ? 2 : 1,
                style: 'contents'
              },
              {
                width: 150,
                alignment: 'center',
                fontSize: 10,
                text: 'Top-up',
                style: 'contents'
              },
             {
                width: 180,
                alignment: 'center',
                fontSize: 10,
                text: topUp,
                style: 'contents'
              },
              {
                width: 120,
                alignment: 'center',
                fontSize: 10,
                text: order.referenceType,
                style: 'contents'
              },
            ]

          }
        ]);
       }

       exs.push([
        !this.hideColumn ? {
          columns: [
            {
              width: 40,
              alignment: 'center',
              fontSize: 10,
              text: '',
              style: 'contents'
            },
            {
              width: 165,
              alignment: 'center',
              fontSize: 10,
              text: '',
              style: 'contents'
            },
            {
              width: 80,
              alignment: 'center',
              fontSize: 10,
              text: 'Total Amount',
              style: 'contents'
            },
           {
              width: 100,
              alignment: 'center',
              fontSize: 10,
              text: this.dollarUS.format(totalAmount),
              style: 'contents'
            },
            {
              width: 80,
              alignment: 'center',
              fontSize: 10,
              text: '',
              style: 'contents'
            },
            {
              width: 70,
              alignment: 'center',
              fontSize: 10,
              text: '',
              style: 'contents'
            },
          ]

        } : 
        {
          columns: [
            {
              width: 50,
              alignment: 'center',
              fontSize: 10,
              text: '',
              style: 'contents'
            },
            {
              width: 150,
              alignment: 'center',
              fontSize: 10,
              text: 'Total Amount',
              style: 'contents'
            },
           {
              width: 180,
              alignment: 'center',
              fontSize: 10,
              text: this.dollarUS.format(totalAmount),
              style: 'contents'
            },
            {
              width: 120,
              alignment: 'center',
              fontSize: 10,
              text: '',
              style: 'contents'
            },
          ]

        }
      ]);

        let docDefinition: any = {
          header: {
            margin: [35, 40, 40, 40],
            columns: [
              {
                image: logo,
                width: 140,
              },
            ],
          },

          content: [
            {
              text: type,
              fontSize: 20,
              alignment: 'right',
              color: '#000',
            },
            {
              margin: [0, 40, 0, 0],
              text: 'Eartheye Space Technologies Pte Ltd',
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#000',
            },
            {
              text: '1 North Bridge Road',
              style: 'CompanyAddress',
            },
            {
              text: '#18-03, High Street Centre',
              style: 'CompanyAddress',
            },
            {
              text: 'Singapore 179094',
              style: 'CompanyAddress',
            },
            {
              columns: [
                [
                  {
                    text:
                      'Date: ' +
                      this.datepipe.transform(new Date(order.createdAt).toDateString(), 'dd-MM-yyyy'),

                    style: 'OrderRight',
                    margin: [0, 50, 0, 0]
                  },
                ],
              ],
            },
            {
              columns: [
                [{ text: this.currentUser.name, style: 'CustomerLeft1', margin: [0, -18, 0, 0]}],
              ],
            },
            {
              columns: [
                [
                  {
                    text: this.currentUser?.company,
                    style: 'CustomerLeft1',
                    margin: [0, 0, 0, 0],
                  },
                ],
                [
                  {
                    text: type + ' No: ' + order.depositNumber,
                    style: 'OrderRight1',
                  },
                ],
              ],
            },
            {
              columns: [
                [{ text: this.currentUser?.address1, style: 'CustomerLeft1', margin: [0, 0, 0, 3], }]
              ]
            },
            {
              columns: [
                [{ text: this.currentUser?.address2, style: 'CustomerLeft1', margin: [0, 0, 0, 3], }]
              ]
            },
            {
              columns: [
                [{ text: country, style: 'CustomerLeft1', margin: [0, 0, 0, 5], }]
              ]
            },
            {
              style: 'tableExample',
              table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                  ...exs,
                ],
              },
            },
            (order.referenceType != "Bank Transfer")
            ? {
              columns: [
                [
                  {
                    text: 'Subtotal: ' + this.dollarUS.format(totalAmount),
                    style: 'totalDisplay',margin: [0, 0, -20, 7]
                  },
                  {
                    text: order?.referenceType +' Fees: ' + this.dollarUS.format(paymentCharge),
                    style: 'totalDisplay', margin: [0, 0, -20, 7],
                  },
                  {
                    text: 'Grand Total: ' + this.dollarUS.format(grandTotalWithCharges),
                    style: 'totalDisplay', margin: [0, 0, -20, 7],
                  }
                ],
              ],
            } : {},
            (order.referenceType === "Bank Transfer")
            ? {
              columns: [
                [
                  {
                    text: 'Grand Total: ' + this.dollarUS.format(totalAmount),
                    style: 'OrderRight1', margin: [0, 0, 0, 7],
                  }
                ],
              ],
            } : {},
            ...(type == 'Invoice' ? [{ 
              columns: [
                [
                  {
                    text: 'Telegraphic transfer (TT) details :',
                    style: 'TTdeatial', bold: true, marginTop: 30
                  },
                  {
                    text: 'A/C Holder: '+ BANK_DETAILS.accountHolder,
                    style: 'TTdeatial', marginTop: 10
                  },
                  {
                    text: 'A/C No: ' + BANK_DETAILS.accountNumber,
                    style: 'TTdeatial',
                  },
                  {
                    text: 'SWIFT Code: ' + BANK_DETAILS.SWIFTcode,
                    style: 'TTdeatial',
                  },
                  {
                    text: 'Bank Name: ' + BANK_DETAILS.bankName,
                    style: 'TTdeatial',
                  },
                  {
                    text: 'Bank Address: ' + BANK_DETAILS.bankAddress,
                    style: 'TTdeatial', lineHeight: 1.5
                  }
                ],
              ],
             }] : []),
          ],
          styles: {
            CompanyAddress: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 3, 0, 0],
            },
            OrderRight: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [0, 50, 0, 0],
            },
            CustomerLeft: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 50, 0, 0],
            },
            OrderRight1: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [10, 5, 0, 0],
            },
            totalDisplay: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
            },
            CustomerLeft1: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 5, 0, 0],
            },
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: 'black',
              marginTop: 6,
              marginBottom: 6
            },
            contents: {
              marginTop: 6,
              marginBottom: 6
            },
            TTdeatial: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 5, 0, 0],
            }
          },
        }

        if (this.isInIframe) {
          delete docDefinition.header;
          pdfMake.createPdf(docDefinition).download(order.depositNumber + '.pdf');
        } else {
          pdfMake.createPdf(docDefinition).open();
        }
        castUser.unsubscribe();
      });
    }
  }
  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
}