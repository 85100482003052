<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<div *ngIf="!notRegistered">
  <div class="container userRegister">
    <img *ngIf="!isInIframe" src="../../../assets/images/logo.png" alt="logo" style="width: 36%" />
    <h4>Discover earth with us</h4>
    <p>Welcome to Eartheye Space Tasking and Information Feeds</p>

    <form class="form-vertical" [formGroup]="registerForm" (ngSubmit)="onSubmit()">

      <div class="register-wrapper">
        <div class="form-group">
          <mat-label>First Name<span>*</span></mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Your first name" formControlName="fullName" ngForm=""
              (keypress)="keyPressName($event)" (keydown)="Space($event)"
              [ngClass]="{ 'is-invalid': f.fullName.errors && f.fullName.touched }" />
            <mat-error *ngIf="f.fullName.touched && f.fullName.errors" class="alert alert-danger">
              <mat-error *ngIf="f.fullName.errors.required"> Your First Name is required</mat-error>
              <mat-error *ngIf="f.fullName.hasError('minlength')"> At least 3 characters long</mat-error>
              <mat-error *ngIf="f.fullName.hasError('maxlength')"> Maximum 50 characters long</mat-error>
            </mat-error>
            <mat-icon matSuffix *ngIf="f.fullName.touched && !f.fullName.errors"><img
                src="../../../assets/images/done.svg"></mat-icon>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-label>Last Name<span>*</span></mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Your last name" formControlName="lastName" ngForm=""
              (keypress)="keyPressName($event)" (keydown)="Space($event)"
              [ngClass]="{ 'is-invalid': f.lastName.errors && f.lastName.touched }" />
            <mat-error *ngIf="f.lastName.touched && f.lastName.errors" class="alert alert-danger">
              <mat-error *ngIf="f.lastName.errors.required"> Your Last Name is required</mat-error>
              <mat-error *ngIf="f.lastName.hasError('maxlength')"> Maximum 50 characters long</mat-error>
            </mat-error>
            <mat-icon matSuffix *ngIf="f.lastName.touched && !f.lastName.errors"><img
                src="../../../assets/images/done.svg"></mat-icon>
          </mat-form-field>
        </div>


        <div class="form-group">
          <mat-label>Display Name<span>*</span></mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" placeholder="Your display name" formControlName="displayName" ngForm=""
              [ngClass]="{ 'is-invalid': f.displayName.errors && f.displayName.touched }"
              (keypress)="keyPressName($event)" (keydown)="Space($event)" />

            <mat-error *ngIf="f.displayName.touched && f.displayName.errors" class="alert alert-danger">
              <mat-error *ngIf="f.displayName.errors.required"> Display Name is required</mat-error>
              <mat-error *ngIf="f.displayName.hasError('minlength')"> At least 3 characters long</mat-error>
              <mat-error *ngIf="f.displayName.hasError('maxlength')"> Maximum 15 characters long</mat-error>
            </mat-error>
            <mat-icon matSuffix *ngIf="f.displayName.touched && !f.displayName.errors"><img
                src="../../../assets/images/done.svg"></mat-icon>
          </mat-form-field>
        </div>


        <div class="form-group">
          <mat-label>Email<span>*</span></mat-label>
          <mat-form-field appearance="outline">

            <input matInput type="text" placeholder="Your email" formControlName="email" ngForm=""
              (keypress)="keyPress($event)" (keydown)="Space($event)"
              [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }" />
            <mat-error *ngIf="f.email.touched && f.email.errors" class="alert alert-danger">
              <mat-error *ngIf="f.email.errors.required">Please enter a valid email</mat-error>
              <mat-error *ngIf="f.email.errors.pattern"> Please use an official email address</mat-error>
            </mat-error>
            <!-- <mat-error *ngIf="!notRegistered && submitted && !f.email.errors">
            {{warnMsg}}
          </mat-error> -->
            <div *ngIf="!notRegistered && submitted && !f.email.errors" class="alert-box"
              style="padding-top: 15px !important; margin-left: -11px;">
              {{warnMsg}}
            </div>
            <mat-icon matSuffix *ngIf="f.email.touched && !f.email.errors"><img
                src="../../../assets/images/done.svg"></mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div>
        <button mat-raised-button color="primary" [disabled]="registerForm.invalid" id="button">NEXT</button>
      </div>
      <div class="regist">
        <span>Already registered ? <a href="#" routerLink="/login">Sign In</a></span>
      </div>
    </form>
  </div>


</div>

<div *ngIf="notRegistered" class="notRegistered">
  <div class="container">
    <img *ngIf="!isInIframe" src="../../../assets/images/logo.png" alt="logo" style="width: 40%" />
    <h4>Sign Up</h4>
    <div class="mails">
      <p>
        Your Email: <span class="link">{{ registerForm.value.email.toLowerCase()
          }}</span>
      </p>
    </div>
    <div id="wrapper">
      <div class="scrollbar" id="style-4">
        <div class="force-overflow"></div>
        <form class="form-vertical" [formGroup]="signupform" (ngSubmit)="submitSignup()">

          <div>
            <label>Organisation Name<span>*</span></label>
            <input type="text" placeholder="Your organisation name" formControlName="companyName" class="form-control"
              [ngClass]="{ 'is-invalid': sign.companyName.errors && sign.companyName.touched }"
              (keydown)="Space($event)" id="company" style="box-shadow: none;	
              z-index: 0;" />
            <div *ngIf="sign.companyName.errors && sign.companyName.touched" class="alert alert-danger align">
              <div *ngIf="sign.companyName.errors.required"> Organisation name is required</div>
            </div>
          </div>

          <div>
            <label>Website<span>*</span></label>
            <input type="text" placeholder="Your website" formControlName="website" class="form-control"
              [ngClass]="{ 'is-invalid': sign.website.errors && sign.website.touched }"
              (keydown)="Space($event)" id="website" style="box-shadow: none;	
              z-index: 0;" />
            <div *ngIf="sign.website.errors && sign.website.touched" class="alert alert-danger align">
              <div *ngIf="sign.website.errors.required"> Website is required</div>
              <div *ngIf="sign['website'].errors?.['pattern']">
                Please enter a valid website
              </div>
            </div>
          </div>

          <div>
            <mat-label>Organisation Type<span>*</span></mat-label>
            <mat-form-field appearance="outline" style="height: 40px;">
              <mat-select [disableOptionCentering]="true" panelClass="matopencountry" formControlName="organisationType"
                placeholder="Choose organisation type" [ngClass]="{ 'is-invalid': sign.organisationType.errors && sign.organisationType.touched }">
                <mat-option *ngFor="let type of organisationTypes" [value]="type">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="sign.organisationType.errors && sign.organisationType.touched" class="alert alert-danger align">
              <div *ngIf="sign.organisationType.errors.required"> Organisation type is required</div>
            </div>
          </div>

          <div>
            <label>Department<span>*</span></label>
            <input type="text" placeholder="Your department" formControlName="department" class="form-control"
              [ngClass]="{ 'is-invalid': sign.department.errors && sign.department.touched }"
              (keydown)="Space($event)" style="box-shadow: none;	
              z-index: 0;" />
            <div *ngIf="sign.department.errors && sign.department.touched" class="alert alert-danger align">
              <div *ngIf="sign.department.errors.required"> Department is required</div>
            </div>
          </div>

          <div>
            <label>Role/Position<span>*</span></label>
            <input type="text" placeholder="Your role/position" formControlName="role" class="form-control"
              [ngClass]="{ 'is-invalid': sign.role.errors && sign.role.touched }"
              (keydown)="Space($event)" style="box-shadow: none;	
              z-index: 0;" />
            <div *ngIf="sign.role.errors && sign.role.touched" class="alert alert-danger align">
              <div *ngIf="sign.role.errors.required"> Role/Position is required</div>
            </div>
          </div>

          <div>
            <label>Phone Number<span>*</span></label>
            <input type="number" placeholder="Your phone number" formControlName="phonenumber" class="form-control"
              (keypress)="keyPressPhone($event)"
              [ngClass]="{ 'is-invalid': sign.phonenumber.errors && sign.phonenumber.touched }" style="box-shadow: none;	
              z-index: 0;" />
            <div *ngIf="sign.phonenumber.errors && sign.phonenumber.touched" class="alert alert-danger align">
              <div *ngIf="sign.phonenumber.errors.required"> Please enter a valid phone number</div>
              <!-- <div *ngIf="sign.phonenumber.errors.pattern" > Please enter a valid phone number</div> -->
              <!-- <div *ngIf="sign.phonenumber.hasError('minlength')"> Please enter a valid phone number</div> -->
              <div *ngIf="sign.phonenumber.hasError('maxlength')"> Must be no more than 15 characters</div>
            </div>
          </div>
          <div>
            <label>Password<span>*</span></label>
            <input [type]="showPassword ? 'password' : 'text'" placeholder="Your password" formControlName="password"
              id="password" type="password" class="form-control"
              [ngClass]="{ 'input-error': sign['password'].touched && sign['password'].invalid}"
              (keydown)="Space($event)" required />
            <mat-icon matSuffix (click)="togglePasswordVisibility()">
              {{ showPassword ? "visibility_off" : "visibility" }}
            </mat-icon>
            <div *ngIf="sign['password'].touched && sign['password'].invalid" class="alert alert-danger align">
              <div *ngIf="sign['password'].errors?.['required']">
                Password is required
              </div>

              <div class="passerror"
                *ngIf="sign['password'].errors?.['minlength'] || sign['password'].errors?.['pattern']">
                Must contain <span *ngIf="sign['password'].errors?.['minlength']"> mimimum of 8 characters </span>

                <span *ngIf="sign['password'].errors?.['pattern']"> with at least one capital letter and a special
                  character </span>
              </div>

            </div>
          </div>

          <div>
            <label>Confirm Password<span>*</span></label>
            <input [type]="confirmshowPassword ? 'password' : 'text'" placeholder="Confirm your password"
              formControlName="confirm_password" id="confirm_password" type="password" class="form-control"
              (keydown)="Space($event)"
              [ngClass]="{ 'input-error': sign.confirm_password.errors && sign.confirm_password.touched }" />
            <mat-icon matSuffix (click)="toggleconfirmPasswordVisibility()">
              {{ confirmshowPassword ? "visibility_off" : "visibility" }}
            </mat-icon>
            <div *ngIf="
                sign['confirm_password'].touched &&
                sign['confirm_password'].invalid
              " class="alert alert-danger align">
              <div *ngIf="sign['confirm_password'].errors?.['required']">
                Password is required.
              </div>
              <div *ngIf="sign['confirm_password'].errors?.['confirmedValidator']">
                The passwords must be the same.
              </div>
            </div>
          </div>

          <div class="address">
            <label>Address<span>*</span></label>
            <div style="margin-bottom: 15px;">
              <input placeholder="Address line 1" formControlName="address1" type="text" class="form-control"
                [ngClass]="{ 'is-invalid': sign.address1.errors && sign.address1.touched }" (keydown)="Space($event)"
                style="box-shadow: none;	
              z-index: 0;" />
              <div *ngIf="sign.address1.errors && sign.address1.touched" class="alert alert-danger align">
                <div *ngIf="sign.address1.errors.required"> Address is required</div>
              </div>
            </div>
            <input placeholder="Address line 2" formControlName="address2" type="text" class="form-control"
              (keydown)="Space($event)" />
          </div>
          <div>
            <label>City<span>*</span></label>
            <input placeholder="City" formControlName="city" type="text" class="form-control"
              [ngClass]="{ 'is-invalid': sign.city.errors && sign.city.touched }" (keydown)="Space($event)" style="box-shadow: none;	
              z-index: 0;" />
            <div *ngIf="sign.city.errors && sign.city.touched" class="alert alert-danger align">
              <div *ngIf="sign.city.errors.required"> City is required</div>
            </div>

          </div>
          <div>
            <label>Zip Code</label>
            <input placeholder="Zip Code" formControlName="zip_code" type="number" class="form-control" />


          </div>
          <div>
            <mat-label>Country<span>*</span></mat-label>
            <mat-form-field appearance="outline">
              <mat-select [disableOptionCentering]="true" panelClass="matopencountry" formControlName="country"
                placeholder="Choose country" [ngClass]="{ 'is-invalid': sign.country.errors && sign.country.touched }">
                <mat-option *ngFor="let country of countries" [value]="country">
                  {{country.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="sign.country.errors && sign.country.touched" style="font-weight: 600; font-size: 10px; color: red;">
                <mat-error *ngIf="sign.country.errors.required"> Country is required</mat-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="upload">
            <label>Upload Profile Picture</label>
            <input class="form-control" [placeholder]=this.userPhotoFile.name type="text" readonly />
          </div>
          <div>
            <label *ngIf="!upload">Upload Profile Picture</label>
            <div class="profilepicture-update" *ngIf="!upload">
              <img src="../../../assets/images/upload.svg" alt="" style="width: 15%" />
              <h5>Select a file or drag and drop here</h5>
              <span>File size maximum 20kb with JPEG format</span>

              <div class="btns">
                <label for="imageUpload" class="btn">Upload</label>
                <input type="file" id="imageUpload" accept="image/*" formControlName="photo" style="display: none"
                  (change)="onFileSelected($event)" #imgInputs />

                <span *ngIf="oversize" style="color: red; text-align: left">File size must be less than 20 Kb</span>
                <span *ngIf="imageType" style="color: red; text-align: left">
                  Selected file is not an allowed image type
                </span>
              </div>

            </div>

            <div style="padding-bottom: 15px;" class="radio-items">
              <mat-radio-button 
              [checked]="selectedOption === 'withDeposit'" 
              (click)="acceptSubscription('withDeposit')"
              class="small-radio-button">
              Access platform with deposit.
              <img 
                style="margin-left: 5px;margin-top: -4px;" width="15px" [src]="tooltipImagePath"
                matTooltip="The full access is based on a minimum deposit of U$5000 as credit, which will need to be paid after the registration is confirmed." 
              />
            </mat-radio-button>

            <div class="terms" *ngIf="selectedOption === 'withDeposit'"
            style="padding-bottom: 0px;padding-left: 28px;
            margin-top: -7px;
            margin-bottom: -7px;">
              <span>
                If you need a quote, click <b (click)="requestQuotation()">here</b>.
              </span>
            </div>
          
            <mat-radio-button 
              [checked]="selectedOption === 'withoutDeposit'" 
              (click)="acceptSubscription('withoutDeposit')"
              class="small-radio-button">
              Access platform without deposit.
              <img 
                style="margin-left: 5px;margin-top: -4px;" width="15px;" [src]="tooltipImagePath"
                matTooltip="Access without deposit is limited to one or more sensors and functionality." 
              />
            </mat-radio-button>
            </div>

            <div class="terms" id="term" style="padding-bottom: 17px;">
              <mat-checkbox [checked]="check" (change)="acceptTerms()">
                <span>Accept the
                  <b (click)="OpenPopup()">Terms and Conditions.</b></span>
              </mat-checkbox>
            </div>

            <div>
              <button mat-raised-button color="primary" [disabled]="signupform.invalid || !check"
                id="buttons">REGISTER</button>
            </div>
            <div class="regist-inner">
              <span>Already registered ?
                <a href="#" routerLink="/login">Sign In</a></span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>