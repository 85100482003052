<div class="setion scrollbar" id="scrollcustom">
  <div class="head">
    <h4>{{ (currentUser.obfuscated && operator.obfuscateName) ? operator.obfuscateName : operator.name }} EULA</h4>
  </div>

  <div class="names">
    <div class="adres">
      <div *ngIf="operator?.operatorKey === OPERATORS.SATELLOGIC">
        <iframe src="https://satellogic.com/license-agreement/" width="100%" height="100%" seamless="seamless"
          style="cursor: pointer;">
        </iframe>
        <a (click)="goToEULA('https://satellogic.com/license-agreement/')"><b>Source:
          </b>https://satellogic.com/license-agreement/</a>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.UMBRA">
        <iframe src="https://umbra.space/terms-of-use/" width="100%" height="100%" seamless="seamless"
          style="cursor: pointer;">
        </iframe>
        <a (click)="goToEULA('https://umbra.space/terms-of-use/')"><b>Source: </b>https://umbra.space/terms-of-use/</a>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.HEAD">
        <iframe src="../../../../../assets/files/EULA-HEAD-CG.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
        <a (click)="goToEULA('https://www.head-aerospace.eu/client-area-jl/')"><b>Source:
          </b>https://www.head-aerospace.eu/client-area-jl/</a>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.STE">
        <iframe src="../../../../../assets/files/STE-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.AT21">
        <iframe src="../../../../../assets/files/EULA-21AT.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.KOMPSAT">
        <iframe src="../../../../../assets/files/EULA-KOMSAT.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.ISI">
        <iframe src="../../../../../assets/files/EULA-ISI.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.AXELSPACE && userType === 'academia'">
        <iframe src="../../../../../assets/files/EULA-Axel-Academic.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.AXELSPACE && userType === 'media'">
        <iframe src="../../../../../assets/files/EULA-Axel-Media.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.AXELSPACE && userType === 'others'">
        <iframe src="../../../../../assets/files/EULA-Axel-Common.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.GHG">
        <iframe src="../../../../../assets/files/GHG.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.USL">
        <app-usl-eula [operator] = this.operator [userDetails] = this.currentUser></app-usl-eula>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.ORORATECH">
        <iframe src="../../../../../assets/files/OroraTech.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.SATVU">
        <iframe src="../../../../../assets/files/SATVU-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.BLACKSKY && eulaPath">
        <iframe src="../../../../../assets/files/EULA-BlackSky-ING.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
        height="100%" seamless="seamless" style="cursor: pointer;">
      </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.BLACKSKY && !eulaPath">
        <iframe src="../../../../../assets/files/EULA-BlackSky-COMMERCIAL.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.WYVERN">
        <iframe src="../../../../../assets/files/Wyvern-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.SPIRE">
        <iframe src="../../../../../assets/files/Spire-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.CLYDE">
        <iframe src="../../../../../assets/files/EULA-Clyde.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.GOKTURK">
        <iframe src="../../../../../assets/files/Gokturk-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.ECURS">
        <iframe src="../../../../../assets/files/ECURS-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>

      <div *ngIf="operator?.operatorKey === OPERATORS.CAPELLA">
        <iframe src="../../../../../assets/files/Capella-EULA.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%"
          height="100%" seamless="seamless" style="cursor: pointer;">
        </iframe>
      </div>
    </div>
  </div>
</div>


<div class="thanks" *ngIf="data?.type != 'save'">
  <button mat-flat-button color="primary" class="delete" (keydown)="onKeyPress($event, 1)" (click)="onKeyPress($event, 1)"
    style="margin: 30px 0 0 0; background-color: #1059a9; color: #fff">
    Ok, Thanks
  </button>
</div>

<div class="thanks" fxLayoutGap="15px" *ngIf="data?.type === 'save'">
  <button style="border: 1px solid rgba(0,0,0,.12);"
   (keydown)="onKeyPress($event, 1)" (click)="onKeyPress($event, 1)" class="custom-button">
    Close</button>
    <button mat-flat-button color="primary" class="delete" (click)="savePdf(operator?.operatorKey)"
    style="margin: 30px 0 0 0; background-color: #1059a9; color: #fff">
    Save
  </button>
</div>