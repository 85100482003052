<div class="container" style="padding-top: 5px;">
  <!-- <ngx-spinner *ngIf="freeze" color="white" type="ball-clip-rotate" [fullScreen]="false" size="medium">
    <p class="textdetails">Downloading...{{this.progress }}%</p>
  </ngx-spinner> -->
  <div fxLayout="column">
    <div class="card-box" style="display: flex; align-items: center; gap: 20px;">
      <mat-checkbox [checked]="jump" matTooltip="Jump on Collection hover" (change)="removeTarget()"
        style="margin: 0" disabled="false">
        <mat-label class="label">Locate Task on Map</mat-label>
      </mat-checkbox>
    
      <!-- NDVI Indication -->
      <!-- <div class="ndvi-indication" style="display: inline-flex;" *ngIf="selectedIndices?.checked">
        <div class="color-label">
          <div class="color-box" style="background-color: #f0a783"></div>
          <span>Water</span>
        </div>
        <div class="color-label">
          <div class="color-box" style="background-color: #ffff05"></div>
          <span>Bare soil and built-up</span>
        </div>
        <div class="color-label">
          <div class="color-box" style="background-color: #8dd874"></div>
          <span>General range for vegetation linked to plant/tree health</span>
        </div>
        <div class="color-label">
          <div class="color-box" style="background-color: #00cc00"></div>
          <span>Dense forest canopy</span>
        </div>
      </div> -->
    </div>

    <div>
      <div class="flex">
        <div class="left scrollbar" id="scrollcustom">
          <!-- Accordian-set -->

          <!-- scroll-start -->
          <div class="collected">
            <div class="collection-head">
              <ul>
                <li>
                  <span>Project Name</span>
                </li>
                <li>
                  <span>Task Name</span>
                </li>
                <li>
                  <span>Task ID</span>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="projectData?.length > 0" class="m-0">
            <div (mouseenter)="jump2target(collectionIndex, undefined, undefined)">
              <div class="details-summary collect" style="flex-wrap: wrap; background-color: transparent">
                <div class="collection-names" style="width: 100% !important">
                  <div class="collection-result">
                    <ul *ngIf="projectData[0]">
                      <li>
                        <div class="collection-th-menu" [matMenuTriggerFor]="menu" style="cursor: pointer;">
                          <span [matTooltip]="projectName?.length > 12 ? projectName : ''">{{ projectName }}</span>
                          <mat-icon class="material-icons-outlined">
                            expand_more
                          </mat-icon>
                        </div>
                        <mat-menu #menu="matMenu" class="collection-list-menu header-mat-menu">
                          <div class="collection-buttons"
                            *ngFor="let collection of projectData; index as collectionIndex">
                            <button mat-menu-item (click)="clickMenuItem(collection, collectionIndex)">
                              <span [matTooltip]="collection?.project?.length > 20 ? collection?.project : ''">{{
                                collection?.project }}</span></button>
                          </div>
                        </mat-menu>
                      </li>

                      <li>
                        <div class="collection-th-menu" [matMenuTriggerFor]="task" style="cursor: pointer;">
                          <span [matTooltip]="taskName?.length > 12 ? taskName : ''">{{taskName}}</span>
                          <mat-icon class="material-icons-outlined">
                            expand_more
                          </mat-icon>
                        </div>
                        <mat-menu #task="matMenu" class="collection-list-menu header-mat-menu">
                          <div *ngFor="let tasks of collections; index as collectionIndex">
                            <button mat-menu-item (click)="clickTask(tasks, collectionIndex);restTask()">
                              <span [matTooltip]="tasks?.taskname?.length > 20 ? tasks?.taskname : ''">{{tasks?.taskname
                                }}</span></button>
                          </div>
                        </mat-menu>
                      </li>
                      <li>
                        <div class="collection-th-menu" [matMenuTriggerFor]="taskId" style="cursor: pointer;">
                          <span [matTooltip]="taskid?.length > 12 ? taskid : ''">{{taskid}}</span>
                          <mat-icon class="material-icons-outlined">
                            expand_more
                          </mat-icon>
                        </div>
                        <mat-menu #taskId="matMenu" class="collection-list-menu header-mat-menu">
                          <div>
                            <button mat-menu-item>
                              <span
                                [matTooltip]="collection?.taskId?.length > 20 ? collection?.taskId : ''">{{collection?.taskId
                                }}</span></button>
                          </div>
                        </mat-menu>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
              <div class="main-panel">
                <div class="sub-head">
                  <span>{{ collection?.taskId }}</span>
                  <div style="display: flex;">
                    <mat-checkbox *ngIf="(collection?.frequency == 2 && spire) || (collection?.recurrencyArray?.length > 1 && !usl)" matTooltip="Timeseries view" (change)="clickSliderView($event)"
                      style="margin: 0 0 8px 10px" [disabled]="showDailyRecurrencyCheckboxes" [checked]="timeseries">
                      <mat-label class="label">Timeseries</mat-label>
                    </mat-checkbox>
                    <mat-icon *ngIf="timeseries && collection?.frequency == 2 && spire" class="icon-filter" matTooltip="Filter by dates"
                     (click)="filterByDate()">tune</mat-icon>
                  </div>
                </div>
                
                <div *ngIf="collection?.feasibilityTask && collection?.opportunityList?.length > 0" class="recurrent-dropdown">
                  <div class="recurrent-head">
                    <span class="head-color">Opportunity Date</span>
                    <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;">
                      <mat-select [(value)]="collection.opportunityList" [disableOptionCentering]="true" panelClass="matopen" #mySelect>
                        <mat-option *ngFor="let item of collection?.opportunityList; let i = index" [value]="collection.opportunityList"
                          style="width: 100%;" [disabled]="(item.disabled && showDailyRecurrencyCheckboxes) || timeseries">
                          <div class="d-flex justify-content-between">
                            <span *ngIf="showDailyRecurrencyCheckboxes">
                              <mat-checkbox style="padding-top: 6px !important;" color="primary" [disabled]="item.disabled"
                                (change)="compareOpportunity(collectionIndex,i,undefined,$event,'dropdown')"
                                [ngModel]="item.checked">
                                {{ item.date | date: "dd/MM/yyyy" : "GMT"}}
                              </mat-checkbox>
                            </span>
                            <span *ngIf="!showDailyRecurrencyCheckboxes" (click)="selectOpportunity(item,'normal')">
                              {{ item.date | date: "dd/MM/yyyy" : "GMT"}}</span>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                    </span>
                  </div>
                
                  <div class="recurrent-head">
                    <span class="head-color">Opportunity Id </span>
                    <span style="padding-top: 15px;">
                      {{opportunityId}}
                    </span>
                  </div>
                
                  <div class="compare-heading">
                    <mat-checkbox color="primary" (change)="toggleDailyRecurrency($event)" [ngModel]="showDailyRecurrencyCheckboxes"
                    [disabled]="this.collection.sensors[0]?.status === 'archived' || timeseries || sensorLength > 1">
                      <span class="head-color">Compare</span>
                    </mat-checkbox>
                  </div>
                </div>

                <div *ngIf="collection?.frequency === 1  && collection?.recurrencyArray?.length > 0" class="recurrent-dropdown">
                  <div class="recurrent-head"
                    *ngIf="collection?.repeatFrequency === 'Daily' || collection?.repeatFrequency === 'Sub-daily'">
                    <span class="head-color">Daily Recurrency</span>
                    <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;">
                      <mat-select [(value)]="collection.repeatFrequency" [disableOptionCentering]="true"
                        panelClass="matopen" #mySelect>
                        <mat-option *ngFor="let item of collection?.recurrencyArray; let i = index"
                          [value]="collection.repeatFrequency" style="width: 100%;"
                          [disabled]="(item.disabled && showDailyRecurrencyCheckboxes) || timeseries">
                          <div class="d-flex justify-content-between">
                            <span *ngIf="showDailyRecurrencyCheckboxes">
                              <mat-checkbox style="padding-top: 6px !important;" color="primary"
                                [disabled]="item.disabled"
                                (change)="toggleRecurrentSensor(collectionIndex,i,undefined,$event,'dropdown',collection)"
                                [ngModel]="item.checked">
                                {{ item?.start | date: "dd/MM/yyyy" : "GMT"}}
                              </mat-checkbox>
                            </span>
                            <span *ngIf="!showDailyRecurrencyCheckboxes" (click)="selectOption(item,'normal')">
                              {{ item?.start | date: "dd/MM/yyyy" : "GMT"}}</span>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                    </span>
                  </div>

                  <div class="recurrent-head"
                    *ngIf="collection?.repeatFrequency != 'Daily' && collection?.repeatFrequency != 'Sub-daily'">
                    <span class="head-color">{{collection?.repeatFrequency }} Recurrency</span>
                    <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;">
                      <mat-select [value]=" collection?.recurrencyArray[0]" [disableOptionCentering]="true"
                        panelClass="matopen" #mySelect>
                        <mat-select-trigger> {{selectionLabel | date:"dd/MM/yyyy" : "GMT"}} - {{selectionLabelEnd | date:"dd/MM/yyyy" : "GMT"}} </mat-select-trigger>
                        <mat-option *ngFor="let item of collection?.recurrencyArray; let i = index" [value]="item"
                          style="width: 100%;" [disabled]="(item.disabled && showDailyRecurrencyCheckboxes) || timeseries">
                          <div class="d-flex justify-content-between">
                            <span *ngIf="showDailyRecurrencyCheckboxes">
                              <mat-checkbox style="padding-top: 6px !important;" color="primary"
                                [disabled]="item.disabled"
                                (change)="toggleRecurrentSensor(collectionIndex,i,undefined,$event,'dropdown',collection)"
                                [ngModel]="item.checked">
                                {{ item?.start | date: "dd/MM/yyyy" : "GMT"}} - {{ item?.end
                                | date: "dd/MM/yyyy" : "GMT"}}
                              </mat-checkbox>
                            </span>
                            <span *ngIf="!showDailyRecurrencyCheckboxes" (click)="selectOption(item,'normal')">
                              {{ item?.start | date: "dd/MM/yyyy" : "GMT"}} - {{ item?.end
                              | date: "dd/MM/yyyy" : "GMT"}}</span>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                    </span>
                  </div>

                  <div class="recurrent-head" *ngIf="collection?.repeatFrequency === 'Sub-daily'">
                    <span class="head-color">Sub-daily Count</span>
                    <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;"
                    *ngIf="selectedCheckboxes.length!==1">
                      <mat-select [(value)]="collection.subDailyCount"[disableOptionCentering]="true"
                        panelClass="matopen" #mySelect>
                        <mat-option *ngFor="let item of [].constructor(collection?.subDailyCount); let i = index"
                          [value]="collection.subDailyCount" style="width: 100%;" (click)="selectSubdaily(i+1)"
                          [disabled] = "(this.selectedCheckboxes?.length > 1) || timeseries">

                          <div class="d-flex justify-content-between">
                            {{ i+1 }}
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                  </span>
                  <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;"  *ngIf="selectedCheckboxes.length === 1">
                    <mat-select [value]="subDailyCountarray[0].value" [disableOptionCentering]="true"
                      panelClass="matopen" #mySelect>
                      <mat-select-trigger> {{selectedSubDailyItemValue}} </mat-select-trigger>
                      <mat-option  *ngFor="let item of subDailyCountarray; let i = index" [value]="item.value" [disabled]="item.disabled || timeseries"
                        style="width: 100%;">
                        <div class="d-flex justify-content-between">
                          <span  *ngIf="selectedCheckboxes.length === 1">
                            <mat-checkbox style="padding-top: 6px !important;" color="primary"
                            (change)="selectSubdailyRecurrent($event,item)"
                            [ngModel]="item.checked"[value]="item" [disabled]="item.disabled">
                            {{item.value }}
                          </mat-checkbox>
                          </span>
                          
                        </div>
                      </mat-option>
                    </mat-select>
                    <mat-icon class="icon-spacing">expand_more</mat-icon>
                  </span>
                   
                  </div>

                  <div class="recurrent-head"  *ngIf="this.collection?.sensors[0]?.operatorKey === this.OPERATOR.LBAND && showDailyRecurrencyCheckboxes && this.collection?.sensors[0]?.scenes[0]?.signals"
                  >
                    <span class="head-color">Signals : </span>
                    <span (click)="toggleOptions(bandSelect)" style="padding-top: 15px;">
                      <mat-select value="both" [disableOptionCentering]="true"
                        panelClass="matopen" #bandSelect value="{{this.collection?.sensors[0]?.scenes[0]?.signals[0]?.product}}">
                        <mat-option *ngFor="let item of this.collection.sensors[0]?.scenes[0]?.signals; let i = index" [value]="item.product" style="width: 100%;" [disabled]="timeseries"
                        (click)="selectSignals(this.collection?.sensors[0],item.product)">
                         <span>{{item.product}}</span>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                  </span>
                  </div>

                  <div class="recurrent-head" *ngIf="this.collection.sensors[0]?.operatorKey === this.OPERATOR.USL && showDailyRecurrencyCheckboxes"
                  >
                    <span class="head-color">Bands</span>
                    <span (click)="toggleOptions(bandSelect)" style="padding-top: 15px;">
                      <mat-select value="both" [disableOptionCentering]="true"
                        panelClass="matopen" #bandSelect>
                        <mat-option value="xband" style="width: 100%;" (click)="selectBands('xband')" [disabled]="timeseries">X (9327 MHz - 9428 MHz)</mat-option>
                        <mat-option value="sband" style="width: 100%;" (click)="selectBands('sband')" [disabled]="timeseries">S (3024 MHz- 3080 MHz)</mat-option>
                        <mat-option value="both" style="width: 100%;" (click)="selectBands('both')" [disabled]="timeseries">All</mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                  </span>
                  </div>

                  <div class="compare-heading">
                    <mat-checkbox color="primary" (change)="toggleDailyRecurrency($event)"
                      [ngModel]="showDailyRecurrencyCheckboxes" 
                      *ngIf="(collection?.recurrencyArray?.length > 1 && collection?.repeatFrequency != 'Sub-daily')
                      || (collection?.subDailyCount > 1 && collection?.repeatFrequency === 'Sub-daily')"
                      [disabled]="this.collection.sensors[0]?.status === 'archived' || timeseries || sensorLength > 1">
                      <span class="head-color">Compare</span>
                    </mat-checkbox>
                  </div> 
                </div>

                <div *ngIf="collection?.recurrencyArray[0]?.repeatFrequency === 'GHG-Monthly' && showDailyRecurrencyCheckboxes" class="site-heads top">
                  
                  <div class="recurrent-head">
                    <span class="head-color">Site Name</span>
                    <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;">
                      <mat-select [(value)]="siteSelected ? siteSelected?.siteId : collection?.siteData[0].siteId" [disableOptionCentering]="true"
                        panelClass="matopen" #mySelect>
                        <mat-select-trigger>
                          {{siteSelected ? siteSelected?.siteName : collection?.siteData[0].siteName}}
                          </mat-select-trigger>
                        <mat-option *ngFor="let item of subDailyCountarray; let i = index" [value]="item.siteId"
                          [disabled]="item.disabled" style="width: 100%;">
                          <div class="d-flex justify-content-between">
                            <span>
                              <mat-checkbox style="padding-top: 6px !important;" color="primary"
                                (change)="selectSiteRecurrent($event,item)" [ngModel]="item.checked" [value]="item"
                                [disabled]="item.disabled">
                                {{ item.siteName}}
                              </mat-checkbox>
                            </span>

                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                    </span>

                  </div>
                  
                  <div class="recurrent-head">
                    <span class="head-color">Monthly Recurrency</span>
                    <span (click)="toggleOptions(mySelects)" style="padding-top: 15px;" *ngIf="this.selectedSites?.length === 1">
                      <mat-select [value]=" collection.recurrencyArray[0]" [disableOptionCentering]="true"
                        panelClass="matopen" #mySelects>
                        <mat-select-trigger>
                          {{ (monthSelected ? monthSelected?.start : collection.recurrencyArray[0].start) | date: "dd/MM/yyyy" : "GMT" }} - {{ (monthSelected ? monthSelected?.end : collection.recurrencyArray[0].end)
                            | date: "dd/MM/yyyy" : "GMT"}}
                          </mat-select-trigger>
                        <mat-option *ngFor="let item of collection?.recurrencyArray; let i = index" [value]="item"
                          style="width: 100%;" [disabled]="(item.disabled && showDailyRecurrencyCheckboxes) || timeseries ||
                          this.selectedSites?.length > 1">
                          <div class="d-flex justify-content-between">
                            <span *ngIf="showDailyRecurrencyCheckboxes">
                              <mat-checkbox style="padding-top: 6px !important;" color="primary"
                                [disabled]="item.disabled || dateDisable"
                                (change)="!dateDisable ? toggleRecurrentSensor(collectionIndex,i,undefined,$event,'dropdown','Month') : ''"
                                [ngModel]="item.checked">
                                {{ item?.start | date: "dd/MM/yyyy" : "GMT" }} - {{ item?.end
                                | date: "dd/MM/yyyy" : "GMT"}}
                              </mat-checkbox>
                            </span>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                    </span>

                    <span (click)="toggleOptions(mySelects)" style="padding-top: 15px;" *ngIf="this.selectedSites?.length != 1">
                      <mat-select [value]=" collection.recurrencyArray[0]" [disableOptionCentering]="true"
                        panelClass="matopen" #mySelects>
                        <mat-select-trigger>
                          {{ (monthSelected ? monthSelected?.start : collection.recurrencyArray[0].start) | date: "dd/MM/yyyy" : "GMT" }} - {{ (monthSelected ? monthSelected?.end : collection.recurrencyArray[0].end)
                            | date: "dd/MM/yyyy" : "GMT"}}
                          </mat-select-trigger>
                        <mat-option *ngFor="let item of collection?.recurrencyArray; let i = index" [value]="item"
                          style="width: 100%;" [disabled]="(item.disabled && showDailyRecurrencyCheckboxes) || timeseries ||
                          this.selectedSites?.length != 1"
                          >
                          <div class="d-flex justify-content-between">
                            <span *ngIf="showDailyRecurrencyCheckboxes">
                              <div style="padding-top: 6px !important;" color="primary">
                                {{ item?.start | date: "dd/MM/yyyy" : "GMT" }} - {{ item?.end
                                | date: "dd/MM/yyyy" : "GMT"}}
                                </div>
                            </span>
                          </div>
                        </mat-option>
                      </mat-select>
                      <mat-icon class="icon-spacing">expand_more</mat-icon>
                    </span>
                  </div>
                
                  <div class="compare-heading">
                    <mat-checkbox color="primary" (change)="toggleDailyRecurrency($event)" [ngModel]="showDailyRecurrencyCheckboxes"
                    [disabled]="this.collection.sensors[0]?.status === 'archived' || timeseries || sensorLength > 1">
                      <span class="head-color">Compare</span>
                    </mat-checkbox>
                  </div>
                </div>

                <div class="details-content">
                  <div class="details-content-inner">
                    <div class="collections">
                      <div class="card" *ngIf="!showDailyRecurrencyCheckboxes">
                        <div class="details-block sub-block" *ngFor="
                                let sensor of collection?.sensors;
                                index as sensorIndex
                              ">
                          <mat-accordion>
                            <mat-expansion-panel [expanded]="isFirst">
                              <mat-expansion-panel-header #panelH (click)="panelH._toggle()" style="padding: 0 9px;">
                                <div class="details-summary">

                                  <mat-checkbox class="example-margin" color="primary"
                                    *ngIf="!showDailyRecurrencyCheckboxes" [disabled]="sensor?.status === 'archived'"
                                    [checked]="collections[collectionIndex]?.sensors[sensorIndex]?.checked" (change)="
                                    toggleSensor(
                                      collectionIndex,
                                      sensorIndex,
                                      undefined,
                                      $event,
                                      sensor?.scenes[0]
                                    )
                                  ">
                                    <span class="sensor-name">{{ (currentUser?.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor?.name }}</span>
                                    <img *ngIf="sensor?.resolution_tooltip" style="margin-left: 5px;margin-top: -3px;"
                                      width="14px" [src]="tooltipImagePath"
                                      [matTooltip]="sensor?.resolution_tooltip" />
                                      <img *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')" style="margin-left: 5px;margin-top: -3px;"
                                    width="14px" [src]="tooltipImagePath"
                                    matTooltip="If the imaging were over land, you would receive an orthorectified (L1D) product ten hours from the first data (Geocorrected (L1B))." />
                                      <span class="legend-pos" *ngIf="(sensor?.operatorKey === this.OPERATOR.USL || sensor?.operatorKey === this.OPERATOR.CLYDE 
                                        || sensor?.operatorKey === this.OPERATOR.SPIRE || sensor?.operatorKey === this.OPERATOR.LBAND)">
                                        <span class="color-legend" [ngStyle]="{'background-color': colorCode[sensor?.operatorKey]}">
                                        </span>
                                      </span>
                                  </mat-checkbox>
                                  <mat-checkbox class="example-margin" color="primary"
                                    *ngIf="showDailyRecurrencyCheckboxes" [checked]="sensor.checked" (change)="
                                    toggleRecurrentSensor(
                                      collectionIndex,
                                      sensorIndex,
                                      undefined,
                                      $event,
                                    undefined,
                                    sensor?.scenes[0]
                                    )
                                  " [(ngModel)]="sensor.checked">
                                    <span class="sensor-name">{{ (currentUser?.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor?.name }}</span>
                                    <img *ngIf="sensor?.resolution_tooltip" style="margin-left: 5px;margin-top: -3px;"
                                      width="14px" [src]="tooltipImagePath"
                                      [matTooltip]="sensor?.resolution_tooltip" />
                                    <img *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')" style="margin-left: 5px;margin-top: -3px;"
                                    width="14px" [src]="tooltipImagePath"
                                    matTooltip="If the imaging were over land, you would receive an orthorectified (L1D) product ten hours from the first data (Geocorrected (L1B))." />
                                  </mat-checkbox>
                                  <span class="align-head" *ngIf="sensor?.latency === 'Emergency'">No preview
                                    available</span>

                                    <div *ngIf="collection?.recurrencyArray[0]?.repeatFrequency === 'GHG-Monthly'"
                                    class="metric" fxLayoutGap="10px">
                                      <span class="head-color">Metric :</span>
                                      <span (click)="toggleOptions(bandSelect)" style="padding-top: 15px;">
                                        <mat-select value="kg/hr" [disableOptionCentering]="true" panelClass="matopen" #bandSelect>
                                          <mat-option value="kg/hr" style="width: 100%;" (click)="selectMetric('kg')">kg/hr</mat-option>
                                          <mat-option value="ppb" style="width: 100%;" (click)="selectMetric('ppb')">ppb</mat-option>
                                        </mat-select>
                                        <mat-icon class="icon-spacing">expand_more</mat-icon>
                                      </span>
                                    </div>

                                    <div class="metric" fxLayoutGap="10px" *ngIf="sensor?.operatorKey === this.OPERATOR.USL"
                                    >
                                      <span class="head-color">Bands : </span>
                                      <span (click)="toggleOptions(bandSelect)" style="padding-top: 15px;">
                                        <mat-select value="both" [disableOptionCentering]="true"
                                          panelClass="matopen" #bandSelect>
                                          <mat-option value="xband" style="width: 100%;" (click)="selectBands('xband')" [disabled]="timeseries">X (9327 MHz - 9428 MHz)</mat-option>
                                          <mat-option value="sband" style="width: 100%;" (click)="selectBands('sband')" [disabled]="timeseries">S (3024 MHz- 3080 MHz)</mat-option>
                                          <mat-option value="both" style="width: 100%;" (click)="selectBands('both')" [disabled]="timeseries">All</mat-option>
                                        </mat-select>
                                        <mat-icon class="icon-spacing">expand_more</mat-icon>
                                    </span>
                                    </div>

                                    <div class="metric" fxLayoutGap="10px" *ngIf="sensor?.operatorKey === this.OPERATOR.LBAND && sensor.scenes[0]?.signals"
                                    >
                                      <span class="head-color">Signals : </span>
                                      <span (click)="toggleOptions(bandSelect)" style="padding-top: 15px;">
                                        <mat-select value="both" [disableOptionCentering]="true"
                                          panelClass="matopen" #bandSelect value="{{sensor.scenes[0]?.signals[0]?.product}}">
                                          <mat-option *ngFor="let item of sensor.scenes[0]?.signals; let i = index" [value]="item.product" style="width: 100%;" [disabled]="timeseries"
                                          (click)="selectSignals(sensor,item.product)">
                                           <span>{{item.product}}</span>
                                          </mat-option>
                                        </mat-select>
                                        <mat-icon class="icon-spacing">expand_more</mat-icon>
                                    </span>
                                    </div>

                                    <div class="down-icon" fxLayoutGap="10px">
                                      <mat-icon *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'"
                                      (click)="checkForDelivered(sensor) ? '' : releaseVADP(collection.taskId,sensor)"
                                      [disabled]="checkForDelivered(sensor)"
                                      matTooltip="Release for VADP" [ngClass]="checkForDelivered(sensor) ? 'my-icon-disable' : 'drive'"
                                      >3d_rotation</mat-icon>

                                      <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'
                                      && (!checkForRelease(sensor)) || ((checkForRelease(sensor) && checkForDelivered(sensor)))"
                                      style="cursor: pointer;" width="21px" matTooltip="Request for VADP Refund"
                                      [src]="refundImage"
                                      alt=""
                                      (click)="refundVADP(collectionIndex, sensorIndex,sensor)"
                                    />

                                    <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                    (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed' && 
                                    (checkForRelease(sensor) && !checkForDelivered(sensor))" style="cursor: default;" width="21px"
                                      src="../../../assets/images/refund-inactive.png" alt=""
                                    />

                                      <mat-icon *ngIf="(sensor.operatorKey === OPERATOR.CAPELLA || (sensor.operatorKey === OPERATOR.UMBRA && (sensor.isAdditionalProducts || sensor.isAdditionalCSIProducts)) || 
                                      (sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush' && sensor?.assetsDelivered))
                                      && ((sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both')))"
                                      class="drive" (click)="getAdditionalData(collection.taskId,sensor)"
                                      [matTooltip]="this.downloadTooltip"
                                      (mouseover)="getTooltipMessage(sensor)"
                                      (mouseout)="onMouseOut()"
                                      [matTooltipDisabled]="tooltipDisabled"
                                      >add_circle_outline</mat-icon>
                                      <img (click)="
                                      download(
                                          collectionIndex,
                                          sensorIndex,
                                          undefined,sensor
                                        )
                                        " *ngIf="(sensor.operatorKey !== OPERATOR.SPIRE && sensor.operatorKey !== OPERATOR.CLYDE && (sensor.scenesList?.length > 0 && (sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both')))) && !demoView" 
                                        [style.width]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? '23px' : '21px'" style="cursor: pointer;"
                                        [src]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? downloadedImage : downloadImage" />
                                        <div *ngIf="sensor.operatorKey === OPERATOR.SPIRE && sensor.noOfRefreshes != null">
                                          <span for="alphaScn" class="currency" style="color: #1059A9;">{{ sensor.updatedRefreshes == 0 ? 0 : sensor.updatedRefreshes || sensor.noOfRefreshes }}</span>
                                          <span class="sensor-name" style="padding-left: 3px;"> refreshes </span>
                                          <span *ngIf="sensor?.isHistorical" class="sensor-name" style="padding-left: 3px;"> and </span>
                                          <span *ngIf="sensor?.isHistorical" for="alphaScn" class="currency" style="color: #1059A9;padding-left: 3px;">{{ sensor.updatedFetches == 0 ? 0 : sensor.updatedFetches || sensor.noOfHistoricalData }}</span>
                                          <span *ngIf="sensor?.isHistorical" class="sensor-name" style="padding-left: 3px;"> historical fetches
                                          </span>
                                          <span class="sensor-name" style="padding-left: 3px;"> left
                                            <img *ngIf="sensor?.isHistorical" style="margin-left: 5px;margin-top: -2px;"
                                            width="14px" [src]="tooltipImagePath"
                                            matTooltip="Please note that your historical fetches expire when the task's time of interest (TOI) ends or when the number of refreshes becomes zero - whichever happens first." />
                                            <span style="margin-left: 15px;">
                                            <img
                                            *ngIf="(!sensor?.scenes[0].aisDownloadable || (sensor?.unUsedRefreshes === 0 && sensor?.unUsedFetches === 0))"
                                            style="cursor: default;" width="21px" src="../../../assets/images/refund-inactive.png" alt="" />
                                            <img (click)="OpenReturnRequest(collectionIndex,
                                              sensorIndex,
                                              0,
                                              sensor?.scenes[0])"
                                              *ngIf="sensor?.scenes[0].aisDownloadable && (sensor?.unUsedRefreshes > 0 || sensor?.unUsedFetches > 0)"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage"
                                               alt="" />
                                              </span>
                                          </span>
                                        </div>
                                        <img *ngIf="(sensor.operatorKey !== OPERATOR.SPIRE && sensor.operatorKey !== OPERATOR.CLYDE && (sensor.scenesList?.length < 1 || (sensor?.status != 'completed' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'view'))) || demoView" width="21px"
                                          src="../../../assets/images/cloud.png" alt="" style="cursor: default;" /> 
                                          
                                         <img *ngIf="sensor.operatorKey !== OPERATOR.SPIRE && sensor.operatorKey !== OPERATOR.CLYDE && (sensor?.status === 'archived' || sensor?.status === 'restored')" 
                                         [src]="(sensor?.status === 'archived') ? '../../../assets/images/archive.svg' : '../../../assets/images/unarchive.svg'"
                                         alt="" [ngClass]="sensor?.status === 'archived' ? 'my-icon' : 'my-icon-disable'"
                                         (click)="sensor?.status === 'archived' ? openRestoreRequest(collectionIndex,sensorIndex,sensor) : null"/> 
                                    </div>

                                </div>
                                <mat-icon class="hiddenicon" (click)="panelH._toggle()" aria-hidden="false"
                                  aria-label="Example home icon" fontIcon="home"></mat-icon>  
                              </mat-expansion-panel-header>
                              <div *ngIf="sensor?.siteData && sensor?.operatorKey === this.OPERATOR.GHG" class="recurrent-dropdown">
                                <div class="recurrent-head">
                                  <span class="head-color">Site Name</span>
                                  <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;" *ngIf="selectedCheckboxes.length!==1">
                                    <mat-select [(value)]="sensor.siteData" [disableOptionCentering]="true" panelClass="matopen" #mySelect>
                                      <mat-option *ngFor="let item of sensor?.siteData; let i = index" [value]="sensor.siteData" style="width: 100%;"
                                        (click)="handleSiteSelection(item, collectionIndex, i, $event);"
                                        [disabled]="!sensor.checked">

                                        <div class="d-flex justify-content-between">
                                          {{ item.siteName}}
                                        </div>
                                      </mat-option>
                                    </mat-select>
                                    <mat-icon class="icon-spacing">expand_more</mat-icon>
                                  </span>
                                </div>

                                <div class="recurrent-head">
                                  <span class="head-color">Monthly Recurrency</span>
                                  <span (click)="toggleOptions(mySelect)" style="padding-top: 15px;">
                                    <mat-select [(value)]="selectedRecurrency" [disableOptionCentering]="true" panelClass="matopen"
                                      #mySelect>
                                      <mat-option *ngFor="let item of collection?.recurrencyArray; let i = index" [value]="item" style="width: 100%;"
                                        [disabled]="(item.disabled && showDailyRecurrencyCheckboxes) || timeseries ||
                                        !sensor.checked">
                                        <div class="d-flex justify-content-between">
                                          <span *ngIf="!showDailyRecurrencyCheckboxes" (click)="!timeseries ? handleSelection(item, collectionIndex, i, $event) : ''">
                                            {{ item?.start | date: "dd/MM/yyyy" : "GMT" }} - {{ item?.end
                                            | date: "dd/MM/yyyy" : "GMT"}}</span>
                                        </div>
                                      </mat-option>
                                    </mat-select>
                                    <mat-icon class="icon-spacing">expand_more</mat-icon>
                                  </span>
                                </div>

                                <div class="compare-heading">
                                  <mat-checkbox color="primary" (change)="toggleDailyRecurrency($event)" [ngModel]="showDailyRecurrencyCheckboxes"
                                  [disabled]="this.collection.sensors[0]?.status === 'archived' || timeseries || sensorLength > 1">
                                    <span class="head-color">Compare</span>
                                  </mat-checkbox>
                                </div>
                              </div>


                              <div class="details-content">
                                <div class="details-content-inner">
                                  <div *ngIf="sensor?.latency === 'Emergency'"
                                    style="border-bottom: 1px solid #EAECF0;"></div>
                                  <div *ngIf="sensor?.latency != 'Emergency'">
                                    <div class="desc" style="border-bottom: 1px solid #EAECF0;display: flex; justify-content: space-between;">
                                      <span class="sensor-name" style="padding-left: 9px;">Scenes Overlay Opacity (<span
                                          for="alphaScn" class="currency" style="color: #1059A9;">{{ alphaScn.value }}%</span>)</span>
                                          <span class="sensor-name" *ngIf="imageLoaded && sensor.operatorKey === OPERATOR.LBAND">
                                            Please zoom into see the detected signals.
                                          </span>
                                    </div>
                                    <div class="desc"
                                      style="background-color: #fff; border-top: none; border-bottom: 1px solid #EAECF0;">
                                      <mat-slider aria-label="unit(s)" min="0" max="100" step="1" value="100"
                                        id="alphaScn" #alphaScn (change)="chngAlpha(sensorIndex, alphaScn.value,sensor)"
                                        oninput="rangeValue.innerText = this.value">

                                      </mat-slider>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data">
                                    <div class="no-data" *ngIf="sensor.scenes?.length === 0">No Collection</div>
                                    <table class="table" *ngIf="sensor.scenes?.length > 0">
                                      <tbody>
                                        <tr *ngFor="
                                            let scene of sensor.scenes;
                                            index as scenesIndex
                                          ">
                                          <td class="noBorder">
                                          <div style="display: flex;" *ngIf="!showDailyRecurrencyCheckboxes">
                                            <mat-checkbox class="example-margin"
                                              (change)="
                                              toggleSensor(
                                                collectionIndex,
                                                sensorIndex,
                                                scenesIndex,
                                                $event,
                                              collections[collectionIndex]?.sensors[sensorIndex]?.scenes[scenesIndex]
                                              )
                                            " [disabled]="((scene.operatorKey === OPERATOR.SPIRE || scene.operatorKey === OPERATOR.CLYDE) && !scene.aisAvailable) || !scene.id || scene?.status === 'failed' || sensor?.status === 'archived' || timeseries 
                                            || (sensor.operatorKey === OPERATOR.SATELLOGIC && scene?.sceneName.includes('L1D'))" [checked]="
                                            
                                          collections[collectionIndex]?.sensors[sensorIndex]?.scenes[scenesIndex]?.checked"
                                              [ngModel]="
                                            collections[collectionIndex]?.sensors[sensorIndex]?.scenes[scenesIndex]?.checked">
                                              <div class="flex">
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey != OPERATOR.USL && scene?.operatorKey != OPERATOR.AXELSPACE && scene?.operatorKey != OPERATOR.LBAND && !scene?.isName
                                                  && !(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')">Scene
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate  && scene?.operatorKey === OPERATOR.AXELSPACE">Tile
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && (scene?.operatorKey === OPERATOR.USL || scene?.operatorKey === OPERATOR.LBAND)">Collection
                                                  {{ scene.sceneIdx }}</span>
                                                  <span class="scene-name"
                                                  *ngIf="scene?.isName">{{scene?.isName}}</span>
                                                <span *ngIf="scene?.RRdeliveryDate"
                                                  class="scene-name">{{scene?.RRdeliveryDate }}</span>
                                                  <span *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')"
                                                  class="scene-name">{{scene?.sceneName }}</span>
                                              </div>
                                            </mat-checkbox>
                                            <img [src]="tooltipImagePath" alt="" 
                                             (click)="getMetadata(scene?.metadata_tooltip ? scene?.metadata_tooltip : scene.metadata)"
                                            *ngIf="!scene?.RRdeliveryDate && (scene?.metadata_tooltip || scene.metadata)" [matMenuTriggerFor]="tooltipMenu"
                                            style="padding: 0 0 0 10px; display: block; width: 25px; cursor: pointer;" 
                                            />

                                            <img [src]="tooltipImagePath" alt=""
                                            *ngIf="!scene?.RRdeliveryDate && (!scene?.metadata_tooltip && !scene.metadata)"
                                            style="padding: 0 0 0 10px; display: block; width: 25px;" />
                                          </div>

                                          <div style="display: flex;" *ngIf="showDailyRecurrencyCheckboxes">
                                            <mat-checkbox class="example-margin"
                                              [checked]="
                                          collections[collectionIndex]?.sensors[sensorIndex]?.scenes[scenesIndex]?.checked"
                                              (change)="
                                            toggleSensor(
                                              collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              $event,
                                              collections[collectionIndex]?.sensors[sensorIndex]?.scenes[scenesIndex]
                                            )
                                          " [disabled]="!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' || timeseries 
                                          || (sensor.operatorKey === OPERATOR.SATELLOGIC && scene?.sceneName.includes('L1D'))"
                                              [ngModel]="
                                            collections[collectionIndex]?.sensors[sensorIndex]?.scenes[scenesIndex]?.checked">
                                              <div class="flex">
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey != OPERATOR.USL && scene?.operatorKey != OPERATOR.AXELSPACE && scene?.operatorKey != OPERATOR.LBAND
                                                  && !(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')">Scene
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate  && scene?.operatorKey === OPERATOR.AXELSPACE">Tile
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey === OPERATOR.USL || scene?.operatorKey === OPERATOR.LBAND">Collection
                                                  {{ scene.sceneIdx }}</span>
                                                <span *ngIf="scene?.RRdeliveryDate"
                                                  class="scene-name">{{scene?.RRdeliveryDate }}</span>
                                                  <span *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')"
                                                  class="scene-name">{{scene?.sceneName }}</span>
                                              </div>
                                            </mat-checkbox>
                                            <img [src]="tooltipImagePath" alt="" 
                                             (click)="getMetadata(scene?.metadata_tooltip ? scene?.metadata_tooltip : scene.metadata)"
                                            *ngIf="!scene?.RRdeliveryDate && (scene?.metadata_tooltip || scene.metadata)" [matMenuTriggerFor]="tooltipMenu"
                                            style="padding: 0 0 0 10px; display: block; width: 25px; cursor: pointer;" 
                                            />
                                            <img [src]="tooltipImagePath" alt=""
                                            *ngIf="!scene?.RRdeliveryDate && (!scene?.metadata_tooltip && !scene.metadata)"
                                            style="padding: 0 0 0 10px; display: block; width: 25px;" />
                                          </div>
                                          </td>
                                          <td>
                                            <span class="flex">
                                              {{ scene?.predictedDate | date: "dd/MM/yyyy - hh.mm a" : "GMT" }}</span>
                                          </td>

                                          <td class="noBorder">
                                            <svg class="change-my-color" *ngIf="((scene.operatorKey === OPERATOR.SPIRE || scene.operatorKey === OPERATOR.CLYDE) && !scene.aisAvailable && scene.id) || (scene.operatorKey !== OPERATOR.SPIRE && scene.operatorKey !== OPERATOR.CLYDE && !scene.id)&& scene?.status != 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                            <svg class="change-my-colors" *ngIf="(scene.operatorKey !== OPERATOR.SPIRE && scene.operatorKey !== OPERATOR.CLYDE && scene.id) || ((scene.operatorKey === OPERATOR.SPIRE || scene.operatorKey === OPERATOR.CLYDE) && scene.aisAvailable && scene.id) || scene?.status === 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                          </td>

                                          <td>
                                            <mat-icon
                                              [ngClass]="scene?.status === 'failed' ? 'my-icon' : 'my-icon-disable'">error_outline</mat-icon>
                                          </td>

                                          <td class="noBorder">
                                            <img (click)="
                                                download(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  scenesIndex,
                                                  scene
                                                )
                                              " *ngIf="((scene.operatorKey === OPERATOR.SPIRE && scene.aisDownloadable && sensor.refreshHistory?.length > 0) || (scene.operatorKey === OPERATOR.CLYDE && scene.aisAvailable && sensor.refreshHistory?.length > 0) || (scene.operatorKey !== OPERATOR.SPIRE && scene.operatorKey !== OPERATOR.CLYDE && scene.id && scene?.status != 'failed' && ((sensor?.status != 'archived' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'both')))) && !demoView" 
                                              [style.width]="(scene?.downloaded || checkDownloaded(scene)) ? '23px' : '21px'"
                                              [src]="(scene?.downloaded || checkDownloaded(scene)) ? downloadedImage : downloadImage" style="cursor: pointer;"
                                              alt="" />
                                            <img *ngIf="((scene.operatorKey === OPERATOR.SPIRE && (!scene.aisDownloadable || !sensor.refreshHistory)) || (scene.operatorKey === OPERATOR.CLYDE && (!scene.aisAvailable || !sensor.refreshHistory))  || (scene.operatorKey !== OPERATOR.SPIRE && scene.operatorKey !== OPERATOR.CLYDE && !scene.id) || scene?.status === 'failed' || (sensor?.status === 'archived' && scene.operatorKey != OPERATOR.SPIRE && scene.operatorKey != OPERATOR.CLYDE) || (sensor?.status === 'restored' && sensor?.restoreOption === 'view' 
                                            && scene.operatorKey != OPERATOR.CLYDE && scene.operatorKey != OPERATOR.SPIRE)) || demoView" width="21px"
                                              src="../../../assets/images/cloud.png" alt="" />
                                          </td>
                                          
                                            <td style="padding-right:5px;"  *ngIf="(!currentUser?.allocated || (currentUser?.allocated && (collection?.target.type === 'Point' && collection?.frequency != 1  && scene.operatorKey !== OPERATOR.AXELSPACE)))
                                            && scene.operatorKey !== OPERATOR.LBAND">
                                            <img
                                            *ngIf="scene.operatorKey != OPERATOR.SPIRE && scene.operatorKey !== OPERATOR.CLYDE && shouldNotDisplayScene(scene, collection, downloadedScene, sensor)
                                            || checkForRelease(sensor)"
                                            style="cursor: pointer;" width="21px"
                                              src="../../../assets/images/refund-inactive.png" alt=""
                                              [style.cursor]="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? 'pointer' : 'default'"
                                              (click)="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? refundStatus(scene) : null" />
                                            <img (click)="OpenReturnRequest(collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              scene)"
                                              *ngIf="scene.operatorKey !== OPERATOR.SPIRE && scene.operatorKey !== OPERATOR.CLYDE && shouldDisplayScene(scene, collection, downloadedScene, sensor)
                                              && !checkForRelease(sensor)"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage"
                                               alt="" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>



                                  </div>
                                </div>
                              </div>

                              <div *ngIf="sensor.InfoServices?.length !== 0" class="details-content">
                                <div class="details-content-inner">
                                  <div style="border-bottom: 1px solid #EAECF0;">
                                    <div class="desc filter-panel">
                                      <span class="sensor-name" style="padding-left: 9px;">Information Feeds 
                                        <span *ngIf="feedLoaded && getFeedInfo(sensor.InfoServices) && vessel" style="font-size: 12px;"> ( Please mouse over the detected vessels to see details )</span>
                                      </span>
                                      <mat-icon *ngIf="feedLoaded && getFeedInfo(sensor.InfoServices) && vessel" class="icon-filter" matTooltip="Filter by vessel length"
                                      (click)="openFilter()">tune</mat-icon>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data">
                                    <table class="table">
                                      <tbody>
                                        <tr *ngFor="
                                        let InfoService of sensor.InfoServices;
                                        index as infoServiceIndex
                                      ">
                                          <td class="noBorder">
                                            <mat-checkbox class="example-margin"
                                            [checked]="this.collections[collectionIndex]?.sensors[sensorIndex]?.InfoServices[infoServiceIndex]?.checked"
                                              (change)="
                                              selectIS(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  infoServiceIndex,
                                                  $event,
                                                  InfoService
                                                )"  
                                              [disabled]="shouldDisableIS(collectionIndex,sensorIndex,scene,InfoService)">
                                              <div class="flex">
                                                <span class="scene-name">
                                                  {{ InfoService.feedName ? InfoService.feedName : InfoService.name }}</span
                                                >
                                              </div>
                                            </mat-checkbox>
                                          </td>
                                          <td class="noBorder">
                                            <svg
                                              class="change-my-color"
                                              *ngIf="
                                                !scene.id &&
                                                scene?.status != 'failed'
                                              "
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                            <svg
                                              class="change-my-colors"
                                              *ngIf="
                                                scene.id ||
                                                scene?.status === 'failed'
                                              "
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                          <td>
                                            <mat-icon
                                            [ngClass]="
                                            scene?.status === 'failed'
                                              ? 'my-icon'
                                              : 'my-icon-disable'
                                          "
                                          >error_outline
                                          </mat-icon>
                                      </td>
                                          <td style="padding-right: 40px !important;"
                                            class="noBorder"
                                          >
                                          
                                            <img
                                            *ngIf="(shouldDownload(collectionIndex,sensorIndex,scene,InfoService) && !demoView)"
                                              width="21px"
                                              src="../../../assets/images/icon-img.png"
                                              style="cursor: pointer"
                                              alt=""
                                              (click)="
                                                download(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  undefined,
                                                  InfoService
                                                )
                                              "
                                            />
                                            <img
                                            *ngIf="(shouldNotDownload(collectionIndex,sensorIndex,scene,InfoService)) || demoView"
                                              width="21px"
                                              src="../../../assets/images/cloud.png"
                                              alt=""
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                            <!-- AIS refresh history start-->

                              <div *ngIf="sensor.refreshHistory?.length > 0" class="details-content">
                                <div class="details-content-inner">
                                  <div style="border-bottom: 1px solid #EAECF0;">
                                    <div class="desc filter-panel">
                                      <span class="sensor-name" style="padding-left: 9px;">Refresh History
                                        <span class="legend-pos">
                                        <span class="color-legend" [ngStyle]="{'background-color': refreshHistoryColor[sensor?.operatorKey]}">
                                        </span>
                                      </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data">
                                    <table class="table">
                                      <tbody>
                                        <tr *ngFor="
                                        let data of sensor.refreshHistory;
                                        index as dataIndex
                                      ">
                                          <td class="noBorder">
                                            <mat-checkbox class="example-margin"
                                              (change)="
                                              displayRefreshData(
                                                $event,
                                                sensor?.scenes[0],
                                                sensor,
                                                'display',
                                                data
                                              )">
                                              <div class="flex">
                                                <span class="scene-name">
                                                  {{ data.name | date : "dd/MM/yyyy - hh.mm a" : "GMT" }}</span
                                                >
                                              </div>
                                            </mat-checkbox>
                                          </td>
                                          <td class="noBorder">
                                          </td>
                                          <td>
                                          </td>
                                          <td style="padding-right: 40px !important;"
                                            class="noBorder"
                                          >
                                          
                                            <img
                                              width="21px"
                                              src="../../../assets/images/icon-img.png"
                                              style="cursor: pointer"
                                              alt=""
                                              (click)=
                                              "download(
                                                collectionIndex,
                                                sensorIndex,
                                                undefined,
                                                data
                                              )
                                            "
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            <!-- AIS refresh history end-->

                              <!-- Value Added option start-->
                              <div 
                                *ngIf="
                                (((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                (((sensor ||  {}).superResolutions || []).length != 0) ||
                                (((sensor ||  {}).indicesDetails || []).length != 0)" 
                                class="details-content">
                                <div class="details-content-inner">
                                  <div style="border-bottom: 1px solid #EAECF0;">
                                    <div class="desc filter-panel">
                                      <span class="sensor-name" style="padding-left: 9px;">Value added  data products
                                      </span>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data" *ngIf="((sensor ||  {}).valueAddedOption || []).length != 0">
                                    <table class="table">
                                      <tbody>
                                        <tr *ngFor="
                                        let valAdOption of sensor.valueAddedOption;
                                        index as valAdOptionIndex">
                                          <td class="noBorder">
                                            <mat-checkbox 
                                              class="example-margin"
                                              (change)="setValueAddedOption(valAdOption, sensor, $event, collectionIndex, sensorIndex)"
                                              [checked]="isValueAddedOptionChecked(sensor, valAdOption.product)"
                                              [disabled]="shouldDisableValueAddedOption(sensor, valAdOption) || 
                                              valAdOption?.product_status !== 'DELIVERED'"
                                            >
                                              <div class="flex">
                                                <span class="scene-name">
                                                  {{ valAdOption.product }}
                                                  <span *ngIf="valAdOption?.resolution">
                                                    ({{ valAdOption.resolution }})
                                                  </span>
                                                </span>
                                              </div>
                                            </mat-checkbox>
                                          </td>
                                          <td>
                                            <span class="flex">
                                              {{ valAdOption?.expectedTimeDelivery }}
                                            </span>
                                          </td>
                                          <td class="noBorder">
                                            <svg
                                              class="change-my-color"
                                              *ngIf="valAdOption?.product_status !== 'DELIVERED'"
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                              [ngStyle]="shouldDisableValueAddedOption(sensor, valAdOption) ? {'pointer-events': 'none', 'fill': '#98a2b3'} : {}"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                            <svg
                                              class="change-my-colors"
                                              *ngIf="valAdOption?.product_status === 'DELIVERED'"
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                          <td>
                                              <mat-icon
                                                [ngStyle]="shouldDisableValueAddedOption(sensor, valAdOption) ? {'pointer-events': 'none', 'color': '#98a2b3'} : {}"
                                                [ngClass]="{
                                                  'my-icon': valAdOption?.product_status === 'ERROR',
                                                  'my-icon-disable': valAdOption?.product_status !== 'ERROR',
                                                  'disabled-dem-icons': shouldDisableValueAddedOption(sensor, valAdOption)
                                                }"
                                              >error_outline
                                              </mat-icon>
                                          </td>
                                          <td class="noBorder">
                                            <ng-container>
                                              <img width="21px" style="cursor: pointer;" *ngIf="!shouldDisableValueAddedOption(sensor, valAdOption) && valAdOption?.isDownloaded" [src]="this.downloadedImage" alt="" (click)="downloadedWarning()" />
                                            </ng-container>
                                            <ng-container 
                                              *ngIf="(shouldDisableValueAddedOption(sensor, valAdOption) && valAdOption?.product_status === 'DELIVERED') ||
                                              (shouldDisableValueAddedOption(sensor, valAdOption) && valAdOption?.product_status !== 'DELIVERED') 
                                              || (!shouldDisableValueAddedOption(sensor, valAdOption) && valAdOption?.product_status !== 'DELIVERED')">
                                              <img width="21px" src="../../../assets/images/cloud.png" alt="" style="cursor: pointer;"/>
                                            </ng-container>
                                            <ng-container 
                                              *ngIf="(!shouldDisableValueAddedOption(sensor, valAdOption) && (valAdOption?.product_status === 'DELIVERED' && !valAdOption.isDownloaded))">
                                              <img width="21px" src="../../../assets/images/icon-img.png" alt="" (click)="downloadValueAddedData(valAdOption, collectionIndex, sensor, sensorIndex)" style="cursor: pointer;"/>
                                            </ng-container>
                                          </td>
                                          <td class="noBorder">
                                            <img
                                              *ngIf="
                                              shouldDisableValueAddedOption(sensor, valAdOption) || (valAdOption.releaseRequest
                                              && valAdOption?.product_status != 'DELIVERED' && !shouldDisableValueAddedOption(sensor, valAdOption)) || 
                                              (valAdOption?.returnRefundAccept || valAdOption?.returnRefundReject)"
                                              style="cursor: pointer;" width="21px"
                                              src="../../../assets/images/refund-inactive.png" alt=""
                                              [style.cursor]="((valAdOption?.returnRefundAccept || valAdOption?.returnRefundReject)) ? 'pointer' : 'default'"
                                              (click)="((valAdOption?.returnRefundAccept || valAdOption?.returnRefundReject)) ? refundValueAddedStatus(valAdOption) : null" />
                                            <img
                                              (click)="refundValueAddedData(collectionIndex, sensorIndex, valAdOption)"
                                              *ngIf="((!shouldDisableValueAddedOption(sensor, valAdOption) && !valAdOption.releaseRequest)
                                              || (!shouldDisableValueAddedOption(sensor, valAdOption) && valAdOption?.product_status === 'DELIVERED'))
                                              && (!valAdOption?.returnRefundAccept && !valAdOption?.returnRefundReject)"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage"
                                              alt=""
                                            />
                                          </td>

                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  <!-- Super solutions -->
                                  <div class="table-responsives scenes-data" *ngIf="((sensor ||  {}).superResolutions || []).length != 0">
                                    <table class="table">
                                      <tbody>
                                        <tr *ngFor="
                                        let superResolution of sensor.superResolutions
                                        index as superResolutionIndex
                                      ">
                                          <td class="noBorder">
                                            <mat-checkbox class="example-margin"
                                            [checked]="this.collections[collectionIndex]?.sensors[sensorIndex]?.superResolutions[superResolutionIndex]?.checked"
                                              (change)="
                                              selectSuperResolution(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  superResolutionIndex,
                                                  $event,
                                                  superResolution
                                                )"  
                                              [disabled]="shouldDisableSuperResolution(collectionIndex,sensorIndex,scene,superResolution) ||
                                              superResolution?.product_status != 'DELIVERED'">
                                              <div class="flex">
                                                <span class="scene-name">
                                                  {{ superResolution.product }} ({{superResolution.resolution}})</span
                                                >
                                              </div>
                                            </mat-checkbox>
                                          </td>
                                          <td>
                                            <span class="flex">
                                              {{ superResolution?.expectedTimeDelivery }}
                                            </span>
                                          </td>
                                          <td class="noBorder">
                                            <svg
                                              class="change-my-color"
                                              *ngIf="superResolution?.product_status !== 'DELIVERED'"
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                              [ngStyle]="shouldDisableSuperResolution(collectionIndex,sensorIndex,scene,superResolution) ? {'pointer-events': 'none', 'fill': '#98a2b3'} : {}"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                            <svg
                                              class="change-my-colors"
                                              *ngIf="superResolution?.product_status === 'DELIVERED'"
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                          <td>
                                            <mat-icon
                                            [ngClass]="
                                            scene?.status === 'failed'
                                              ? 'my-icon'
                                              : 'my-icon-disable'
                                              "
                                              >error_outline
                                              </mat-icon>
                                          </td>
                                          <td class="noBorder">
                                            <ng-container>
                                              <img 
                                                width="21px" 
                                                style="cursor: pointer;" 
                                                *ngIf="isDownloadedSR(superResolution, sensor, scene)" 
                                                [src]="this.downloadedImage" 
                                                alt="" 
                                                (click)="downloadedWarning()" 
                                              />
                                            </ng-container>
                                            <ng-container *ngIf="superResolution?.product_status !== 'DELIVERED' || (shouldDisableSuperResolution(collectionIndex,sensorIndex,scene,superResolution) && !isDownloadedSR(superResolution, sensor, scene))">
                                              <img width="21px" src="../../../assets/images/cloud.png" alt="" style="cursor: pointer;"/>
                                            </ng-container>
                                            <ng-container *ngIf="superResolution?.product_status === 'DELIVERED' && (!shouldDisableSuperResolution(collectionIndex,sensorIndex,scene,superResolution) && !isDownloadedSR(superResolution, sensor, scene))">
                                              <img width="21px" src="../../../assets/images/icon-img.png" alt="" (click)="downloadSR(superResolution, collectionIndex, sensor, sensorIndex)" style="cursor: pointer;"/>
                                            </ng-container>
                                          </td>
                                          <td class="noBorder">
                                            <img
                                              *ngIf="!isEnableSR(superResolution, sensor, scene) || 
                                              (superResolution.releaseRequest && superResolution?.product_status != 'DELIVERED' && isEnableSR(superResolution, sensor, scene)) 
                                              || (superResolution?.returnRefundAccept || superResolution?.returnRefundReject)"
                                              style="cursor: pointer;" width="21px"
                                              src="../../../assets/images/refund-inactive.png" alt=""
                                              [style.cursor]="refundStatusCheckSR(superResolution, sensor) ? 'pointer' : 'default'"
                                              (click)="refundStatusCheckSR(superResolution, sensor) ? refundSRStatus(superResolution, sensor) : null" />
                                            <img
                                              (click)="OpenReturnRequestSR(collectionIndex, sensorIndex, superResolution)"
                                              *ngIf="((isEnableSR(superResolution, sensor, scene) && !superResolution.releaseRequest)
                                              || (isEnableSR(superResolution, sensor, scene) && superResolution?.product_status === 'DELIVERED')) &&
                                              (!superResolution?.returnRefundAccept && !superResolution?.returnRefundReject) || 
                                              (superResolution?.product_status != 'DELIVERED' && !superResolution.releaseRequest && isEnableSR(superResolution, sensor, scene))"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage"
                                              alt=""
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <!-- Super solutions -->

                                  <!-- Indices -->
                                  <div class="table-responsives scenes-data" *ngIf="((sensor ||  {}).indicesDetails || []).length != 0">
                                    <table class="table">
                                      <tbody>
                                        <tr *ngFor="
                                        let indices of sensor.indicesDetails
                                        index as indicesIndex
                                      ">
                                          <td class="noBorder">
                                            <mat-checkbox class="example-margin"
                                            [checked]="this.collections[collectionIndex]?.sensors[sensorIndex]?.indicesDetails[indicesIndex]?.checked"
                                              (change)="
                                              selectIndices(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  indicesIndex,
                                                  $event,
                                                  indices
                                                )"  
                                              [disabled]="shouldDisableIndices(collectionIndex,sensorIndex,scene,indices)">
                                              <div class="flex">
                                                <span class="scene-name">
                                                  {{ indices.product }}</span
                                                >
                                              </div>
                                            </mat-checkbox>
                                          </td>
                                          <td>
                                            &nbsp;
                                          </td>
                                          <td class="noBorder">
                                            <svg
                                              class="change-my-color"
                                              *ngIf="!indices"
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                              [ngStyle]="shouldDisableIndices(collectionIndex,sensorIndex,scene,indices) ? {'pointer-events': 'none', 'fill': '#98a2b3'} : {}"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                            <svg
                                              class="change-my-colors"
                                              *ngIf="indices"
                                              width="19"
                                              height="24"
                                              viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                              d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z"
                                              />
                                            </svg>
                                          <td>
                                            <mat-icon
                                            [ngClass]="
                                            scene?.status === 'failed'
                                              ? 'my-icon'
                                              : 'my-icon-disable'
                                              "
                                              >error_outline
                                              </mat-icon>
                                          </td>
                                          <td class="noBorder">
                                            <ng-container>
                                              <img 
                                                width="21px" 
                                                style="cursor: pointer;" 
                                                *ngIf="(isDownloadedIndices(indices, sensor, scene) && !demoView)" 
                                                [src]="this.downloadedImage" 
                                                alt="" 
                                                (click)="downloadedWarning()" 
                                              />
                                            </ng-container>
                                            <ng-container *ngIf="(shouldDisableIndices(collectionIndex,sensorIndex,scene,indices) && !isDownloadedIndices(indices, sensor, scene)) || demoView">
                                              <img width="21px" src="../../../assets/images/cloud.png" alt="" style="cursor: pointer;"/>
                                            </ng-container>
                                            <ng-container *ngIf="(!shouldDisableIndices(collectionIndex,sensorIndex,scene,indices) && !isDownloadedIndices(indices, sensor, scene)) && !demoView">
                                              <img width="21px" src="../../../assets/images/icon-img.png" alt="" (click)="downloadIndices(indices, collectionIndex, sensor, sensorIndex)" style="cursor: pointer;"/>
                                            </ng-container>
                                          </td>
                                          <!-- <td class="noBorder"> -->
                                            <!-- <img
                                            *ngIf="!isDownloadedIndices(indices, sensor, scene)"
                                            style="cursor: pointer;" width="21px"
                                            src="../../../assets/images/refund-inactive.png" alt=""
                                            [style.cursor]="refundStatusCheckIndices(indices, sensor) ? 'pointer' : 'default'"
                                            (click)="refundStatusCheckIndices(indices, sensor) ? refundIndicesStatus(indices, sensor) : null" />
                                          <img
                                            (click)="OpenReturnRequestIndices(collectionIndex, sensorIndex, indices)"
                                            *ngIf="isDownloadedIndices(indices, sensor, scene)"
                                            style="cursor: pointer;" width="21px"
                                            [src]="refundImage"
                                            alt=""
                                          /> -->
                                          <!-- </td> -->
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <!-- Indices -->

                                </div>
                              </div>
                              <!-- value added option end -->
                            </mat-expansion-panel>
                          </mat-accordion>
                          <hr class="solid">
                        </div>
                      </div>


                      <div class="card" *ngIf="showDailyRecurrencyCheckboxes &&!showSubDailyList">

                        <div class="details-block sub-block" *ngFor="
                                let sensor of selectedCheckboxes;
                                index as sensorIndex
                              ">
                          <mat-accordion>
                            <mat-expansion-panel [expanded]="isFirst">
                              <mat-expansion-panel-header #panelH (click)="panelH._toggle()" style="padding: 0 9px;">
                                <div class="details-summary">


                                  <mat-checkbox class="example-margin" color="primary"
                                    *ngIf="showDailyRecurrencyCheckboxes" [checked]="sensor.checked" (change)="
                                    toggleRecurrentSensor(
                                      collectionIndex,
                                     sensorIndex,
                                      undefined,
                                      $event,
                                      undefined,
                                      sensor?.scenes[0]
                                    )
                                  " [(ngModel)]="sensor.checked">
                                  <div style="display: flex; padding-top: 4px;">
                                    <span class="sensor-name">{{ (currentUser?.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor?.name }} </span>
                                    <img *ngIf="sensor?.resolution_tooltip" style="margin-left: 5px;margin-top: -3px;"
                                      width="14px" [src]="tooltipImagePath"
                                      [matTooltip]="sensor?.resolution_tooltip" />
                                      <img *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')" style="margin-left: 5px;margin-top: -3px;"
                                    width="14px" [src]="tooltipImagePath"
                                    matTooltip="If the imaging were over land, you would receive an orthorectified (L1D) product ten hours from the first data (Geocorrected (L1B))." />
                                      &nbsp;&nbsp;&nbsp;
                                      <span style="padding-top: 1px;" *ngIf="this.collection?.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.USL">
                                        <span *ngIf="sensorIndex === 0" class="legend-container">
                                        </span>
                                        <span *ngIf="sensorIndex != 0" class="legend">
                                        </span>
                                      </span>
                                      <span class="legend-pos" *ngIf="(this.collection?.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.LBAND)">
                                        <span class="color-legend" style="top: 38%;left: 1px;" [ngStyle]="{'background-color': recurrentColorCode[sensorIndex]}">
                                        </span>
                                      </span>
                                    </div>
                                      </mat-checkbox>
                                  <span class="align-head" *ngIf="sensor?.latency === 'Emergency'">No preview
                                    available</span>

                                    <div class="img-icon" fxLayoutGap="10px">
                                      <mat-icon *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'"
                                      (click)="checkForDelivered(sensor) ? '' : releaseVADP(collection.taskId,sensor)"
                                      [disabled]="checkForDelivered(sensor)"
                                      matTooltip="Release for VADP" [ngClass]="checkForDelivered(sensor) ? 'my-icon-disable' : 'drive'"
                                      >3d_rotation</mat-icon>

                                      <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'
                                      && (!checkForRelease(sensor)) || ((checkForRelease(sensor) && checkForDelivered(sensor)))"
                                      style="cursor: pointer;" width="21px" matTooltip="Request for VADP Refund"
                                      [src]="refundImage"
                                      alt=""
                                      (click)="refundVADP(collectionIndex, sensorIndex,sensor)"
                                    />

                                    <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                    (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed' && 
                                    (checkForRelease(sensor) && !checkForDelivered(sensor))" style="cursor: default;" width="21px"
                                      src="../../../assets/images/refund-inactive.png" alt=""
                                    />
                                      <mat-icon *ngIf="(sensor.operatorKey === OPERATOR.CAPELLA || (sensor.operatorKey === OPERATOR.UMBRA && (sensor.isAdditionalProducts || sensor.isAdditionalCSIProducts)) || 
                                      (sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush' && sensor?.assetsDelivered)) 
                                      && ((sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both')))"
                                      class="drive" (click)="getAdditionalData(collection.taskId,sensor)"
                                      [matTooltip]="this.downloadTooltip"
                                      (mouseover)="getTooltipMessage(sensor)"
                                      (mouseout)="onMouseOut()"
                                      [matTooltipDisabled]="tooltipDisabled"
                                      >add_circle_outline</mat-icon>
                                      <img (click)="
                                      download(
                                          collectionIndex,
                                          sensorIndex,
                                          undefined,sensor
                                        )
                                        " *ngIf="(sensor.scenesList?.length > 0 && (sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both'))) && !demoView" 
                                        [style.width]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? '23px' : '21px'" style="cursor: pointer;"
                                        [src]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? downloadedImage : downloadImage" />
                                      <img *ngIf="(sensor.scenesList?.length < 1 || (sensor?.status != 'completed' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'view')) || demoView" width="21px"
                                        src="../../../assets/images/cloud.png" alt="" style="cursor: default;" />                                     
  
                                        <img *ngIf="sensor?.status === 'archived' || sensor?.status === 'restored'" 
                                        [src]="(sensor?.status === 'archived') ? '../../../assets/images/archive.svg' : '../../../assets/images/unarchive.svg'"
                                        alt="" [ngClass]="sensor?.status === 'archived' ? 'my-icon' : 'my-icon-disable'"
                                        (click)="sensor?.status === 'archived' ? openRestoreRequest(collectionIndex,sensorIndex,sensor) : null"/> 
                                    </div>

                                </div>
                                <mat-icon class="hiddenicon" (click)="panelH._toggle()" aria-hidden="false"
                                  aria-label="Example home icon" fontIcon="home"></mat-icon>
                              </mat-expansion-panel-header>
                              <div class="details-content">
                                <div class="details-content-inner">
                                  <div *ngIf="sensor?.latency === 'Emergency'"
                                    style="border-bottom: 1px solid #EAECF0;"></div>
                                  <div *ngIf="sensor?.latency != 'Emergency'">
                                    <div class="desc" style="border-bottom: 1px solid #EAECF0;">
                                      <span class="sensor-name" style="padding-left: 9px;">Scenes Overlay Opacity (<span
                                          for="alphaScn" class="currency" style="color: #1059A9;">{{ alphaScn.value }}%</span>)</span>
                                    </div>
                                    <div class="desc"
                                      style="background-color: #fff; border-top: none; border-bottom: 1px solid #EAECF0;">
                                      <mat-slider aria-label="unit(s)" min="0" max="100" step="1" value="100"
                                        id="alphaScn" #alphaScn (change)="chngAlpha(sensorIndex, alphaScn.value,sensor)"
                                        oninput="rangeValue.innerText = this.value">

                                      </mat-slider>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data">
                                    <div class="no-data" *ngIf="sensor.scenes?.length === 0">No Collection</div>
                                    <table class="table" *ngIf="sensor.scenes?.length > 0">
                                      <tbody>
                                        <tr *ngFor="
                                            let scene of sensor.scenes;
                                            index as scenesIndex
                                          ">
                                          <td class="noBorder">

                                            <div style="display: flex;" *ngIf="showDailyRecurrencyCheckboxes">
                                            <mat-checkbox class="example-margin"
                                              [checked]="
                                         scene.checked" (change)="
                                            toggleRecurrentSensor(
                                              collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              $event,
                                              undefined,
                                              scene
                                            )
                                          " [disabled]="!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' 
                                          || (sensor.operatorKey === OPERATOR.SATELLOGIC && scene?.sceneName.includes('L1D'))"
                                              [(ngModel)]="scene.checked">
                                              <div class="flex">
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey != OPERATOR.USL && scene?.operatorKey != OPERATOR.AXELSPACE && scene?.operatorKey != OPERATOR.LBAND
                                                  && !(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')">Scene
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate  && scene?.operatorKey === OPERATOR.AXELSPACE">Tile
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey === OPERATOR.USL || scene?.operatorKey === OPERATOR.LBAND">Collection
                                                  {{ scene.sceneIdx }}</span>
                                                <span *ngIf="scene?.RRdeliveryDate"
                                                  class="scene-name">{{scene?.RRdeliveryDate }}</span>
                                                <span *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')"
                                                  class="scene-name">{{scene?.sceneName }}</span>
                                              </div>
                                            </mat-checkbox>
                                            <img [src]="tooltipImagePath" alt="" 
                                             (click)="getMetadata(scene?.metadata_tooltip ? scene?.metadata_tooltip : scene.metadata)"
                                            *ngIf="!scene?.RRdeliveryDate && (scene?.metadata_tooltip || scene.metadata)" [matMenuTriggerFor]="tooltipMenu"
                                            style="padding: 0 0 0 10px; display: block; width: 25px; cursor: pointer;" 
                                            />

                                            <img [src]="tooltipImagePath" alt=""
                                            *ngIf="!scene?.RRdeliveryDate && (!scene?.metadata_tooltip && !scene.metadata)"
                                            style="padding: 0 0 0 10px; display: block; width: 25px;" />
                                          </div>
                                          </td>
                                          <td>
                                            <span class="flex">
                                              {{ scene?.predicted_date | date: "dd/MM/yyyy - hh.mm a" : "GMT" }}</span>
                                          </td>

                                          <td class="noBorder">
                                            <svg class="change-my-color" *ngIf="!scene.id && scene?.status != 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                            <svg class="change-my-colors" *ngIf="scene.id || scene?.status === 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                          </td>

                                          <td>
                                            <mat-icon
                                              [ngClass]="scene?.status === 'failed' ? 'my-icon' : 'my-icon-disable'">error_outline</mat-icon>
                                          </td>

                                          <td class="noBorder">
                                            <img (click)="
                                                download(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  scenesIndex,
                                                  scene
                                                )
                                              " *ngIf="(scene.id && scene?.status != 'failed' && ((sensor?.status != 'archived' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'both'))) && !demoView" 
                                              [style.width]="(scene?.downloaded || checkDownloaded(scene)) ? '23px' : '21px'"
                                              [src]="(scene?.downloaded || checkDownloaded(scene)) ? downloadedImage : downloadImage" style="cursor: pointer;"
                                              alt="" />

                                            <img *ngIf="(!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' || (sensor?.status === 'restored' && sensor?.restoreOption === 'view')) || demoView" width="21px"
                                              src="../../../assets/images/cloud.png" alt="" />
                                          </td>
                                          <td style="padding-right:5px;"  *ngIf="(!currentUser?.allocated || (currentUser?.allocated && (collection?.target.type === 'Point' && collection?.frequency != 1  && scene.operatorKey !== OPERATOR.AXELSPACE)))
                                          && scene.operatorKey !== OPERATOR.LBAND">
                                            <img
                                            *ngIf="shouldNotDisplayScene(scene, collection, downloadedScene, sensor)
                                            || checkForRelease(sensor)"
                                            style="cursor: pointer;" width="21px"
                                              src="../../../assets/images/refund-inactive.png" alt=""
                                              [style.cursor]="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? 'pointer' : 'default'"
                                              (click)="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? refundStatus(scene) : null" />
                                            <img (click)="OpenReturnRequest(collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              scene)"
                                              *ngIf="shouldDisplayScene(scene, collection, downloadedScene, sensor) &&
                                              !checkForRelease(sensor)"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage"
                                              alt="" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>



                                  </div>
                                </div>
                              </div>

                            </mat-expansion-panel>
                          </mat-accordion>
                          <hr class="solid">
                        </div>
                      </div>

                      <div class="card" *ngIf="showDailyRecurrencyCheckboxes && showSubDailyList">

                        <div class="details-block sub-block" *ngFor="
                                let sensor of subDailyFinalArray;
                                index as sensorIndex
                              ">
                          <mat-accordion>
                            <mat-expansion-panel [expanded]="isFirst">
                              <mat-expansion-panel-header #panelH (click)="panelH._toggle()" style="padding: 0 9px;">
                                <div class="details-summary">


                                  <mat-checkbox class="example-margin" color="primary"
                                    *ngIf="showDailyRecurrencyCheckboxes" [checked]="sensor.checked" (change)="
                                    toggleSubDailyRecurrentSensor(
                                     sensorIndex,
                                      undefined,
                                      $event
                                    )
                                  " [(ngModel)]="sensor.checked">
                                  <div style="display: flex; padding-top: 4px;">
                                  <span class="sensor-name">{{ (currentUser?.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor?.name }} </span>
                                    <img *ngIf="sensor?.resolution_tooltip" style="margin-left: 5px;margin-top: -3px;"
                                      width="14px" [src]="tooltipImagePath"
                                      [matTooltip]="sensor?.resolution_tooltip" />
                                      <img *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')" style="margin-left: 5px;margin-top: -3px;"
                                    width="14px" [src]="tooltipImagePath"
                                    matTooltip="If the imaging were over land, you would receive an orthorectified (L1D) product ten hours from the first data (Geocorrected (L1B))." />
                                      &nbsp;&nbsp;&nbsp;
                                      <span style="padding-top: 1px;" *ngIf="this.collection?.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.USL">
                                        <span *ngIf="sensorIndex === 0" class="legend-container">
                                        </span>
                                        <span *ngIf="sensorIndex != 0" class="legend">
                                        </span>
                                      </span>
                                    </div>
                                  </mat-checkbox>
                                  <span class="align-head" *ngIf="sensor?.latency === 'Emergency'">No preview
                                    available</span>

                                    <div class="img-icon" fxLayoutGap="10px">
                                      <mat-icon *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'"
                                      (click)="checkForDelivered(sensor) ? '' : releaseVADP(collection.taskId,sensor)"
                                      [disabled]="checkForDelivered(sensor)"
                                      matTooltip="Release for VADP" [ngClass]="checkForDelivered(sensor) ? 'my-icon-disable' : 'drive'"
                                      >3d_rotation</mat-icon>

                                      <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'
                                      && (!checkForRelease(sensor)) || ((checkForRelease(sensor) && checkForDelivered(sensor)))"
                                      style="cursor: pointer;" width="21px" matTooltip="Request for VADP Refund"
                                      [src]="refundImage"
                                      alt=""
                                      (click)="refundVADP(collectionIndex, sensorIndex,sensor)"
                                    />

                                    <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                    (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed' && 
                                    (checkForRelease(sensor) && !checkForDelivered(sensor))" style="cursor: default;" width="21px"
                                      src="../../../assets/images/refund-inactive.png" alt=""
                                    />
                                      <mat-icon *ngIf="(sensor.operatorKey === OPERATOR.CAPELLA || (sensor.operatorKey === OPERATOR.UMBRA && (sensor.isAdditionalProducts || sensor.isAdditionalCSIProducts)) || 
                                      (sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush' && sensor?.assetsDelivered)) 
                                      && ((sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both')))"
                                      class="drive" (click)="getAdditionalData(collection.taskId,sensor)"
                                      [matTooltip]="this.downloadTooltip"
                                      (mouseover)="getTooltipMessage(sensor)"
                                      (mouseout)="onMouseOut()"
                                      [matTooltipDisabled]="tooltipDisabled"
                                      >add_circle_outline</mat-icon>
                                      <img (click)="
                                      download(
                                          collectionIndex,
                                          sensorIndex,
                                          undefined,sensor
                                        )
                                        " *ngIf="(sensor.scenesList?.length > 0 && (sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both'))) && !demoView" 
                                        [style.width]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? '23px' : '21px'" style="cursor: pointer;"
                                        [src]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? downloadedImage : downloadImage" />
                                      <img *ngIf="(sensor.scenesList?.length < 1 || (sensor?.status != 'completed' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'view')) || demoView" width="21px"
                                        src="../../../assets/images/cloud.png" alt="" style="cursor: default;" />                                     
  
                                        <img *ngIf="sensor?.status === 'archived' || sensor?.status === 'restored'" 
                                        [src]="(sensor?.status === 'archived') ? '../../../assets/images/archive.svg' : '../../../assets/images/unarchive.svg'"
                                        alt="" [ngClass]="sensor?.status === 'archived' ? 'my-icon' : 'my-icon-disable'"
                                        (click)="sensor?.status === 'archived' ? openRestoreRequest(collectionIndex,sensorIndex,sensor) : null"/> 
                                    </div>

                                </div>
                                <mat-icon class="hiddenicon" (click)="panelH._toggle()" aria-hidden="false"
                                  aria-label="Example home icon" fontIcon="home"></mat-icon>
                              </mat-expansion-panel-header>
                              <div class="details-content">
                                <div class="details-content-inner">
                                  <div *ngIf="sensor?.latency === 'Emergency'"
                                    style="border-bottom: 1px solid #EAECF0;"></div>
                                  <div *ngIf="sensor?.latency != 'Emergency'">
                                    <div class="desc" style="border-bottom: 1px solid #EAECF0;">
                                      <span class="sensor-name" style="padding-left: 9px;">Scenes Overlay Opacity (<span
                                          for="alphaScn" class="currency" style="color: #1059A9;">{{ alphaScn.value }}%</span>)</span>
                                    </div>
                                    <div class="desc"
                                      style="background-color: #fff; border-top: none; border-bottom: 1px solid #EAECF0;">
                                      <mat-slider aria-label="unit(s)" min="0" max="100" step="1" value="100"
                                        id="alphaScn" #alphaScn (change)="chngAlpha(sensorIndex, alphaScn.value, sensor)"
                                        oninput="rangeValue.innerText = this.value">

                                      </mat-slider>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data">
                                    <div class="no-data" *ngIf="sensor.scenes?.length === 0">No Collection</div>
                                    <table class="table" *ngIf="sensor.scenes?.length > 0">
                                      <tbody>
                                        <tr *ngFor="
                                            let scene of sensor.scenes;
                                            index as scenesIndex
                                          ">
                                          <td class="noBorder">

                                        <div style="display: flex;" *ngIf="showDailyRecurrencyCheckboxes">
                                            <mat-checkbox class="example-margin"
                                              [checked]="
                                         scene.checked" (change)="
                                         toggleSubDailyRecurrentSensor(
                                          sensorIndex,
                                          scenesIndex,
                                           $event
                                         )
                                          " [disabled]="!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' 
                                          || (sensor.operatorKey === OPERATOR.SATELLOGIC && scene?.sceneName.includes('L1D'))"
                                              [(ngModel)]="scene.checked">
                                              <div class="flex">
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey != OPERATOR.USL && scene?.operatorKey != OPERATOR.AXELSPACE && scene?.operatorKey != OPERATOR.LBAND
                                                  && !(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')">Scene
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate  && scene?.operatorKey === OPERATOR.AXELSPACE">Tile
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey === OPERATOR.USL || scene?.operatorKey === OPERATOR.LBAND">Collection
                                                  {{ scene.sceneIdx }}</span>
                                                <span *ngIf="scene?.RRdeliveryDate"
                                                  class="scene-name">{{scene?.RRdeliveryDate }}</span>
                                                  <span *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')"
                                                  class="scene-name">{{scene?.sceneName }}</span>
                                              </div>
                                            </mat-checkbox>
                                            <img [src]="tooltipImagePath" alt="" 
                                             (click)="getMetadata(scene?.metadata_tooltip ? scene?.metadata_tooltip : scene.metadata)"
                                            *ngIf="!scene?.RRdeliveryDate && (scene?.metadata_tooltip || scene.metadata)" [matMenuTriggerFor]="tooltipMenu"
                                            style="padding: 0 0 0 10px; display: block; width: 25px; cursor: pointer;" 
                                            />

                                            <img [src]="tooltipImagePath" alt=""
                                            *ngIf="!scene?.RRdeliveryDate && (!scene?.metadata_tooltip && !scene.metadata)"
                                            style="padding: 0 0 0 10px; display: block; width: 25px;" />
                                          </div>
                                          </td>
                                          <td>
                                            <span class="flex">
                                              {{ scene?.predictedDate | date: "dd/MM/yyyy - hh.mm a" : "GMT" }}</span>
                                          </td>

                                          <td class="noBorder">
                                            <svg class="change-my-color" *ngIf="!scene.id && scene?.status != 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                            <svg class="change-my-colors" *ngIf="scene.id || scene?.status === 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                          </td>

                                          <td>
                                            <mat-icon
                                              [ngClass]="scene?.status === 'failed' ? 'my-icon' : 'my-icon-disable'">error_outline</mat-icon>
                                          </td>

                                          <td class="noBorder">
                                            <img (click)="
                                                download(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  scenesIndex,
                                                  scene
                                                )
                                              " *ngIf="(scene.id && scene?.status != 'failed' && ((sensor?.status != 'archived' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'both'))) && !demoView" 
                                              [style.width]="(scene?.downloaded || checkDownloaded(scene)) ? '23px' : '21px'"
                                              [src]="(scene?.downloaded || checkDownloaded(scene)) ? downloadedImage : downloadImage" style="cursor: pointer;"
                                              alt="" />

                                            <img *ngIf="(!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' || (sensor?.status === 'restored' && sensor?.restoreOption === 'view')) || demoView" width="21px"
                                              src="../../../assets/images/cloud.png" alt="" />
                                          </td>
                                          <td style="padding-right:5px;"  *ngIf="(!currentUser?.allocated || (currentUser?.allocated && (collection?.target.type === 'Point' && collection?.frequency != 1  && scene.operatorKey !== OPERATOR.AXELSPACE)))
                                          && scene.operatorKey !== OPERATOR.LBAND">
                                            <img
                                            *ngIf="shouldNotDisplayScene(scene, collection, downloadedScene, sensor) ||
                                            checkForRelease(sensor)"
                                            style="cursor: pointer;" width="21px"
                                              src="../../../assets/images/refund-inactive.png" alt=""
                                              [style.cursor]="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? 'pointer' : 'default'"
                                              (click)="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? refundStatus(scene) : null" />
                                            <img (click)="OpenReturnRequest(collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              scene)"
                                              *ngIf="shouldDisplayScene(scene, collection, downloadedScene, sensor) &&
                                              !checkForRelease(sensor)"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage" alt="" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>



                                  </div>
                                </div>
                              </div>

                            </mat-expansion-panel>
                          </mat-accordion>
                          <hr class="solid">
                        </div>
                      </div>

                      <div class="card" *ngIf="collection?.feasibilityTask && collection?.opportunityList?.length > 0">

                        <div class="details-block sub-block" *ngFor="
                                let sensor of feasibilityArray;
                                index as sensorIndex
                              ">
                          <mat-accordion>
                            <mat-expansion-panel [expanded]="isFirst">
                              <mat-expansion-panel-header #panelH (click)="panelH._toggle()" style="padding: 0 9px;">
                                <div class="details-summary">


                                  <mat-checkbox class="example-margin" color="primary"
                                    *ngIf="showDailyRecurrencyCheckboxes" [checked]="sensor.checked" (change)="
                                    compareOpportunity(
                                      collectionIndex,
                                     sensorIndex,
                                      undefined,
                                      $event,
                                      undefined
                                    )
                                  " [(ngModel)]="sensor.checked">
                                    <span class="sensor-name">{{ (currentUser?.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor?.name }}</span>
                                    <img *ngIf="sensor?.resolution_tooltip" style="margin-left: 5px;margin-top: -3px;"
                                      width="14px" [src]="tooltipImagePath"
                                      [matTooltip]="sensor?.resolution_tooltip" />
                                      <img *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')" style="margin-left: 5px;margin-top: -3px;"
                                    width="14px" [src]="tooltipImagePath"
                                    matTooltip="If the imaging were over land, you would receive an orthorectified (L1D) product ten hours from the first data (Geocorrected (L1B))." />
                                  </mat-checkbox>
                                  <span class="align-head" *ngIf="sensor?.latency === 'Emergency'">No preview
                                    available</span>

                                  <div class="img-icon" fxLayoutGap="10px">
                                    <mat-icon *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                    (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'"
                                    (click)="checkForDelivered(sensor) ? '' : releaseVADP(collection.taskId,sensor)"
                                    [disabled]="checkForDelivered(sensor)"
                                    matTooltip="Release for VADP" [ngClass]="checkForDelivered(sensor) ? 'my-icon-disable' : 'drive'"
                                    >3d_rotation</mat-icon>
                                    
                                    <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                      (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed'
                                      && (!checkForRelease(sensor)) || ((checkForRelease(sensor) && checkForDelivered(sensor)))"
                                      style="cursor: pointer;" width="21px" matTooltip="Request for VADP Refund"
                                      [src]="refundImage"
                                      alt=""
                                      (click)="refundVADP(collectionIndex, sensorIndex,sensor)"
                                    />

                                    <img *ngIf="((((sensor ||  {}).valueAddedOption || []).length != 0) || 
                                    (((sensor ||  {}).superResolutions || []).length != 0)) && sensor?.status === 'completed' && 
                                    (checkForRelease(sensor) && !checkForDelivered(sensor))" style="cursor: default;" width="21px"
                                      src="../../../assets/images/refund-inactive.png" alt=""
                                    />
                                    <mat-icon *ngIf="(sensor.operatorKey === OPERATOR.CAPELLA || (sensor.operatorKey === OPERATOR.UMBRA && (sensor.isAdditionalProducts || sensor.isAdditionalCSIProducts)) || 
                                    (sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush' && sensor?.assetsDelivered)) 
                                    && ((sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both')))"
                                    class="drive" (click)="getAdditionalData(collection.taskId,sensor)"
                                    [matTooltip]="this.downloadTooltip"
                                    (mouseover)="getTooltipMessage(sensor)"
                                    (mouseout)="onMouseOut()"
                                    [matTooltipDisabled]="tooltipDisabled"
                                    >add_circle_outline</mat-icon>
                                    <img (click)="
                                    download(
                                        collectionIndex,
                                        sensorIndex,
                                        undefined,sensor
                                      )
                                    " *ngIf="(sensor?.scenesList?.length > 0 && (sensor?.status === 'completed' || (sensor?.status === 'restored' && sensor?.restoreOption === 'both'))) && !demoView" 
                                    [style.width]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? '23px' : '21px'" style="cursor: pointer;"
                                    [src]="(sensor?.downloaded || checkSensorDownloaded(sensor)) ? downloadedImage : downloadImage" />
                                    <img *ngIf="(sensor.scenesList?.length < 1 || (sensor?.status != 'completed' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'view')) || demoView" width="21px"
                                      src="../../../assets/images/cloud.png" alt="" style="cursor: default;" />
                                  </div>


                                </div>
                                <mat-icon class="hiddenicon" (click)="panelH._toggle()" aria-hidden="false"
                                  aria-label="Example home icon" fontIcon="home"></mat-icon>
                              </mat-expansion-panel-header>
                              <div class="details-content">
                                <div class="details-content-inner">
                                  <div *ngIf="sensor?.latency === 'Emergency'"
                                    style="border-bottom: 1px solid #EAECF0;"></div>
                                  <div *ngIf="sensor?.latency != 'Emergency'">
                                    <div class="desc" style="border-bottom: 1px solid #EAECF0;">
                                      <span class="sensor-name" style="padding-left: 9px;">Scenes Overlay Opacity (<span
                                          for="alphaScn" class="currency" style="color: #1059A9;">{{ alphaScn.value }}%</span>)</span>
                                    </div>
                                    <div class="desc"
                                      style="background-color: #fff; border-top: none; border-bottom: 1px solid #EAECF0;">
                                      <mat-slider aria-label="unit(s)" min="0" max="100" step="1" value="100"
                                        id="alphaScn" #alphaScn (change)="chngAlpha(sensorIndex, alphaScn.value, sensor)"
                                        oninput="rangeValue.innerText = this.value">

                                      </mat-slider>
                                    </div>
                                  </div>
                                  <div class="table-responsives scenes-data">
                                    <div class="no-data" *ngIf="sensor.scenes?.length === 0">No Collection</div>
                                    <table class="table" *ngIf="sensor.scenes?.length > 0">
                                      <tbody>
                                        <tr *ngFor="
                                            let scene of sensor.scenes;
                                            index as scenesIndex
                                          ">
                                          <td class="noBorder">

                                            <div style="display: flex;" *ngIf="showDailyRecurrencyCheckboxes">
                                            <mat-checkbox class="example-margin"
                                              [checked]="
                                         scene.checked" (change)="
                                         compareOpportunity(
                                              collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              $event,
                                              undefined
                                            )
                                          " [disabled]="!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' 
                                          || (sensor.operatorKey === OPERATOR.SATELLOGIC && scene?.sceneName.includes('L1D'))"
                                              [(ngModel)]="scene.checked">
                                              <div class="flex">
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey != OPERATOR.USL && scene?.operatorKey != OPERATOR.AXELSPACE && scene?.operatorKey != OPERATOR.LBAND
                                                  && !(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')">Scene
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate  && scene?.operatorKey === OPERATOR.AXELSPACE">Tile
                                                  {{ scene.sceneIdx }}</span>
                                                <span class="scene-name"
                                                  *ngIf="!scene?.RRdeliveryDate && scene?.operatorKey === OPERATOR.USL || scene?.operatorKey === OPERATOR.LBAND">Collection
                                                  {{ scene.sceneIdx }}</span>
                                                <span *ngIf="scene?.RRdeliveryDate"
                                                  class="scene-name">{{scene?.RRdeliveryDate }}</span>
                                                  <span *ngIf="(sensor.operatorKey === OPERATOR.SATELLOGIC && sensor?.latency?.key === 'rush')"
                                                  class="scene-name">{{scene?.sceneName }}</span>
                                              </div>
                                            </mat-checkbox>
                                            <img [src]="tooltipImagePath" alt="" 
                                             (click)="getMetadata(scene?.metadata_tooltip ? scene?.metadata_tooltip : scene.metadata)"
                                            *ngIf="!scene?.RRdeliveryDate && (scene?.metadata_tooltip || scene.metadata)" [matMenuTriggerFor]="tooltipMenu"
                                            style="padding: 0 0 0 10px; display: block; width: 25px; cursor: pointer;" 
                                             />

                                            <img [src]="tooltipImagePath" alt=""
                                            *ngIf="!scene?.RRdeliveryDate && (!scene?.metadata_tooltip && !scene.metadata)"
                                            style="padding: 0 0 0 10px; display: block; width: 25px;" />
                                          </div>
                                          </td>
                                          <td>
                                            <span class="flex">
                                              {{ scene?.predictedDate | date: "dd/MM/yyyy - hh.mm a" : "GMT"}}</span>
                                          </td>

                                          <td class="noBorder">
                                            <svg class="change-my-color" *ngIf="!scene.id && scene?.status != 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                            <svg class="change-my-colors" *ngIf="scene.id || scene?.status === 'failed'"
                                              width="19" height="24" viewBox="0 0 19 24"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.75 21.6H14.25V18C14.25 16.68 13.7849 15.55 12.8547 14.61C11.9245 13.67 10.8062 13.2 9.5 13.2C8.19375 13.2 7.07552 13.67 6.14531 14.61C5.2151 15.55 4.75 16.68 4.75 18V21.6ZM9.5 10.8C10.8062 10.8 11.9245 10.33 12.8547 9.39C13.7849 8.45 14.25 7.32 14.25 6V2.4H4.75V6C4.75 7.32 5.2151 8.45 6.14531 9.39C7.07552 10.33 8.19375 10.8 9.5 10.8ZM17.8125 24H1.1875C0.851042 24 0.568813 23.8848 0.340813 23.6544C0.113604 23.4248 0 23.14 0 22.8C0 22.46 0.113604 22.1752 0.340813 21.9456C0.568813 21.7152 0.851042 21.6 1.1875 21.6H2.375V18C2.375 16.78 2.65723 15.6348 3.22169 14.5644C3.78535 13.4948 4.57187 12.64 5.58125 12C4.57187 11.36 3.78535 10.5048 3.22169 9.4344C2.65723 8.3648 2.375 7.22 2.375 6V2.4H1.1875C0.851042 2.4 0.568813 2.2848 0.340813 2.0544C0.113604 1.8248 0 1.54 0 1.2C0 0.86 0.113604 0.5748 0.340813 0.3444C0.568813 0.1148 0.851042 0 1.1875 0H17.8125C18.149 0 18.4308 0.1148 18.658 0.3444C18.886 0.5748 19 0.86 19 1.2C19 1.54 18.886 1.8248 18.658 2.0544C18.4308 2.2848 18.149 2.4 17.8125 2.4H16.625V6C16.625 7.22 16.3432 8.3648 15.7795 9.4344C15.215 10.5048 14.4281 11.36 13.4187 12C14.4281 12.64 15.215 13.4948 15.7795 14.5644C16.3432 15.6348 16.625 16.78 16.625 18V21.6H17.8125C18.149 21.6 18.4308 21.7152 18.658 21.9456C18.886 22.1752 19 22.46 19 22.8C19 23.14 18.886 23.4248 18.658 23.6544C18.4308 23.8848 18.149 24 17.8125 24Z" />
                                            </svg>
                                          </td>

                                          <td>
                                            <mat-icon
                                              [ngClass]="scene?.status === 'failed' ? 'my-icon' : 'my-icon-disable'">error_outline</mat-icon>
                                          </td>

                                          <td class="noBorder">
                                            <img (click)="
                                                download(
                                                  collectionIndex,
                                                  sensorIndex,
                                                  scenesIndex,
                                                  scene
                                                )
                                              " *ngIf="(scene.id && scene?.status != 'failed' && ((sensor?.status != 'archived' && sensor?.status != 'restored') || (sensor?.status === 'restored' && sensor?.restoreOption === 'both'))) && !demoView" 
                                              [style.width]="(scene?.downloaded || checkDownloaded(scene)) ? '23px' : '21px'"
                                              [src]="(scene?.downloaded || checkDownloaded(scene)) ? downloadedImage : downloadImage" style="cursor: pointer;"
                                              alt="" />

                                            <img *ngIf="(!scene.id || scene?.status === 'failed' || sensor?.status === 'archived' || (sensor?.status === 'restored' && sensor?.restoreOption === 'view')) || demoView" width="21px"
                                              src="../../../assets/images/cloud.png" alt="" />
                                          </td>
                                          <td style="padding-right:5px;"  *ngIf="(!currentUser?.allocated || (currentUser?.allocated && (collection?.target.type === 'Point' && collection?.frequency != 1  && scene.operatorKey !== OPERATOR.AXELSPACE)))
                                          && scene.operatorKey !== OPERATOR.LBAND">
                                            <img
                                            *ngIf="shouldNotDisplayScene(scene, collection, downloadedScene, sensor) ||
                                            checkForRelease(sensor)"
                                            style="cursor: pointer;" width="21px"
                                              src="../../../assets/images/refund-inactive.png" alt=""
                                              [style.cursor]="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? 'pointer' : 'default'"
                                              (click)="((scene?.returnRefundAccept || scene?.returnRefundReject) && collection?.returnRefund) ? refundStatus(scene) : null" />
                                            <img (click)="OpenReturnRequest(collectionIndex,
                                              sensorIndex,
                                              scenesIndex,
                                              scene)"
                                              *ngIf="shouldDisplayScene(scene, collection, downloadedScene, sensor) &&
                                              !checkForRelease(sensor)"
                                              style="cursor: pointer;" width="21px"
                                              [src]="refundImage"
                                              alt="" />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>



                                  </div>
                                </div>
                              </div>

                            </mat-expansion-panel>
                          </mat-accordion>
                          <hr class="solid">
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="baseLayer">
                  <div class="desc" style="background-color: #fff;    border-top: none; ">
                    <span class="sensor-name">Base Layer (<span for="alpha" class="currency" style="color: #1059A9;">{{
                        alpha.value }}%</span>)</span>
                  </div>
                  <div class="desc" style="background-color: #f9fafb;     border-bottom: none;">
                    <mat-slider aria-label="unit(s)" min="0" max="100" step="1" value="100" #alpha
                      (change)="chngAlpha(-9, alpha.value,'sensor')" oninput="rangeValue.innerText = this.value"
                      [(ngModel)]="baseOverlay">
                    </mat-slider>
                  </div>
                </div>


            </div>
          </div>
        </div>

          <!-- scroll-start -->

          <!-- Accordian-set-two -->
        </div>

        <div id="legend-container" class="legend-fixed-container">
          <!-- The legend content will be inserted here -->
        </div>
        
        <div class="right-align" style="position: relative;">
          <div id="cesiumContainer1" class="cesiumcontainer" style="width: 49%; height: calc(100vh - 220px) !important">
            <ngx-spinner *ngIf="mapLoad" color="white" type="ball-clip-rotate" [fullScreen]="false"
              size="medium"></ngx-spinner>
              <div id="feedTooltip" style="display: none; position: absolute; background: black;color: white; border: 1px solid black; padding: 10px; z-index: 100;
              max-width: 300px;"></div>
            <div style="
                position: absolute;
                top: 40px;
                right: 5px;
                color: white;
                z-index: 10;
                height: 10px;
              ">
              <p>Search by Lng, Lat or Location</p>
            </div>
            <div style="position: absolute; z-index: 1; top: 3px">
              <div>
                <button class="cesium-button " title="Annotate" (click)="annotateImage()" [disabled]="!imageLoaded || showDailyRecurrencyCheckboxes || timeseries || isVisible || newTaskButtonClicked"
                [ngClass]="annotateClicked ? 'annotate' : 'default-icon'">
                  <span class='material-icons-outlined'>font_download</span>
                </button>
              </div>
              <div>
                <button class="cesium-button " title="Create New Task" (click)="createNewTask()" [disabled]="checkSelectedSecene() || annotateClicked"
                [ngClass]="newTaskButtonClicked ? 'annotate' : 'default-icon'">
                  <span class='material-icons-outlined'>add_task</span>
                </button>
              </div>
              <div *ngIf="!checkSelectedSecene() && audioAvailable">
                <button class="cesium-button " title="Audio signals available" (click)="openAudioSignals()"
                [ngClass]="'default-icon'">
                <span class='material-icons-outlined'>volume_up</span>

              </button>
              </div>
            </div>
            <div style="
              position: absolute;
              z-index: 1;
              bottom: 100px;
              right: 0px;
              display: flex;
              flex-direction: column;
              align-items: center;
            ">
              <div>
                <div>
                  <img width="60px" src="../../../assets/images/view-pan-64x64.png" usemap="#PanImagMap" 
                  (click)="zoomToLocation()" />
                </div>
                <map name="PanImagMap">
                  <area onblur="this.focus()" autofocus shape="circle" coords="30,30,10" title="Home"/>
                  <area shape="rect" coords="0,16,18,40" title="Move left" (click)="moveLeft()" />
                  <area shape="rect" coords="42,16,60,40" title="Move right" (click)="moveRight()" />
                  <area shape="rect" coords="16,0,40,18" title="Move up" (click)="moveUp()" />
                  <area shape="rect" coords="16,42,40,60" title="Move down" (click)="moveDown()" />
                </map>
              </div>
                <button class="cesium-button" (click)="zoomIn()">
                  <img width="29px" src="../../../assets/images/view-zoom-in-32x32.png" />
                </button>
                <button class="cesium-button" (click)="zoomOut()">
                  <img width="29px" src="../../../assets/images/view-zoom-out-32x32.png" />
                </button>
          </div>

          <div [ngStyle]="{'display': isVisible ? 'block' : 'none'}">
            <div id="sliders" class="slider"></div>
          </div>

          <div
            [ngStyle]="{'display': isVisible && showDailyRecurrencyCheckboxes && splitRightPostion ? 'block' : 'none'}">
            <div *ngIf="!collection?.feasibilityTask" class="split-heading-right">
              <b *ngIf="!dateRange">{{previousSelectionText | date:"dd/MM/yyyy" : "GMT"}}</b>
              <b *ngIf="dateRange && this.selectedSites?.length != 2"> {{previousSelectionText | date:"dd/MM/yyyy" : "GMT"}} - {{previousEndText | date:"dd/MM/yyyy" : "GMT"}}</b>
              <br>
              <b>{{this.subDailyCount}}</b>
            </div>

            <div [ngStyle]="{'display': collection?.feasibilityTask  && splitRightPostion ? 'block' : 'none'}"
              class="split-heading-right">
              <b>{{this.feasibilityArray[0]?.date | date: "dd/MM/yyyy" : "GMT"}}</b>
            </div>

          </div>
          <div
            [ngStyle]="{'display': isVisible && showDailyRecurrencyCheckboxes  && splitLeftPostion  ? 'block' : 'none'}">
            <div *ngIf="!collection?.feasibilityTask " class="split-heading-left">
              <b *ngIf="!dateRange">{{selectionLabel | date: "dd/MM/yyyy" : "GMT"}}</b>
              <b *ngIf="dateRange && this.selectedSites?.length != 2"> {{selectionLabel | date:"dd/MM/yyyy" : "GMT"}} - {{selectionLabelEnd | date:"dd/MM/yyyy" : "GMT"}}</b>
                <br>
              <b>{{this.selectedSubdaily}}</b>
            </div>

            <div [ngStyle]="{'display': collection?.feasibilityTask  && splitLeftPostion ? 'block' : 'none'}"
              class="split-heading-left"><b>{{this.feasibilityArray[1]?.date | date: "dd/MM/yyyy" : "GMT"}}</b>
            </div>
          </div>

          <div *ngIf="this.collection?.sensors[0]?.scenes[0]?.operatorKey === this.OPERATOR.USL">
            <div class="split-heading-right" style="left: 45px;" *ngIf="leftBand">
              <div>
                <img width="20px" height="20px" src="../../../assets/images/cross.png" />
                <b> - X (9327 MHz - 9428 MHz)</b>
              </div>
          
              <div>
                &nbsp;<img width="18px" height="18px" src="../../../assets/images/cross-S.png" />
                <b> - S (3024 MHz- 3080 MHz)</b>
              </div>
            </div>
          
            <div class="split-heading-left" style="left: 55%;" *ngIf="rightBand">
              <div>
                <img width="20px" height="20px" src="../../../assets/images/green-cross.png" />
                <b> - X (9327 MHz - 9428 MHz)</b>
              </div>
          
              <div>
                &nbsp;<img width="18px" height="18px" src="../../../assets/images/green-cross-S.png" />
                <b> - S (3024 MHz- 3080 MHz)</b>
              </div>
            </div>
          </div>

          <div class="split-heading-left feed-class" *ngIf="feedInfo && feedData">
            <div>
              <b>{{feedData}}</b>
            </div>
          </div>

          <div class="split-heading-left oil-class" *ngIf="feedInfo && oilSpil"
          style="background-color: black; padding: 7px;">
            <div>
              <span class="color-legend" style="top: 29%" [ngStyle]="{'background-color': 'MAGENTA'}">
              </span>
              &nbsp;&nbsp;&nbsp;<b style="padding-left: 16px;">Oil spill </b>
            </div>
            <div>
              <span class="color-legend" style="top: 69%" [ngStyle]="{'background-color': 'PINK'}">
              </span>
              &nbsp;&nbsp;&nbsp;<b style="padding-left: 16px;">Probable oil spill </b>
            </div>
          </div>
          
        </div>

        <mat-icon *ngIf="sliderShow" class="material-icons-outlined slider-close"
        (click)="clearSilder();resetPreviousEntity()" matTooltip="Clear historical data" >
          close
        </mat-icon>
        <div id="sliderDiv" *ngIf="sliderShow">
          <mat-slider min="0" [max]="dateRangeArray.length" step="1" thumbLabel tickInterval="1" (input)="getSelectedDateVessels($event)">
          </mat-slider>
          <div class="slider-labels">
            <span *ngFor="let item of dateRangeArray; let i = index" class="label-color">{{ item }}</span>
          </div>
        </div>
        <div id="sliderDiv" *ngIf="sliderView && this.sliderDateRangeArray && this.sliderDateRangeArray.length > 0 && AISdata && !recurrentSlider">
          <mat-slider #slider min="0" [max]="sliderDateRangeArray.length" step="1" thumbLabel tickInterval="1" (input)="overlayData($event.value, 'overlay')"
          [value]="currentSliderValue">
          </mat-slider>
          <div class="slider-labels">
            <span *ngFor="let item of sliderDateRangeArray; let i = index" class="label-color">{{ item }}</span>
          </div>
        </div>
        <div id="sliderDiv" *ngIf="sliderView && this.sliderDateRangeArray && this.sliderDateRangeArray.length > 0 && recurrentSlider">
          <mat-slider #slider min="0" [max]="sliderDateRangeArray.length" step="1" thumbLabel tickInterval="1" (input)="overlayRecurrentData($event.value, 'overlay')"
          [value]="currentSliderValue">
          </mat-slider>
          <div class="slider-labels">
            <span *ngFor="let item of sliderDateRangeArray; let i = index" class="label-color">{{ item.start | date: "dd/MM/yyyy"}} - {{item.end | date: "dd/MM/yyyy"}}</span>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>

  </div>
  <div id="slider"></div>
</div>


<mat-menu #tooltipMenu="matMenu"  class="mat-menu-style" yPosition="above">
    <app-custom-tooltip [metadata]="metaDataTooltip" product="collection"></app-custom-tooltip>
</mat-menu>
