import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { TaskService } from 'src/app/services/task.service';
import { ThemeService } from 'src/app/services/theme.service';
import { DesktopVersionComponent } from '../my-task/desktop-version/desktop-version.component';
import { MatDialog } from '@angular/material/dialog';
import { BudgetpopupComponent } from '../newtask/budgetpopup/budgetpopup.component';
import { ApiService } from 'src/app/services/api.service';
import { CountrycheckComponent } from '../taskcost/countrycheck/countrycheck.component';
import { ConfirmDialog } from '../taskcost/confirmdialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-budgets',
  templateUrl: './budgets.component.html',
  styleUrls: ['./budgets.component.scss']
})
export class BudgetsComponent implements OnInit {
  currentUser: any = undefined;
  displayedColumns: string[] = ['budgetName', 'createdAt', 'startDate', 'endDate','sensorType','resolution','frequency','cloudCover','taskingTier','holdBack', 'taskcost','action'];
  dataSource = new MatTableDataSource<any>();
  tableDataLength: number;
  filteredData: any;
  userid: any;
  darkTheme: boolean;
  invoiceImagePath: string;
  receiptsImagePath: string;
  isInIframe: boolean;
  retaskDisable:boolean=false;
  dialog: any;
  currentTime: Date = new Date();
  pointUser: boolean = false;
  taskCliked: boolean;
  constructor(public datepipe: DatePipe, private authService: AuthService,
    private router: Router, private taskService: TaskService,
    private localStorageService: DataService,
    private themeService: ThemeService,
    private matdialog: MatDialog,
    private apiService: ApiService,
    private userService: UserService
  ) {
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;

        this.invoiceImagePath = this.darkTheme ? '../../../assets/images/invoice-dark.png' : '../../../assets/images/invoice.png';
        this.receiptsImagePath = this.darkTheme ? '../../../assets/images/receipts-dark.svg' : '../../../assets/images/receipts.svg';
      });
      this.isInIframe = this.themeService.isInIframe();    
  }

  ngOnInit(): void {
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;

      if (!this.currentUser?.verified) {
        this.router.navigate(['login']);
      }
      else {
        this.authService.user.subscribe(user => this.currentUser = user);
        this.userid = this.currentUser.id;
        if (this.currentUser.userType === 'admin')
          this.userid = this.userid + 'admin';
        if((this.currentUser?.ndc_user || this.currentUser?.allocated)) this.router.navigate(['task']);        
        else {
          this.getBudgets(this.userid);
          this.getUser(this.userid);
        }
      }
      castUser.unsubscribe();
    });
  }

  desktopVersion() {
    this.matdialog.open(DesktopVersionComponent, {
      maxWidth: '80%',
      backdropClass: 'blurred',
      disableClose: true,
    });
  }

  getBudgets(userId: any) {
    // this.tableDataLength = 0;
    this.taskService.getBudgets(userId).subscribe(
      (result: any) => {
        
        this.filteredData = [];
        this.tableDataLength = result?.response?.length > 0 ? result?.response?.length: 0;

        if (result?.response?.length > 0) {
          this.filteredData = result?.response;
          this.filteredData = this.filteredData.map(record => ({
            ...record,
            isBudgetEqual: this.isRangesAreEqual(record.budget)
          }));
          this.dataSource = new MatTableDataSource(this.filteredData);
        }
      },
      (err) => {
        return false;
      }
    );
  }
 
  isRangesAreEqual(budget: any[]): boolean {
    const budgetItem = budget[0];
    return budgetItem.minRange === budgetItem.maxRange;
  }

  getRetask(element: any) {       
    let newTaskParams = {};
   newTaskParams['aoiCountry'] = element?.aoiCountry;
   newTaskParams['aoiPlace'] = element?.aoiPlace;
   newTaskParams['area'] = element?.area;
   newTaskParams['budget'] = element?.budget;
   newTaskParams['budgetRange'] = element?.budgetRange;
   newTaskParams['closeWindow'] = element?.closeWindow;
   newTaskParams['holdBack'] = element?.holdBack;
   newTaskParams['intent'] = element?.intent;
   newTaskParams['noOfMonths'] = element?.noOfMonths;
   newTaskParams['noOfRepeats'] = element?.noOfRepeats;
   newTaskParams['numOfWeeks'] = element?.numOfWeeks;
   newTaskParams['openWindow'] = element?.openWindow;
   newTaskParams['purpose'] = element?.purpose;
   newTaskParams['repeatFrequency'] = element?.repeatFrequency;
   newTaskParams['resolution'] = element?.resolution;
   newTaskParams['sensorType'] = element?.sensorType;
   newTaskParams['sensors'] = element?.sensors;
   newTaskParams['target'] = element?.target;
   newTaskParams['taskingTier'] = element?.taskingTier;

   newTaskParams['projectid'] = null;
   newTaskParams['feasibilityTask'] = false;
   newTaskParams['observation'] = null;
   newTaskParams['tipAndCueBasedOn'] = '';
   newTaskParams['budgetRange'] = undefined;
   newTaskParams['circleCordinates'] = undefined;
   newTaskParams['expected_age'] = undefined;
   newTaskParams['mode'] = undefined;
   newTaskParams['subDailyCount'] = '';
   newTaskParams['noOfMonths'] = 0;
   const dialogRef = this.matdialog.open(BudgetpopupComponent, {
    backdropClass: 'blurr', disableClose: true, width: '40%',
    data: { data:element },
  });  
  dialogRef.afterClosed().subscribe((result: { action: string, data?: any }) => {
  this.taskCliked = false;
  if (result?.action === 'save_task') {
    this.taskCliked = true;
    newTaskParams['project'] = result?.data?.project;
    newTaskParams['taskname'] = result?.data?.taskname;
    newTaskParams['taskStrategy'] = result?.data?.taskStrategy?.value;
    newTaskParams['sensors'] = result?.data?.sensors;
    newTaskParams['intent'] = result?.data?.intent;
    newTaskParams['purpose'] = result?.data?.purpose;
    newTaskParams['projectid'] = null;
    newTaskParams['observation'] = null;
    newTaskParams['tipAndCueBasedOn'] = "";
    newTaskParams['feasibilityTask'] = false;
    newTaskParams['mode'] = undefined;
    newTaskParams['customerid'] = element?.customerId;
    newTaskParams['subDailyCount'] = "";
    newTaskParams['noOfMonths'] = 0;
    newTaskParams['budgetRange'] = undefined;
     newTaskParams['circleCordinates'] =undefined;
    newTaskParams['expected_age'] = undefined;
    newTaskParams['frequency'] = element?.frequency;
    newTaskParams['fileName'] = "";
    newTaskParams['target'] = JSON.parse(element['target']);
    newTaskParams['openWater'] = result?.data?.openWater
   
    this.apiService.newTaskSubmit(newTaskParams).subscribe((res: any) => {
      if (res?.msg === 'Success') {
        let data = JSON.parse(res?.response);
        let taskid;
        let sensorList = this.matchUUIDs( newTaskParams['sensors'], data);
        newTaskParams['sensors'] = JSON.stringify(sensorList);
       
        if (data && data.length > 0) {
          taskid = res?.taskid;
          newTaskParams['taskId'] = taskid;
        
          const budgetId=  element['_id'];
        delete element['_id'];
        delete element['__v'];
          this.apiService
            .genCollection(
              this.currentUser.id,
              this.currentUser.email,
              newTaskParams
            )
            .subscribe((res: any) => {
              const taskid = res?.id;
              this.apiService.updateBudget(budgetId).subscribe((res: any) => {
                if (res?.msg === 'Success'){
                
                }
                 
               
              });
            });
            const dialogRef = this.matdialog.open(ConfirmDialog, {
              backdropClass: 'blurr', disableClose: true,
              data: { taskid: taskid, taskname:  newTaskParams['taskname'], response: res, type: 'normal',from:'budget' },
            });
        }
      
      }
    }, (error: any) => {
      const dialogRef = this.dialog.open(CountrycheckComponent, {
        width: '50%', backdropClass: 'blurred', disableClose: true,
        data: { message: "fail",url:"/task" }
      });
    });

    
   }
 });
  }
  handleButtonClick(event: Event) {
    event.stopPropagation();
  }
  matchUUIDs(selectedSensors,taskResponse) {
    let sensorList = JSON.parse(selectedSensors);
    
    const minLength = Math.min(sensorList.length, taskResponse.length);  
    for (let i = 0; i < minLength; i++) {
      sensorList[i].uuid = taskResponse[i].uuid;
    }

    return sensorList;
  }
  capitalizeFirstLetter(value: string): string {
    if (!value) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  isDisabled(row): boolean {
    const openWindowDate = new Date(row.openWindow);
    return openWindowDate < this.currentTime;
  }

  getUser(userId) {
    this.userService.personalInfo(userId).subscribe((result: any) => {
      if (result.pointsEligible) {
        this.pointUser = true;
      }
    });
  }
}