import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TaskService } from 'src/app/services/task.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from './delete/delete.component';
import { ApiService } from 'src/app/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from 'src/app/services/snackbar.service';
import { CancelpaylaterComponent } from '../payment/cancelpaylater/cancelpaylater.component';
import { ToiWarningPopupComponent } from '../my-task/toi-warning-popup/toi-warning-popup.component';
import { OPERATORS } from 'src/app/services/constants';
import { ConfirmPopupComponent } from '../my-task/confirm-popup/confirm-popup.component';
import { CatalogsPopupComponent } from '../my-task/catalogs-popup/catalogs-popup.component';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
export interface orders {
  projectname: string | null;
  taskname: string | null;
  startdue: string | null;
  enddue: string | null;
  total: string | null;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'projectname',
    'taskname',
    'startdue',
    'enddue',
    'total',
    'delete',
  ];
  dataSource: any = [];
  currentUser: any;

  tasktabs: any;
  cartData: any[];
  selectedSensors: any = [];
  tasksCount: any;
  userid: any;
  tableDataLength: any = 0;
  selectedTask: any;
  currency = environment.payCurrency;
  currencySign = environment.payCurrencySign;

  totalAmount = 0;
  totalPoint=0;
  totalTaskCount=0;
  CARTDATA: any;
  satellogic: boolean = false;
  operators: any;
  umbra: boolean;
  head: boolean;
  darkTheme: boolean;
  deletedImagePath: string;
  allocated: boolean = false;
  constructor(
    private matdialog: MatDialog,
    private authService: AuthService,
    private taskService: TaskService,
    private router: Router,
    public dialog: MatDialog,
    private apiService: ApiService,
    private toastService: ToasterService,
    private localStorageService: DataService,
    private userService: UserService
    ) { }

  ngOnInit(): void {

   
    const castUser = this.authService.castCurrentUser.subscribe(
      async (res: any) => {
        this.currentUser = await res;
        if (!this.currentUser?.verified) {
          this.router.navigate(['login']);
          this.authService.logout();
        } else {
          this.userid = this.currentUser.id;
          if (this.currentUser.userType === 'admin')
            this.userid = this.userid + 'admin';
          this.getCart(this.userid);
        }
        castUser.unsubscribe();
        this.loadUserDetails(this.currentUser.id);
      }
    );

    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.deletedImagePath = this.darkTheme
        ? '../../../assets/images/deleted-dark.png'
        : '../../../assets/images/deleted.png';
    });
    localStorage.removeItem('taskData');
  }

  loadUserDetails(userId): void {
    if (userId) {
      this.userService.personalInfo(userId).subscribe({
        next: (res: any) => {
          this.currentUser = res;
          this.allocated = this.currentUser?.allocated;
        },
        error: (error) => {
        }
      });
    }
  }

  getCart(userId: any) {
    this.tableDataLength = 0;
    let data: any;
    this.CARTDATA = [];

    this.apiService.getCart(userId).subscribe((result: any) => {
      this.tableDataLength = result?.result?.length;
      data = result?.result.reverse();
      if (result?.result?.length > 0) {
        this.totalAmount = 0;
        for (const [index, carts] of data.entries()) {
          carts.cartdata = JSON.parse(carts.cartdata);
          this.CARTDATA.push(carts);
        }
        this.selectedTask = this.CARTDATA[0];
        this.totalAmount = this.getTotalCost(this.CARTDATA[0].cartdata);
        this.totalPoint = this.getTotalPoint(this.CARTDATA[0].cartdata);
        this.totalTaskCount = this.getTotalTaskCount(this.CARTDATA[0].cartdata);
        this.cartData =
          this.CARTDATA[0].cartdata && this.CARTDATA[0].cartdata.length > 0
            ? this.CARTDATA[0].cartdata
            : [];
        this.CARTDATA[0].cartdata[0].checked = false;
        this.dataSource = new MatTableDataSource(this.CARTDATA);
      } else {
        this.dataSource = [];
      }
    });
  }

  getCartData(userId: any) {
    this.tableDataLength = 0;
    this.apiService.cartdata(this.currentUser.id);
    const res = this.apiService.castCartData.subscribe((result: any) => {
      this.tableDataLength = result?.length;
      if (result?.length > 0) {
        this.totalAmount = 0;
        this.CARTDATA = result.reverse();
        this.selectedTask = this.CARTDATA[0];
        this.totalAmount = this.getTotalCost(this.CARTDATA[0].cartdata);
        this.cartData =
          this.CARTDATA[0].cartdata && this.CARTDATA[0].cartdata.length > 0
            ? this.CARTDATA[0].cartdata
            : [];

        this.CARTDATA[0].cartdata[0].checked = false;
        this.dataSource = new MatTableDataSource(this.CARTDATA);
      }

      if (this.CARTDATA?.length === 0) {
        this.router.navigate(['/tasks']);
      }
      res.unsubscribe();
    });
  }
  getTotalCost(cartdata: any) {
    let sumWithInitial;
    sumWithInitial = cartdata.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue +  (parseFloat(currentValue.cost) + parseFloat(currentValue?.demTotalPrice ?? 0)),
      0
    );
    let srTotal = 0;
    cartdata.forEach((i) => {
      i?.super_resolution?.forEach((r) => {
        srTotal += parseFloat(r?.totalareacost);
      })
    })
    let IndicesTotal = 0;
    cartdata.forEach((i) => {
      i?.indices?.forEach((r) => {
        IndicesTotal += parseFloat(r?.totalareacost);
      })
    })
    return sumWithInitial + srTotal + IndicesTotal;
  }
  getTotalPoint(cartdata: any) {
    let sumWithInitial;
    sumWithInitial = cartdata.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + currentValue.point,
      0
    );
    return sumWithInitial;
  }
  getTotalTaskCount(cartdata: any) {
    let sumWithInitial;
    sumWithInitial = cartdata.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + currentValue.taskNumber,
      0
    );
    return sumWithInitial;
  }
  onItemChange(value: any) {
    this.selectedTask = value;
    this.cartData = value.cartdata.length > 0 ? value.cartdata : [];
    this.totalAmount = this.getTotalCost(this.cartData);
    this.totalPoint = this.getTotalPoint(this.cartData);
    this.totalTaskCount = this.getTotalTaskCount(this.cartData);
  }

  select(data) {
    this.CARTDATA.forEach((val) => {
      if (val.cartdata[0]?.uuid == data.uuid) {
        val.cartdata[0].checked = !val.cartdata[0].checked;
      } else {
        if (val.cartdata[0]) {
          val.cartdata[0].checked = true;
        }
      }
    });
  }

  ProceedToPay(task: any) {
    this.operators = [];
    this.satellogic = false;
    this.umbra = false;
    const endDate = new Date(task.closeWindow);
    const currentDate = new Date();
    const startDate = new Date(task.openWindow);
    this.head = false;
    let checkTime = false;

    let approveTime: any;
    let timeDifference = 0, twelveHoursInMillis = 0;

    task.cartdata.forEach((val) => {
      if (val.operatorKey === OPERATORS.UMBRA) {
        this.umbra = true;
      } else if (val?.approveTime) {
        approveTime = new Date(val.approveTime);
        timeDifference = currentDate.getTime() - approveTime.getTime();
        twelveHoursInMillis = 12 * 60 * 60 * 1000;
        checkTime = true;
      }
      if (checkTime && val.operatorKey === OPERATORS.HEAD && timeDifference >= twelveHoursInMillis) {
        this.head = true;
      }
    });

    if (endDate.getTime() < currentDate.getTime()) {
      const dialogRef = this.dialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'toi', row: task.cartdata, type: 'cart', user: this.currentUser },
      });
    } else if (startDate.getTime() < currentDate.getTime() && this.umbra) {
      const dialogRef = this.dialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'umbra', row: task.cartdata, type: 'cart', user: this.currentUser },
      });
    } else if (task?.feasibilityTask) {
      const dialogRef = this.dialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'cartFeasible', row: task.cartdata, type: 'cart', user: this.currentUser },
      });
    } else if (this.head) {
      const dialogRef = this.dialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'head', row: task.cartdata, type: 'cart', user: this.currentUser }
      });
    } else {
      task.cartdata.forEach((val) => {
        if(val.operatorKey !== OPERATORS.SPIRE && val.operatorKey !== OPERATORS.CLYDE
          && val.operatorKey !== OPERATORS.LBAND
        ) {
          if (!task?.target.includes('Point') || task?.taskFrequency === 1) {
            this.satellogic = true;
            const operator = val.name,
              taskid = val.taskid,
              sensor = val.sensor,
              resolution = val.resolution,
              status =
                val.status === 'Ordered' ||
                val.orderStatus === 'ordered' ||
                val.status === '100',
              operatorKey = val?.operatorKey,
            uuid = val.uuid;
            this.operators.push({
              operator,
              taskid,
              sensor,
              resolution,
              uuid,
              status,
          operatorKey });
          }
        }
      });
      if (this.satellogic) {
        const dialogRef = this.dialog.open(CancelpaylaterComponent, {
          width: '50%',
          backdropClass: 'blurred',
          disableClose: true,
          data: { satellogic: this.satellogic },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result === 'yes') {
            const sensors = task.cartdata;
            const isISTask = task.isISTask;

            let selectedSensors: any = [];
            sensors.forEach(item => {
              selectedSensors.push({
                sensor: item.sensor,
                name: item.name,
                resolution: item?.originalResolution
              });
            });
            this.apiService
              .getFeedsBySensorType(selectedSensors)
              .subscribe((result: any) => {
                if ((result && result?.informationServices?.length)
                  && task?.taskStrategy != 'Tip-and-Cue' && this.allocated === false) {
                    const dialogRef = this.matdialog.open(ConfirmPopupComponent, {
                      width: '50%',
                      disableClose: true,
                      backdropClass: 'blurred',
                      data: {message: 'FEED'}
                    });
      
                    dialogRef.afterClosed().subscribe(async (result) => {
                      if (result === 'yes') {
                        const catlogRef = this.matdialog.open(
                          CatalogsPopupComponent,
                          {
                            width: '50%',
                            backdropClass: 'blurred',
                            disableClose: true,
                            data: {taskId: task.taskid, sensorTypes: selectedSensors,
                              isOpenWater: task?.openWater
                            },
                          }
                        );
                        catlogRef.afterClosed().subscribe(async (result) => {
                          if (result !== 'no') {
                            task.mlTasks = result;
                            const newcartdata = [];
                            let taskcost = 0;
                            this.selectedSensors = [];
      
                            newcartdata['customerid'] = this.currentUser.id;
                            newcartdata['taskid'] = task.taskid;
                            newcartdata['project'] = task.project;
                            newcartdata['taskname'] = task.taskname;
                            newcartdata['target'] = task.target;
                            newcartdata['openWindow'] = task.openWindow;
                            newcartdata['closeWindow'] = task.closeWindow;
                            newcartdata['checked'] = true;
                            newcartdata['taskFrequency'] = task?.frequency;
                            newcartdata['mlTasks'] = task?.mlTasks;
                            newcartdata['cartdata'] = task.cartdata;
                            newcartdata['taskcost'] = taskcost;
                            newcartdata['feasibilityTask'] = task?.feasibilityTask;
                            newcartdata['taskStrategy'] = task?.taskStrategy;
                            newcartdata['purpose'] = task?.purpose;
                            newcartdata['intent'] = task?.intent?.key ? task?.intent?.key : task?.intent;
                            newcartdata['repeatFrequency'] = task?.repeatFrequency;
                            newcartdata['noOfRepeats'] = task?.noOfRepeats;
                            newcartdata['subDailyCount'] = task?.subDailyCount;
                            newcartdata['projectid'] = task?.projectid;
                            newcartdata['openWater'] = task?.openWater;
                            this.apiService
                              .addToCart(this.currentUser.id, newcartdata)
                              .subscribe((res: any) => {});
                            this.router.navigate(['/payment'], {
                              queryParams: { taskId: task.taskid, type: 'cart' },
                            });
                          } else {
                            this.satellogic = false;
                          }
                        });
                      } else if (result === 'no') {
                        this.router.navigate(['/payment'], {
                          queryParams: { taskId: task.taskid, type: 'cart' },
                        });
                      }
                    });
                  } else {
                    this.router.navigate(['/payment'], {
                      queryParams: { taskId: task.taskid, type: 'cart' },
                    });
                  }
              });
          } else if (result === 'no') {
            const action = 'taskcancel';
            const taskBody = { taskid: task.taskid, operators: this.operators };
            const res = await this.apiService
              .cancelSensor(action, taskBody)
              .subscribe((res: any) => {
                this.router.navigate(['/task'], {});
              });
            this.apiService.deleteSensor(taskBody).subscribe((res: any) => { });
          } else this.satellogic = false;
        });
      } else {
        const sensors = task.cartdata;
        const isISTask = task.isISTask;

        let selectedSensors: any = [];
        sensors.forEach(item => {
          selectedSensors.push({
            sensor: item.sensor,
            name: item.name,
            resolution: item?.originalResolution
          });
        });
        this.apiService
          .getFeedsBySensorType(selectedSensors)
          .subscribe((result: any) => {
            if ((result && result?.informationServices?.length)
              && task?.taskStrategy != 'Tip-and-Cue' && this.allocated === false) {
                const dialogRef = this.matdialog.open(ConfirmPopupComponent, {
                  width: '50%',
                  backdropClass: 'blurred',
                  data: {message: 'FEED'}
                });
      
                dialogRef.afterClosed().subscribe(async (result) => {
                  if (result === 'yes') {
                    const catlogRef = this.matdialog.open(CatalogsPopupComponent, {
                      width: '50%',
                      backdropClass: 'blurred',
                      disableClose: true,
                      data: {taskId: task.taskid, sensorTypes: selectedSensors,
                        isOpenWater: task?.openWater
                      },
                    });
                    catlogRef.afterClosed().subscribe(async (result) => {
                      if (result !== 'no') {
                        task.mlTasks = result;
                        const newcartdata = [];
                        let taskcost = 0;
                        this.selectedSensors = [];
                        newcartdata['customerid'] = this.currentUser.id;
                        newcartdata['taskid'] = task.taskid;
                        newcartdata['project'] = task.project;
                        newcartdata['taskname'] = task.taskname;
                        newcartdata['target'] = task.target;
                        newcartdata['openWindow'] = task.openWindow;
                        newcartdata['closeWindow'] = task.closeWindow;
                        newcartdata['checked'] = true;
                        newcartdata['taskFrequency'] = task?.frequency;
                        newcartdata['mlTasks'] = task?.mlTasks;
                        newcartdata['cartdata'] = task.cartdata;
                        newcartdata['taskcost'] = taskcost;
                        newcartdata['feasibilityTask'] = task?.feasibilityTask;
                        newcartdata['taskStrategy'] = task?.taskStrategy;
                        newcartdata['purpose'] = task?.purpose;
                        newcartdata['intent'] = task?.intent?.key ? task?.intent?.key : task?.intent;
                        newcartdata['repeatFrequency'] = task?.repeatFrequency;
                        newcartdata['noOfRepeats'] = task?.noOfRepeats;
                        newcartdata['subDailyCount'] = task?.subDailyCount;
                        newcartdata['projectid'] = task?.projectid;
                        newcartdata['openWater'] = task?.openWater;
                        this.apiService
                          .addToCart(this.currentUser.id, newcartdata)
                          .subscribe((res: any) => {});
                        this.router.navigate(['/payment'], {
                          queryParams: { taskId: task.taskid, type: 'cart' },
                        });
                      } else {
                        this.satellogic = false;
                      }
                    });
                  } else if (result === 'no') {
                    task.mlTasks = [];
                    const newcartdata = [];
                    let taskcost = 0;
                    this.selectedSensors = [];
                    newcartdata['customerid'] = this.currentUser.id;
                    newcartdata['taskid'] = task.taskid;
                    newcartdata['project'] = task.project;
                    newcartdata['taskname'] = task.taskname;
                    newcartdata['target'] = task.target;
                    newcartdata['openWindow'] = task.openWindow;
                    newcartdata['closeWindow'] = task.closeWindow;
                    newcartdata['checked'] = true;
                    newcartdata['taskFrequency'] = task?.frequency;
                    newcartdata['mlTasks'] = task?.mlTasks;
                    newcartdata['cartdata'] = task.cartdata;
                    newcartdata['taskcost'] = taskcost;
                    newcartdata['feasibilityTask'] = task?.feasibilityTask;
                    newcartdata['taskStrategy'] = task?.taskStrategy;
                    newcartdata['purpose'] = task?.purpose;
                    newcartdata['intent'] = task?.intent?.key ? task?.intent?.key : task?.intent;
                    newcartdata['repeatFrequency'] = task?.repeatFrequency;
                    newcartdata['noOfRepeats'] = task?.noOfRepeats;
                    newcartdata['subDailyCount'] = task?.subDailyCount;
                    newcartdata['projectid'] = task?.projectid;
                    newcartdata['openWater'] = task?.openWater;
                    this.apiService
                      .addToCart(this.currentUser.id, newcartdata)
                      .subscribe((res: any) => {});
                    this.router.navigate(['/payment'], {
                      queryParams: { taskId: task.taskid, type: 'cart' },
                    });
                  }
                });
              } else {
                task.mlTasks = [];
                const newcartdata = [];
                let taskcost = 0;
                this.selectedSensors = [];
                newcartdata['customerid'] = this.currentUser.id;
                newcartdata['taskid'] = task.taskid;
                newcartdata['project'] = task.project;
                newcartdata['taskname'] = task.taskname;
                newcartdata['target'] = task.target;
                newcartdata['openWindow'] = task.openWindow;
                newcartdata['closeWindow'] = task.closeWindow;
                newcartdata['checked'] = true;
                newcartdata['taskFrequency'] = task?.frequency;
                newcartdata['mlTasks'] = task?.mlTasks;
                newcartdata['cartdata'] = task.cartdata;
                newcartdata['taskcost'] = taskcost;
                newcartdata['feasibilityTask'] = task?.feasibilityTask;
                newcartdata['taskStrategy'] = task?.taskStrategy;
                newcartdata['purpose'] = task?.purpose;
                newcartdata['intent'] = task?.intent?.key ? task?.intent?.key : task?.intent;
                newcartdata['repeatFrequency'] = task?.repeatFrequency;
                newcartdata['noOfRepeats'] = task?.noOfRepeats;
                newcartdata['subDailyCount'] = task?.subDailyCount;
                newcartdata['projectid'] = task?.projectid;
                newcartdata['openWater'] = task?.openWater;
                this.apiService
                  .addToCart(this.currentUser.id, newcartdata)
                  .subscribe((res: any) => {});
                this.router.navigate(['/payment'], {
                  queryParams: { taskId: task.taskid, type: 'cart' },
                });
              }
          });
      }
    }
  }

  deleteDialog(data: any, i: any, element: any): void {
    let dataid = data.taskid;
    let subText = '';
    let uuid = '';

    if (i !== 99 && data.cartdata.length > 1) {
      dataid = `${data.taskid}#${i}`;
      uuid = element?.uuid;
    }
    data.id = dataid;
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '40%',
      data: { itemdata: data, action: '', userId: this.userid, sensorId: uuid },
      backdropClass: 'blurred',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Deleted') {
        this.getCart(this.userid);

        if (i !== 99) {
          subText = `(#${i + 1})`;
          data.taskid = element?.uuid;
        }
        const toast: any = {
          type: 'success',
          title: '',
          msg: `Task ${data.taskid} is removed from your cart.`,
        };
        this.toastService.openSnackBar(toast, 'Ok');
      }
    });
  }

  getPoints (cost: any) {
    if (this.CARTDATA) {
    if (this.currentUser?.pointsEligible && this.CARTDATA[0]?.conversionFactor) {
      let convertFactor = this.CARTDATA[0]?.conversionFactor.split(":");
      const conversionFactor = convertFactor[1];
      return cost * conversionFactor;                    
    }
  }
    return 0;
  }
}
