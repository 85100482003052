<div id="terms">
<div class="head">
  <h4>Eartheye Sat-Tasking - Terms of Use</h4>
</div>
<hr />
<div class="container numbers">
  <div class="scrollbar" id="style-4">
    <div class="force-overflow"></div>
    <div>
      <p>Last update: {{currentDate}} <br /></p>
      <p>
        These Eartheye Space SAT-TASKING Terms of Use outline Eartheye's and Users obligations and responsibilities on
        the Eartheye Platform. </p>

      <p>
        Eartheye operates an online platform allowing Users to connect through the Eartheye Platform with other
        Geospatial Service Providers to provide the Services and the Service Deliverables. By registering an account on
        the Eartheye Platform a User is entering into an Agreement with Eartheye as set out in these Terms of Use which
        shall govern a User's use of the Eartheye Platform and transactions that may take place through that.</p>

      <p>Please read these terms and all Policies including the Eartheye User Guidelines available online at:
        https://tasking.eartheye.space/howitworks carefully before using the Eartheye Platform. These Policies are
        incorporated into this Agreement by reference.</p>

      <p>All defined terms in this Agreement have the meaning given to them in the Eartheye Glossary as further detailed
        in clause 19 below. A copy of these Terms of Use is also available at: https://tasking.eartheye.space/register
      </p>

      <br />
      <div>
        <ol style="padding: 0 30px;">
          <li> SCOPE OF EARTHEYE SERVICES AND THE SERVICES
            <ol>
              <li><span>The Eartheye Platform is a self-service on-line platform through which Users can enquire,
                  request, order and pay for products and services from potential Geospatial Service Providers.</span>
              </li>
              <li>Eartheye provides the Eartheye Platform to enable Users to raise Service Requests whereby a User can
                submit specific details of a Service Request (with Eartheye's on-line assistance) to calculate the
                Service Fee and find potential Geospatial Service Providers to perform the Services;</li>
              <li>Users can through the Eartheye Platform choose which Geospatial Service Providers it wishes to provide
                the Service Deliverables in response to a Service Request through a menu of available Services. The
                applicable Geospatial Service Provider will advise whether it is able to accept or reject the Service
                Request through the Eartheye Platform.</li>
              <li>A User may revoke or modify a Service Request at any time before a Service Contract is created.</li>
              <li>Full details of the rights of cancellation of a Service Contract in respect of each applicable
                Geospatial Service Provider are detailed in the Policies on the Eartheye Platform and the GSP Terms of
                Use for Users.</li>
              <li>Once the Service Contract is created, the Geospatial Service Provider and User may agree to vary the
                Service Contract on the Eartheye Platform through the self-service and messaging system on the Eartheye
                Platform.</li>
              <li>Once the Services and any Service Deliverables are complete, the User will be advised the Service
                deliverables are available for collection through the Eartheye Platform.</li>
              <li>Users may use the Eartheye Platform and any software that form part of the Eartheye Platform solely to
                use and enjoy the Eartheye Services as provided by Eartheye, and as permitted by this Agreement. User
                may not incorporate any portion of the Eartheye Platform software into their own programs or compile any
                portion of it in combination with their own programs, transfer it for use with another service, or sell,
                rent, lease, lend, loan, distribute or sub-license the Eartheye Platform software or otherwise assign
                any rights to the Eartheye Platform software in whole or in part. User may not use the Eartheye Platform
                software for any illegal purpose. In addition to any other rights of termination under this Agreement,
                Eartheye may cease providing any Eartheye Platform and may terminate User's right to use any Eartheye
                Platform software at any time. User rights to use the Eartheye Platform software may be terminated
                without notice from Eartheye if User fails to comply with any of the terms in the Agreement as regards
                User's use of the Eartheye Platform including software that form part of the Eartheye Platform.</li>
            </ol>
          </li>


          <li> SERVICE CONTRACT
            <ol>

              <li>If a nominated Geospatial Service Provider accepts a Service Request on the Eartheye Platform, a
                Service Contract is created between Eartheye and the User for the Services and Service Deliverables of
                the applicable Services specified in the Service Request under the terms and conditions of this
                Agreement (including any applicable GSP Terms of Use of Service Deliverables). For Recurring Services,
                the next Service Contract is created upon completion of the previous Service Request.</li>
              <li>Once the Services are complete and the Service Deliverables available for collection by the User, the
                GSP shall provide notice of that through the Eartheye Platform.</li>
              <li>Once the Service Contract has been formed the User will pay the Service Fee to Eartheye through one of
                the payment mechanism referred to in clause 7.1 chosen by the User including the release of the Service
                Fee from the Payment Account to Eartheye where payment on account has been made. </li>
              <li>Subject to the applicable Geospatial Service Providers cancellation policies referred to in clause 1.5
                a User may revoke or modify a Service Contract, including for Recurring Services, at any time as
                follows:

                <ul class="display">
                  <b>(a) Before the User pays the Service Fees and the Geospatial Service Provider provides the Service,
                    or </b>
                  <b>(b) as a consequence of a force majeure event which affects the ability of the Geospatial Service
                    Provider to provide the applicable Service in accordance with the Service Contract and the User is
                    notified of such through the Eartheye Platform; or </b>
                  <b>(c) the Geospatial Service Provider is unable to provide the Service under a Service Request by the
                    date prescribed in a Service Contract or such other alternative time acceptable to the User; or</b>
                  <b>(d) the Geospatial Service Provider cancels the Service Contract.</b>
                </ul>
              </li>
              <li>Eartheye reserves the right to cancel all Service Requests made prior to the revocation or
                modification.</li>
              <li>The User may elect to pause automatic payment of a future Service Fee for any Service Request the User
                claims is subject to a bona fide dispute in relation to the quality or performance of the Services and
                any Service Deliverables within 24 hours from when the Geospatial Service Provider confirms the Service
                Request is completed. If the User pauses such automatic payment in accordance with this clause, then any
                Service Fees held in the Payment Account on account will not be released to Eartheye until the dispute
                has been resolved in accordance with clauses 3.9 and 16.</li>
            </ol>
          </li>

          <li> EARTHEYE'S ROLE AND OBLIGATIONS
            <ol>
              <li>Eartheye provides the Eartheye Platform, enabling Users to publish Service Requests and Geospatial
                Service Providers to accept Service Requests through the Eartheye Platform. In addition, Eartheye
                facilitates the ordering, processing and managing of Service Contracts and Service Requests through the
                Eartheye Platform and interactions with the Geospatial Service Providers.</li>
              <li>Eartheye only permits individuals over 18 years of age to become Users and Users must be natural
                persons but can specify within their account description that they represent a business entity.</li>
              <li>At its absolute discretion, Eartheye may refuse to allow any person to register or create an account
                with Eartheye or cancel or suspend or modify any existing account including if Eartheye reasonably forms
                the view that a User's conduct (including a breach of this Agreement) is detrimental to the operation of
                the Eartheye Platform.</li>
              <li>Registering and creating an account with Eartheye on the Eartheye Platform for use of the Eartheye
                Service is by way of a Deposit. There is no additional charge for a User to raise a Service
                Request, or for other Geospatial Service Providers to review content on the Eartheye Platform relating
                to a User's Service Request. Geospatial Service Provider's will only have access to the Eartheye
                Platform for the purposes of reviewing and accepting Service Requests and delivering their Services and
                Service Deliverables through the Eartheye Platform to Users.</li>
              <li>Eartheye accepts no liability for any aspect of the User and Geospatial Service Provider interaction,
                including but not limited to the description, performance or delivery of Services or any Service
                Deliverable which will be governed by the applicable GSP Terms of Use and the applicable Geospatial
                Services Providers privacy policies. In the event the User has an issue with the description,
                performance or quality of the Services or Service Deliverables from a Geospatial Service Provider under
                a Service Contract then the User should address this with Eartheye who will facilitate any resolution or
                response with the applicable Geospatial Service Provider. </li>
              <li>Any rights of refund of Service Fees paid under a Service Contract shall be determined and facilitated
                by Eartheye in consultation with the applicable GSP and in line GSP End User Terms and these terms and
                conditions.</li>
              <li>Eartheye has no responsibility and makes no warranty as to the truth or accuracy of any aspect of any
                information provided by Users, or Service Deliverables including, but not limited to, the ability of
                Geospatial Service Providers to perform tasks or supply items, or the honesty or accuracy of any
                information provided by User</li>
              <li>Except for liability, that cannot be excluded by law the Eartheye Service is provided on an "as is"
                basis, and without any warranty or condition, express or implied. To the extent permitted by law,
                Eartheye specifically disclaim any implied warranties of title, merchantability, fitness for a
                particular purpose and non-infringement.</li>
              <li>Eartheye shall assist Users and involve itself in any dispute between User and the applicable GSP who
                is providing the Service Deliverables under a Service Contract that is not in compliance with the GSP
                End User Terms or this Agreement, including as a mechanism to improve User experience.</li>
            </ol>
          </li>


          <li> USER OBLIGATIONS
            <ol>
              <li>Users will at all times:
                <ul class="display">
                  <b>(a) comply with this Agreement (including all applicable Policies and Eartheye User Guidelines) and
                    all applicable laws and regulations;</b>
                  <b>(b) only submit accurate information on the Eartheye Platform;</b>
                  <b>(c) ensure that they are aware of any laws that apply to them as a User, or in relation to using
                    the Eartheye Platform.</b>
                </ul>
              </li>
              <li>User agrees that any content (whether provided by Eartheye, a User or a third party) on the Eartheye
                Platform may not be used on third party sites or for other business purposes without Eartheye's or the
                applicable Geospatial Service Provider's (as the case may be) prior permission.</li>
              <li>Users must not use the Eartheye Platform for any illegal or immoral purpose and Users are responsible
                for maintaining control of and access to their Eartheye account at all times.</li>
              <li>User grants Eartheye an unrestricted, worldwide, royalty-free licence to use, reproduce, modify and
                adapt any content and information submitted on the Eartheye Platform for the purpose of publishing
                material on the Eartheye Platform and as otherwise may be required to provide the Eartheye Service or
                the Services (including the sharing of any content and information with Geospatial Service Providers, or
                for the general promotion of the Eartheye Service and the fulfilment of a Service Contract), and as
                permitted by this Agreement.</li>
              <li>User acknowledges and confirms that Eartheye shall retain all Intellectual Property Rights associated
                with the Eartheye Platform and the Eartheye Service other than the Intellectual Property Rights in the
                content or information provided by Users</li>
              <li>User agrees that any information submitted on the Eartheye Platform must not, in any way whatsoever,
                be potentially or actually harmful to Eartheye or applicable Geospatial Service Provider or any other
                person. </li>
              <li>User must ensure that no Harmful Code or similar programming effects are coded or introduced into the
                Eartheye Platform or systems as a direct result of the provision of the Eartheye Services and User shall
                not copy, in whole or in part, the software forming part of the Eartheye Platform or associated
                documentation, or modify, disassemble, decompress, reverse compile, reverse assemble, reverse engineer,
                or translate any portion of such software.</li>
              <li>Without limiting any provision of this Agreement, any information User supplies to Eartheye or publish
                in a Service Request (including as part of an Offer) must be up to date and kept up to date and must
                not:
                <ul class="display">
                  <b>(a) be false, inaccurate or misleading or deceptive;</b>
                  <b>(b) be fraudulent;</b>
                  <b>(c) infringe any third party&#39;s copyright, patent, trademark, trade secret or other
                    proprietary rights or intellectual property rights, rights of publicity, confidentiality
                    or privacy;</b>
                  <b>(d) violate any applicable law, statute, ordinance or regulation (including, but not limited to,
                    those governing export and import control, consumer protection, unfair competition, criminal law,
                    antidiscrimination and trade practices/fair trading laws);</b>
                  <b>(e) be defamatory, libellous, threatening or harassing or be obscene or contain any material that,
                    in Eartheye's sole and absolute discretion, is in any way inappropriate or unlawful, including, but
                    not limited to obscene, inappropriate or unlawful images; or</b>
                  <b>(f) contain any Harmful Code that intentionally or unintentionally causes harm or subverts the
                    intended function of any Eartheye Platform, including, but not limited to viruses, trojan horses,
                    worms, time bombs, cancelbots, or other computer programming routines that may damage, modify,
                    delete, detrimentally interfere with, surreptitiously intercept, access without authority or
                    expropriate any system, data or Personal Information.</b>
                </ul>
              </li>
              <li>If Eartheye determines at its sole discretion that User has breached any obligation under this clause
                4 or that User has breached one or more Service Contracts, it reserves the rights to remove any content,
                or Service Request User has submitted to the Eartheye Platform or cancel or suspend Users account and/or
                any Service Contracts without liability.</li>
            </ol>
          </li>


          <li> SERVICE AND DEPOSIT
            <ol>
              <li>Upon User registration to the Eartheye Platform and on an anniversary date thereafter the User shall
                pay the Deposit.</li>
              <li>Upon the creation of a Service Contract, the User owes Eartheye the respective Service Fee. The
                Service Fee will be paid by the Users to Eartheye through the selected Payment Provider and where
                prepaid held in the User Payment Account.</li>
              <li>If the Service Request requires Eartheye or a Geospatial Service Provider to incur additional costs in
                completing the Services, such additional costs shall be subject to agreement prior to them being
                incurred and will added to any calculation of the applicable Service Fees.</li>
              <li>Subject to clause 2.6 all Service Fees and Deposit and charges payable to Eartheye are
                non-cancellable and non-refundable, save for User's rights under any GSP Terms of Use or the applicable
                GSP's cancellation Policies .</li>
              <li>Eartheye may set-off any Service Fees or Deposit owed by a User against any other amounts
                held by Eartheye on behalf of a User in the User's Payment Account (if any).</li>
              <li>Eartheye may restrict a User's account until all Service Fees due in accordance with this Agreement or
                a Service Contract(s) have been paid by the User.</li>
            </ol>
          </li>

          <li> PAYMENT FACILITY
            <ol>
              <li>Eartheye uses one or more Payment Providers to operate the payments under this Agreement and Service
                Contracts. Payment of Service and Deposit will be through the one or more of the Eartheye
                nominated Payment Providers chosen by the User at the time of placing a Service Contract through the
                Eartheye Platform. </li>
              <li class="page-break">In so far as it is relevant to the provision of the payments through the Payment Provider, the terms
                of the applicable Payment Provider available through the Eartheye Platform are incorporated into this
                Agreement and will prevail over this Agreement to the extent of any inconsistency in relation to the
                provision of the payment of Service or Deposit under this Agreement through the payment
                Provider.</li>
              <li>If Eartheye changes or adds to its list of Payment Providers then the User may be asked to agree to
                any further additional terms with those providers. If User does not agree to them, User will be given
                alternative means of payment.</li>
            </ol>
          </li>

          <li> PAYMENTS AND REFUNDS
            <ol>
              <li>The Eartheye Platform allows the User to choose from any of the following Payment mechanisms as
                further set out in the Eartheye Policies:
                <ol class="display">
                  <b>(a) Pay Now;</b>
                  <b>(b) Debit My Account; or</b>
                  <b>(c) Pay Later.</b>
                </ol>
              </li>
              <li>If a User nominates for payment of Service Fees under clause 7.1 b) this shall be subject to the User
                having sufficient funds available in their Payment Account to cover the applicable amount.</li>
              <li>If a User nominates for payment of Service Fees under clause 7.1 c) this shall be subject to approval
                by Eartheye of the prescribed payment terms which shall be facilitated through the Eartheye Platform in
                line with Eartheye's Policy under the Pay Later function.</li>
              <li>If the Service Contract is cancelled for any reason (by a User, a GSP or otherwise under this
                Agreement) and the User does not wish to re-task the Service Deliverable under a revised Service Request
                at a later date agreed with Eartheye and the applicable Geospatial Service Provider, then Eartheye may
                decide in its absolute discretion that any prepaid Service Fees under the relevant Service Contract held
                in the User's Payment Account should be returned to the User and refunded to the User as a credit that:
                <ul class="display">
                  <b>(a) can be used by the User to pay for any new Services via the Eartheye Platform;</b>
                  <b>(b) are not refundable or redeemable for cash unless otherwise expressly agreed by Eartheye;</b>
                  <b>(c) cannot be replaced, exchanged or reloaded or transferred to another account;</b>
                  <b>(d) are valid for 12 months from the date on which that particular credit is applied to a User's
                    account, the date of issue or purchase or any expiry date applied by Eartheye (subject to any
                    contrary specific jurisdictional legislative requirements); or </b>
                  <b>(e) can be used by the User to be used as an offset against any other payments owed by the User to
                    Eartheye at any time to Eartheye.</b>
                </ul>
              </li>
              <li>If the parties agree to any additional cancellation fee payable under a Service Contract, it is the
                responsibility of the party aggrieved to claim any amount owed directly from the other (including any
                pre-existing amounts held in the User's Payment Account).</li>
              <li>Eartheye may take up to 14 days to credit the Service Fee or refund in cash where agreed under clause
                7.4(b) (less any cancellation fee, if applicable) to the User.</li>
              <li>If, for any reason, the Service Fees cannot be transferred or otherwise returned to the User (as the
                case may be) or no claim is otherwise made for the Service Fees, the Service Fees will remain in the
                Users Payment Account for up to twelve months from the date the User initially paid the applicable
                Service Fees.</li>
              <li>Following the 12 months referred to in clause 7.7, and provided there is still no dispute in respect
                of the returned Service Fees, the applicable Service Fees will be retained by Eartheye. </li>
              <li>If the Service Contract is cancelled in line with the cancellation policies for the applicable
                Geospatial Service Provider and there is a dispute around any such cancellation then the amount of the
                Service Fee to be returned to the User in the form of a Service Credit or refund will be conditional
                upon the mediation and dispute process in clause 16. </li>
            </ol>
          </li>

          <li> THIRD-PARTY SERVICES
            <ol>
              <li>Eartheye may from time to time include Third Party Services on the Eartheye Platform. These Third
                Party Services are not provided by Eartheye..</li>
              <li>Third Party Services are offered to Users pursuant to the third party's terms and conditions. Third
                Party Services may be promoted on the Eartheye Platform as a convenience to Users who may find the
                Third-Party Services of interest or of use.</li>
              <li>If a User engages with any Third-Party Service provider, the agreement will be directly between the
                User and that Third Party Service provider.</li>
              <li>Eartheye makes no representation or warranty as to the Third-Party Services. However, to help us
                continue to improve our Eartheye Platform, Users may inform Eartheye of their Third-Party Service
                experience.</li>
            </ol>
          </li>
          <li class="displaynon"> INSURANCE
            <b>If so required by User, User will be responsible for obtaining insurance through the Users nominated
              insurance company and at its own cost for certain Service Contracts.</b>
          </li>

          <li class="page-break"> FEEDBACK
            <ol>
              <li>Users can provide feedback on the Eartheye Platform using any 'Report' function on the Eartheye
                Platform. This can be used to: i) raise a complaint, or ii) provide a compliment on the Services, or
                iii) to suggest feature enhancements to the Services or the offerings available through the Eartheye
                Platform.</li>
              <li>Eartheye is entitled to suspend or terminate User's account at any time if Eartheye, in its sole and
                absolute discretion, is concerned by any feedback from User or considers a User feedback rating to be
                problematic for other Eartheye Users.</li>
            </ol>
          </li>

          <li> LIMITATION OF LIABILITY
            <ol>
              <li>To the fullest extent permitted by law, Eartheye will not be responsible for: (i) losses arising from
                the unavailability of, or your inability to use any Eartheye Services), (ii) losses that are not
                directly caused by any breach on our part; (iii) any business loss, loss of sales, profits, revenue,
                contracts, anticipated savings, data, goodwill or wasted expenditure; (iv) any indirect or consequential
                losses; (v) any delay or failure to comply with our obligations under these conditions if the delay or
                failure arises from any cause which is beyond our reasonable control. For any other loss relating to an
                Eartheye Services, Eartheye's liability is limited to the amount the applicable User paid to Eartheye
                for the relevant Eartheye Service..</li>
              <li>Nothing in these conditions is intended to (i) override any express commitments Eartheye gives to User
                for particular Eartheye Services, or (ii) exclude, restrict or modify any right or remedy User may have
                in statute including under any applicable consumer law or otherwise to the extent that that right or
                remedy cannot be excluded, restricted or modified under law. Any disclaimer, exclusion, or limitation in
                these conditions applies as provided for in these conditions to the full extent permitted by law and
                subject to any such non excludable right or remedy.</li>
            </ol>
          </li>

          <li class="page-break"> CONFIDENTIALITY. PRIVACY AND DATA SECURITY
            <ol>
              <li>Neither party will, without the prior written consent of the other party disclose or use (except as
                expressly permitted by, or required to achieve the purposes of, the Agreement) the Confidential
                Information received from the other party. Each party will use reasonable efforts to protect the other
                party's Confidential Information and will use at least the same efforts to protect such Confidential
                Information, as the party would use to protect its own Confidential Information. A party may disclose
                Confidential Information if required to do so by a governmental agency, by operation of law, or if
                necessary in any proceeding to establish rights or obligations under the Agreement. Subject to clause
                4.6 each party agrees to limit disclosure and access to Confidential Information to those of its
                employees, contractors, attorneys or other representatives who reasonably require such access in order
                to accomplish the purposes of this Agreement and who are subject to confidentiality obligations at least
                as restrictive as those contained herein. This clause does not prohibit either party from responding to
                lawful requests from law enforcement authorities.</li>
              <li>Subject to clause 4.6 above, each party shall at all times comply with its obligations under all laws
                relating to the protection of Personal Information applicable in the country where the Services are to
                be supplied. Eartheye will provide their Service pursuant to their own Privacy Policy and in compliance
                with applicable Privacy Laws. Geospatial Service Providers will provide their Service pursuant to their
                own Privacy Policy. Prior to acceptance of any Service from a Geospatial Service Provider, User must
                review and agree to their terms of service including their privacy policy. </li>
              <li>As part of providing the Services through the Eartheye Platform, (i) either party may collect certain
                information some of which may include personally identifiable information and (ii) a party may share
                with the other party certain personally identifiable information such as name, company name, physical
                address, email address and phone number under Service Contracts. With respect to each party's respective
                use of Personal Information, each party agree to:
                <ul class="display">
                  <b>(a) Maintain appropriate administrative, physical, and technical safeguards for protection of the
                    security, confidentiality and integrity of Personal Information in accordance with industry
                    standards;</b>
                  <b>(b) Take all commercially reasonable and appropriate legal, organizational, and technical measures
                    to protect Personal Information against (a) accidental or unlawful destruction (b) accidental loss,
                    alteration or processing, and (c) unauthorised disclosure or access;</b>
                  <b>(c) Comply with all applicable general privacy and data security laws; </b>
                  <b>(d) Use Personal Information solely as permitted under this Agreement and for such other purposes
                    solely as permitted;</b>
                  <b>(e) Not disclose any Personal Information except as compelled by law or as expressly permitted in
                    writing by the subject individual who the Personal Information relates to;</b>
                  <b>(f) Provide legally adequate privacy notices and obtain all necessary consents in accordance with
                    applicable general privacy and data security laws; and</b>
                  <b>(g) Execute data protection agreement(s), upon request from Eartheye, to ensure compliance with
                    applicable general privacy and data security laws.</b>
                </ul>
              </li>
              <li><b>Information Security.</b>Each party shall take all appropriate legal, organisational and technical
                measures to protect against unlawful and unauthorized processing of Confidential Information. Each party
                shall maintain reasonable operating standards and security procedures and shall secure Confidential
                Information through the use of appropriate physical and logical security measures. In the event that a
                party learns of a breach of its security measures or gained unauthorized access to the other party's
                Confidential Information that party shall promptly notify the other party.</li>
              <li>Eartheye will endeavour to permit User to transact anonymously on the Eartheye Platform. However in
                order to ensure Eartheye can reduce the incidence of fraud and other behaviour in breach of the Eartheye
                User Policy guidelines, Eartheye reserves the right to ask Users to verify themselves in order to remain
                a User.</li>
            </ol>
          </li>


          <li> MODIFICATIONS TO THE AGREEMENT
            <ol>
              <li>Eartheye may modify this Agreement or the Policies (and update the Eartheye pages on which they are
                displayed) from time to time. Eartheye will send notification of such modifications to Users Eartheye
                account or advise Users the next time they login.</li>
              <li>When Users actively agree to amended terms (for example, by clicking a button saying "I accept") or
                use the Eartheye Platform in any manner, including engaging in any acts in connection with a Service
                Contract, the amended terms will be effective immediately and apply from the date of the next Service
                Contract created in the Eartheye Platform. In all other cases, the amended terms will automatically be
                effective 30 days after they are initially notified to User.</li>
              <li>If User does not agree with any changes to this Agreement (or any of our Policies), User must either
                terminate their account or notify Eartheye who will terminate their Eartheye account on their behalf and
                stop using the Eartheye Service and the Eartheye Platform.</li>
            </ol>
          </li>

          <li class="page-break"> NO AGENCY</li>
          <div>No agency, partnership, joint venture, employee-employer or other similar relationship is created by this
            Agreement. In particular User has no authority to bind Eartheye, its related entities or affiliates in any
            way whatsoever. Eartheye confirms that all Services that may be promoted on the Eartheye Platform are
            provided solely by such applicable and identified Geospatial Service Providers. To the extent permitted by
            law, Eartheye specifically disclaims all liability for any loss or damage incurred by User in any manner due
            to the performance or non-performance of such Geospatial Service Provider.</div>

          <li> NOTICES
            <ol>
              <li>Except as stated otherwise, any notices must be given by registered ordinary post or by email, either
                to Eartheye's contact address as displayed on the Eartheye Platform, or to Eartheye Users' contact
                address as provided at registration. Any notice shall be deemed given:
                <ol class="display">
                  <b>(a) if sent by email, 24 hours after email is sent, unless the User is notified that the email
                    address is invalid or the email is undeliverable, and</b>
                  <b>(b) if sent by pre-paid post, three Business Days after the date of posting, or on the seventh
                    Business Day after the date of posting if sent to or posted from outside the jurisdiction in which
                    User has their Eartheye Platform account.</b>
                </ol>
              </li>
              <li>Notices related to performance of any Geospatial Service Provider must be delivered to such third
                party as set out in the Geospatial Service Provider's terms and conditions and facilitated through
                Eartheye.</li>
            </ol>
          </li>

          <li> MEDIATION AND DISPUTE RESOLUTION
            <ol>
              <li>Each party will allow the other reasonable opportunity to comply before it claims that the other
                (including the Geospatial Service Provider) has not met its obligations under a Service Contract.
                Eartheye encourages User to try and resolve disputes (including claims for returns or refunds) with the
                Geospatial Service Provider through Eartheye rather than directly. Accordingly, User acknowledges and
                agrees that Eartheye may, in its absolute discretion, provide Users information as it decides is
                suitable to other parties involved in the dispute. If Eartheye provides information about Geospatial
                Service Providers to the applicable User for the purposes of resolving disputes under this clause, User
                acknowledges and agrees that such information will be used only for the purpose of resolving the dispute
                (and no other purpose) and that User will be responsible and liable to Eartheye for any costs, losses or
                liabilities incurred by Eartheye in relation to any claims relating to any other use of information not
                permitted by this Agreement.</li>
              <li class="page-break">Any dispute arising out of or in connection with this this Agreement or a Service Contract, including
                any question regarding its existence, validity or termination (each, a “Dispute”), shall be resolved
                exclusively in accordance with the provisions of this clause 16.2. Any Dispute shall first be referred
                in writing to senior executives of each party for resolution ("Dispute Notice"). Any Dispute which is
                not resolved in writing within fifteen days after the delivery of a Dispute Notice shall be referred to
                and finally resolved by arbitration administered by the Singapore International Arbitration Centre
                (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre
                ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in
                this clause. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one (1)
                arbitrator agreed by the parties. The language of the arbitration shall be English. The arbitrator shall
                issue a reasoned award including findings of fact and conclusions of law. The award shall be final and
                binding and enforceable in any court having jurisdiction. The cost of such arbitration shall be borne
                equally by the parties and each party to the arbitration shall bear its own legal fees. By agreeing to
                arbitration, the parties do not intend to deprive any court of its jurisdiction to issue a pre-arbitral
                injunction, pre-arbitral attachment or other order in aid of arbitration proceedings or to preserve the
                status quo.</li>
              <li>Eartheye has the right to hold any Service Fee that is the subject of a dispute, until the dispute has
                been resolved.</li>
              <li>Disputes with any Geospatial Service Provider must proceed pursuant to any dispute resolution process
                set out in clause 3.5 and clause 16 and the GSP's Terms of Use.</li>
            </ol>
          </li>

          <li> TERMINATION
            <ol>
              <li>Either User or Eartheye may terminate a User's account and this Agreement at any time for any reason.
              </li>
              <li>Termination of this Agreement does not affect any Service Contract that has been formed between
                Eartheye Users prior to the date of termination.</li>
              <li>Eartheye Services provided through Geospatial Service Provider are conditional upon and governed by
                the applicable GSP's Terms of Use and this Agreement.</li>
              <li>Clauses 5 (Service Fees), 11 (Limitation of Liability) and 16 (Mediation and Dispute Resolution) and
                any other terms which by their nature should continue to apply, will survive any termination or
                expiration of this Agreement.</li>
              <li>If a User account or this Agreement are terminated for any reason then User may not without Eartheye's
                consent (in its absolute discretion) create any further accounts with Eartheye and Eartheye may
                terminate any other accounts the applicable User operates.</li>
            </ol>
          </li>

          <li class="page-break"> GENERAL
            <ol>
              <li>This Agreement and all the rights and obligations of the parties hereunder are governed by and shall
                be construed in accordance with the laws of Singapore without regard to any conflicts of law rules
                thereof. </li>
              <li>Neither party will be liable to the other for any failure to fulfil obligations caused by
                circumstances outside its reasonable control. This clause does not apply to any of User's obligations to
                pay Service Fees for Services provided. </li>
              <li>The provisions of this Agreement are severable, and if any provision of this Agreement is held to be
                invalid or unenforceable, such provision may be removed and the remaining provisions will be
                enforceable.</li>
              <li>This Agreement may be assigned or novated by Eartheye to a third party without your consent. In the
                event of an assignment or novation the User will remain bound by this Agreement.</li>
              <li>No Third-Party Beneficiaries. This Agreement shall inure solely to the benefit of and be binding upon
                the parties hereto and to their respective successors and assigns, and except where advised to the
                contrary in a Service Contract nothing in this Agreement confers on any third party any legal or
                equitable right to enforce its terms. </li>
              <li>This Agreement sets out the entire understanding and agreement between the User and Eartheye with
                respect to its subject matter.</li>
            </ol>
          </li>

          <li class="display">.EARTHEYE GLOSSARY
            <b>"Agreement" means the most updated version of these Eartheye Marketplace Terms of Use - Geospatial
              Tasking Services between Eartheye and a User.</b>
            <b>“Confidential Information” ,means all non-public or proprietary information provided by the other party,
              its affiliates (including Geospatial Service Providers), all specifications or other documents prepared by
              a party in connection herewith and all other non-public information relating hereto, including any of the
              foregoing disclosed pursuant to a Service Request or a Service Contract. Confidential Information does not
              include any information which was previously known or disclosed to a party without any confidentiality
              restriction, or is publicly available through no fault of that party.</b>
            <b>"Eartheye" means "us" "our" means the Eartheye Space Technologies Pte Ltd. </b>
            <b>"Eartheye Platform" means the Eartheye website at <a> http://tasking.eartheye.space/</a>, and any other
              affiliated platform that may be introduced from time to time.</b>
            <b>"Eartheye Service" means the service of providing the Eartheye Platform.</b>
            <b>"Business Day" means a day on which banks are open for general business in the jurisdiction where Users
              have their Eartheye Platform account, other than a Saturday, Sunday or public holiday.</b>

            <b>"Consequential Loss" means any loss, damage or expense recoverable at law:</b>
            <ul class="divno">
              <div>• (a) other than a loss, damage or expense that would be suffered or incurred by any person in a
                similar situation to the person suffering or incurring the loss, damage or expense; or</div>
              <div>• (b) which is a loss of:
                <div style="margin: 13px;">
                  <div>a. opportunity or goodwill;</div>
                  <div>b. profits, anticipated savings or business;</div>
                  <div>c. data; or</div>
                  <div>d. value of any equipment,</div>
                </div>

              </div>
            </ul>

            <b>and any costs or expenses incurred in connection with the foregoing.</b>
            <b>"Geospatial Service Provider" or “GSP” means the third party entity that is the provider of the Services
              Deliverables to User through the Eartheye Platform.</b>
            <b>“GSP Terms of Use” means the Geospatial Service Providers terms of use applicable to Service Deliverables
              as identified in an Offer.</b>
            <b>“Harmful Code” means any computer program or virus or other code that is harmful, destructive, disabling
              or which assists in or enables theft, alternation, denial of service, unauthorised access to, or
              disclosure, destruction or corruption of, information or data.</b>
            <b>“Intellectual Property Rights” includes all intellectual property rights and industrial property rights
              throughout the world including rights in respect of or in connection with the Eartheye Platform or the
              Eartheye Services including: i) rights in any trade secrets or other Confidential Information ii)
              copyright (including future copyright and rights in the nature of or analogous to copyright); iii) right
              of integrity, rights of attribution and other rights of an analogous nature which may now exist or which
              may exist in the future (moral rights); iv) inventions (including patents); v) trade marks; vi) service
              marks; and vii) rights in designs.</b>
            <b>"Offer" means an offer made by a Geospatial Service Provider through the Eartheye Platform in response to
              a Service Request to perform the Services. </b>
            <b>"Payment Account" means the User account under which the User may pre-pay Service or Deposit
              operated by Eartheye and any respective Payment Provider.</b>
            <b>"Payment Provider" means an entity appointed by Eartheye that manages and operates the payment of
              Deposit and Service Fees including accepting payments from and making payments from Users.</b>
            <b class="page-break" style="padding-top: 10px;">"Personal Information" means information that identifies, relates to, describes or is linked or linkable,
              to an individual, and which is provided by or on behalf of the disclosing party to the other or which that
              party comes into possession of, or is created, generated or processed by the party as a result of or in
              connection with its delivery and performance of the Services.</b>
            <b>"Policies" means the policies posted by Eartheye on the Eartheye Platform, including but not limited to
              the Eartheye User Guidelines.</b>
            <b>“Privacy Laws” means all laws relating to the protection of Personal Information applicable in the
              country where the Services are to be supplied.</b>
            <b>"Recurring Services" means the same services procured by a User from the same Geospatial Service Provider
              on a recurring basis, for example weekly, fortnightly or monthly.</b>
            <b>"Services" means the Eartheye Services and the services to be rendered as described in the Service
              Request, including any variations or amendments agreed before or subsequent to the creation of a Service
              Contract and for Recurring Services the Services are the services to be performed under each Service
              Request.</b>
            <b>"Service Contract" means the separate contract which is formed between a User and a Eartheye for Services
              provided by a Geospatial Service Provider under the terms and conditions of this Agreement and any other
              terms as may otherwise be agreed and added to the Service Contract.</b>
            <b>“Service Deliverable(s)” means the defined deliverable of the Services provided by the Geospatial Service
              Provider in electronic or other tangible form. </b>
            <b>" Service Fee" means the fee payable by the User to Eartheye as consideration for the Eartheye Services
              (including any variation)) displayed to a User prior to entering into each Service Contract and paid by
              the User through the Payment Provider.</b>
            <b>"Service Request" means the User's request for Services published by one or more Geospatial Service
              Providers on the Eartheye Platform, and includes the date for completion, any price considerations and a
              description of the Services to be provided.</b>
            <b>”Deposit” means the registration of a User account on the Platform.</b>
            <b>“Deposit amount” means the amount to be paid (if any) by the User to register an account on the Eartheye
              Platform and use the Eartheye Services.</b>
            <b>"Third Party Service" means the promotions and links to services offered by third parties (other than
              Geospatial service Providers) as may be featured on the Eartheye Platform from time to time.</b>
            <b>"User" or "You" means the person who has signed up to use the Eartheye Platform and raises Service
              Requests on and through the Eartheye Platform in search of particular Services.</b>

          </li>

          <li class="page-break">
            RULES OF INTERPRETATION:
            <p>
              In the Eartheye Agreement and all Policies, except where the context otherwise requires:
            </p>
            <ul class="divno">
              <div>(a) the singular includes the plural and vice versa, and a gender includes other genders;</div>
              <div>(b) another grammatical form of a defined word or expression has a corresponding meaning;</div>
              <div>(c) a reference to a document or instrument includes the document or instrument as novated, altered,
                supplemented or replaced from time to time;</div>
              <div>(d) a reference to a person includes a natural person, partnership, body corporate, association,
                governmental or local authority or agency or other entity;</div>
              <div>(e) a reference to a statute, ordinance, code or other law includes regulations and other instruments
                under it and consolidations, amendments, re-enactments or replacements of any of them;</div>
              <div>(f) the meaning of general words is not limited by specific examples introduced by including, for
                example or similar expressions;</div>
              <div>(g) ) headings are for ease of reference only and do not affect interpretation;</div>
              <div>(h)any agreement, representation, warranty or indemnity in favour of two or more parties (including
                where two or more persons are included in the same defined term) is for the benefit of them jointly and
                severally; and</div>
              <div>(i) a rule of construction does not apply to the disadvantage of a party because the party was
                responsible for the preparation of this agreement or any part of it.</div>
            </ul>
          </li>





        </ol>
        <br>
        <p>copyright Eartheye {{ date }}</p>
      </div>
    </div>
  </div>
</div>
</div>
<div class="btns">
  <button mat-stroked-button (click)="onNoClick(0)" class="decline">
    Decline
  </button>
  <button mat-flat-button color="primary" class="accept" (click)="onNoClick(1)">Accept</button>
  <button mat-flat-button color="primary" class="decline" (click)="convertToPDF()">Save as PDF</button>
</div>